/* eslint-disable @typescript-eslint/ban-types */
import { AnyObject, IMetaPage } from 'src/interfaces'

export enum CrudActionType {
  REQUEST_LOADING = 'REQUEST_LOADING',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  REQUEST_FAILURE = 'REQUEST_FAILURE',

  LOAD_DATA = 'LOAD_DATA',
  /** Update api list without fetching API */
  UPDATE_LIST = 'UPDATE_LIST',

  CREATE_DATA = 'CREATE_DATA',
  UPDATE_DATA = 'UPDATE_DATA',
  DELETE_DATA = 'DELETE_DATA',
  VIEW_DATA = 'VIEW_DATA',

  RESET_MODAL = 'RESET_MODAL',
  RESET_REQUEST = 'RESET_REQUEST',
}

export type CrudOpenState = 'create' | 'update' | 'delete' | 'view' | null

export type OmitOpenAttrs<T> = Omit<T, 'open'>

export type CrudModuleClose = {
  open: null
  data?: undefined
  id?: undefined
  name?: string
}
export type CrudModuleCreate = {
  open: 'create'
  data?: undefined
  id?: undefined
  name?: string
}

export type CrudModuleUpdate<T = AnyObject> = {
  open: 'update'
  data: T
  id: number | string
  name?: string
}

export type CrudModuleDelete<T = AnyObject> = {
  open: 'delete'
  data: T
  id: number | string
  name?: string
}

export type CrudModuleView<T = AnyObject> = {
  open: 'view'
  data: T
  id?: undefined
  name?: string
}

export type CrudModule<T = AnyObject> =
  | CrudModuleClose
  | CrudModuleCreate
  | CrudModuleUpdate<T>
  | CrudModuleDelete<T>
  | CrudModuleView<T>

export type CrudState = CrudModule & {
  loading: boolean
  list: AnyObject[]
  meta?: IMetaPage
  open: CrudOpenState
}

export type CrudAction =
  | { type: CrudActionType.REQUEST_LOADING }
  | { type: CrudActionType.REQUEST_SUCCESS }
  | { type: CrudActionType.REQUEST_FAILURE }
  | { type: CrudActionType.LOAD_DATA; payload: { meta?: IMetaPage; list: AnyObject[] } }
  | { type: CrudActionType.CREATE_DATA; payload?: { name: string } }
  | { type: CrudActionType.UPDATE_DATA; payload: OmitOpenAttrs<CrudModuleUpdate> }
  | { type: CrudActionType.UPDATE_LIST; payload: { list: AnyObject[] } }
  | { type: CrudActionType.DELETE_DATA; payload: OmitOpenAttrs<CrudModuleDelete> }
  | { type: CrudActionType.VIEW_DATA; payload: OmitOpenAttrs<CrudModuleView> }
  | { type: CrudActionType.RESET_MODAL }
  | { type: CrudActionType.RESET_REQUEST }
