/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UploadFile } from 'antd'
import { uploadPhoto } from '../services/user_photo'
import { postUploadUserImage } from '../services/usermanage'

const typeUpload = {
  national_id: 'national_id',
  national_id_2: 'national_id_2',
  national_id_selfie: 'national_id_selfie',
  car_license: 'car_license',
  car_license_2: 'car_license_2',
  car_license_selfie: 'car_license_selfie',
  moto_license: 'moto_license',
  moto_license_2: 'moto_license_2',
  moto_license_selfie: 'moto_license_selfie',
  income: 'income',
  organization: 'organization',
  signature: 'signature',
  work_permit: 'work_permit',
} as const

export type TUploadType = keyof typeof typeUpload

interface uploadPhotosParams {
  data: any
  auth: any
  project: 'cms' | 'ev-charger-station-public' | 'user-regist'
  device: 'WEBSITE' | 'MOBILE'
  uploadType?: TUploadType
  issueDate?: string
  expirationDate?: string
  userId?: number
}

export const useNumberRange = (start: number, end: number): number[] => {
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start)
}

export function titleCase(str: string): string {
  // https://stackoverflow.com/a/32589289
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export function generateId(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function ConvertCamelCaseInToCapitalize(txt: string) {
  const result = txt.replaceAll('_', '').replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

export function snakeCaseToCapitalize(txt: string) {
  const splitText = txt.split('_')
  const capitalizeText = splitText.reduce((acc: string, item: string) => {
    return acc + ' ' + item.charAt(0).toUpperCase() + item.slice(1)
  }, '')
  return capitalizeText
}

export async function uploadPhotos({ data, project, device, auth: Auth }: uploadPhotosParams) {
  const formData = new FormData()
  formData.append('fileName', data.originFileObj || data)
  formData.append('deviceType', device)
  formData.append('project', project)
  const result = await uploadPhoto(Auth, formData).then(async (response: any) => {
    return response
  })

  return result.fileUrl
}

// patch and new upload
export async function uploadUserPhotos({
  data,
  project,
  device,
  auth: Auth,
  uploadType,
  issueDate,
  expirationDate,
  userId,
}: uploadPhotosParams) {
  const formData = new FormData()
  formData.append('fileName', data.originFileObj)
  formData.append('deviceType', device)
  formData.append('project', project)
  formData.append('flag', '1')
  formData.append('issueDate', issueDate || '')

  if (uploadType) {
    formData.append('uploadType', uploadType)
  }

  if (expirationDate) {
    formData.append('expirationDate', expirationDate)
  }
  if (userId) {
    formData.append('id1', `${userId}`)
  }

  const params = {
    pathfile: uploadType,
    userid: userId,
  }

  const result = await postUploadUserImage(Auth, params, formData).then(async (response: any) => {
    return response
  })

  return result.fileUrl
}

export const isObjectEmpty = (obj: Record<string, unknown>): boolean => {
  return Object.keys(obj).length === 0
}

export const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string) => {
  if (!allowedTypes) {
    return true
  }

  if (type) {
    return allowedTypes === type
  }
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
