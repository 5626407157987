import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { checkAuthStatus, setUserData, setUserToken } from '../../contexts/Auth'
import { exchangeProjectToken, getUserToken } from '../../services/auth'
import LogoHaup from '../../static/img/logo2.png'
import Loginbg from './loginbg.jpg'

const { Title } = Typography

const LoginForm = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const clearError = () => {
    setErrorMessage('')
  }
  const onFinish = async (values: any) => {
    const credential = {
      username: values.username,
      password: values.password,
    }
    try {
      const userToken = await getUserToken(credential)
      if (userToken) {
        const response = await exchangeProjectToken(userToken.accessToken, 'haup_app')
        if (response.accessToken) {
          localStorage.setItem('haupToken', response.accessToken)
        }
        await setUserToken(userToken.accessToken)
        await setUserData(userToken.userData)
        setLoggedIn(true)
      }
    } catch (err: any) {
      setErrorMessage(err?.response?.data?.message || 'Something went wrong.')
      setTimeout(() => {
        clearError()
      }, 3000)
    }
  }

  return (
    <>
      {loggedIn && <Redirect to="/" />}
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Title level={3}>Please login</Title>
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            onClose={clearError}
            closable
            style={{ marginBottom: '1rem' }}
          />
        )}
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            size="large"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" className="login-form-button" block>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

class Login extends React.Component {
  state = {
    loggedIn: false,
  }

  componentDidMount() {
    checkAuthStatus().then((res) => {
      this.setState({ loggedIn: res })
    })
  }

  render(): JSX.Element {
    const isUserLoggedIn = this.state.loggedIn
    return (
      <>
        {isUserLoggedIn && <Redirect to="/" />}
        <Layout
          style={{
            minHeight: '100vh',
            backgroundImage: `url(${Loginbg})`,
            backgroundSize: 'cover',
          }}
        >
          <Row justify="space-around" align="middle" style={{ minHeight: '100vh' }}>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col xs={24} sm={16} md={12} lg={8} xl={6} style={{ textAlign: 'center' }}>
                  <img src={LogoHaup} height="40vh" />
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col
                  xs={24}
                  sm={16}
                  md={12}
                  lg={8}
                  xl={6}
                  style={{ backgroundColor: 'white', textAlign: 'center', padding: '50px' }}
                >
                  <LoginForm />
                </Col>
              </Row>
              <Row style={{ minWidth: '100%' }} justify="center">
                <Col
                  xs={24}
                  sm={16}
                  md={12}
                  lg={8}
                  xl={6}
                  style={{ textAlign: 'center', color: 'white' }}
                >
                  <p>© {new Date().getFullYear()} Haupcar Company Limited.</p>
                </Col>
              </Row>
            </Space>
          </Row>
        </Layout>
      </>
    )
  }
}

export default Login
