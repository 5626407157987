import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { Drawer, Grid, Layout, Menu, MenuProps, Typography } from 'antd'
import { Breakpoint } from 'antd/lib/_util/responsiveObserve'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoHaupLong from './logo2.png'
import LogoHaup from './logo3.png'

const { Sider } = Layout
const { Text } = Typography

// type SubMenuItem = {
//   title: string
//   key: string
//   link: string
//   subMenu?: SubMenuItem[]
// }

type Logo = {
  icon: string
  width: string
}

type MenuItem = {
  key: string
  title: string
  link: string
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | any
  subMenu?: MenuItem[]
}

interface ISideMenu {
  collapsed: boolean
  menus: MenuItem[]
  screen?: Partial<Record<Breakpoint, boolean>>
}

interface ISideMenuState extends ISideMenu {
  logo: Logo
  mobileMenuOpen: boolean
}
export const UlCustom = styled.ul`
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
`

const withBreakPoint = (Component: any) => (props: any) => {
  const screen = Grid.useBreakpoint()
  return <Component {...props} screen={screen} />
}

class SideMenu extends React.Component<ISideMenu, ISideMenuState> {
  logoList = {
    long: { icon: LogoHaupLong, width: '120px' },
    short: { icon: LogoHaup, width: '40px' },
  }
  // menuItems
  menus: MenuProps['items']
  constructor(props: ISideMenu) {
    super(props)
    const logo = this.props.collapsed ? this.logoList.short : this.logoList.long
    this.state = {
      collapsed: this.props.collapsed,
      menus: this.props.menus,
      logo,
      mobileMenuOpen: false,
    }
    // this.menuItems = generateMenu(this.props.menus)

    // Create menus by props
    const createMenuItems = (menus: MenuItem[]): MenuProps['items'] => {
      return menus.map((item) => {
        if (item.subMenu) {
          // Recursive call to create sub-menu items
          const subMenu: MenuProps['items'] = createMenuItems(item.subMenu)
          return {
            label: (
              <Text
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = '#1890ff'
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'white'
                }}
                style={{ color: 'white' }}
              >
                {item.title}
              </Text>
            ),
            key: item.key,
            children: subMenu,
          }
        } else {
          return {
            label: <Link to={item.link}>{item.title}</Link>,
            key: item.key,
          }
        }
      })
    }

    //use function createMenuItems() and set value
    this.menus = createMenuItems(this.props.menus)
  }

  setLogo(collapsed: boolean): void {
    if (collapsed) {
      this.setState({ logo: this.logoList.short })
    } else {
      this.setState({ logo: this.logoList.long })
    }
  }

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed })
    this.setLogo(collapsed)
  }

  scrollCollapsed(mobileMenuOpen: boolean): Record<string, any> {
    const originalStyle = { position: 'relative', backgroundColor: '#15499b' }
    if (mobileMenuOpen) {
      return { ...originalStyle }
    } else {
      return {
        ...originalStyle,
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'auto',
        borderRight: 'solid 1px #1890ff',
      }
    }
  }

  render(): JSX.Element {
    const { collapsed } = this.state

    return (
      <div id="side-menu-root" style={{ position: 'relative', backgroundColor: '#15499b' }}>
        <div
          id="side-menu-burger-button-container"
          hidden={this.props.screen?.lg}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '14px',
            zIndex: 1000,
            cursor: 'pointer',
            backgroundColor: '#15499B',
          }}
          onClick={() => this.setState((prev) => ({ ...prev, mobileMenuOpen: true }))}
        >
          <MenuOutlined id="side-menu-burger-button" style={{ fontSize: '36px', color: 'white' }} />
        </div>
        <Sider
          collapsible={false}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          style={{
            background: '#15499b',
            height: '100vh',
            overflowY: 'auto',
          }}
          className="site-bar-media"
        >
          <Link to="/">
            <div className="logo" style={{ height: '40px' }}>
              <img src={this.state.logo.icon} width={this.state.logo.width} />
            </div>
          </Link>

          <Menu
            theme="dark"
            mode="inline"
            style={{ background: '#15499b' }}
            items={this.menus}
          ></Menu>
        </Sider>
        <Drawer
          open={this.state.mobileMenuOpen}
          onClose={() => this.setState((prev) => ({ ...prev, mobileMenuOpen: false }))}
          bodyStyle={{ backgroundColor: '#15499b' }}
          headerStyle={{ backgroundColor: '#15499b' }}
          closeIcon={<CloseOutlined style={{ color: 'white' }} />}
          placement="left"
        >
          <Link to="/">
            <img src={LogoHaupLong} width={150} style={{ margin: 10 }} />
          </Link>
          <Menu
            theme="dark"
            // defaultSelectedKeys={['1']}
            mode="inline"
            style={{ background: '#15499b' }}
            items={this.menus}
          />
        </Drawer>
      </div>
    )
  }
}

export default withBreakPoint(SideMenu)
