import { PlusCircleOutlined } from '@ant-design/icons'
import {
  Alert,
  Col,
  Divider,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import { useEffect, useState } from 'react'
import { Else, If, Then } from 'react-if'
import { useHistory } from 'react-router-dom'
import CMSButton from '../../components/Styles/button.style'
import { RowBetween, RowCenterMY1 } from '../../components/Styles/row.style'
import { PermissionCreate } from '../../interfaces/permissions.interface'
import { generateId } from '../../libs/utils'
import {
  createPermission,
  getAllDepartments,
  getAvailableMenus,
  getPermissionList,
} from '../../services/admin-permissions/permission.api'
import { getPositionsByDepartmentId } from '../../services/admin-permissions/position.api'
import { TZeroOne } from '../../services/invoice/invoice.interface'
import { BaseOption } from '../AirportPackage/types'
import { PermissionBadgeProps, isPermissionActivated } from '../PermissionsList'
const { Title } = Typography

export default function PermissionsCreate(): JSX.Element {
  const history = useHistory()
  const returnToPermissionList = () => history.replace('/permissions')
  const [isPageReady, setIsPageReady] = useState(false)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [searchPositionLoading, setSearchPositionLoading] = useState(false)
  const [permissionsData, setPermissionsData] = useState<PermissionCreate[]>([])
  const [permissionAlreadySaved, setPermissionAlreadySaved] = useState(false)

  const havePermissions = permissionsData.length > 0

  const saveCreatedPermissions = async () => {
    await createPermission(permissionsData).then((res) => {
      if (res.created) history.replace('/permissions')
    })
  }

  const [departmentOptions, setDepartmentOptions] = useState<BaseOption[]>([])
  const [positionOptions, setPositionOptions] = useState<BaseOption[]>([])
  const [menuOptions, setMenuOptions] = useState<BaseOption[]>([])

  const handleActivation = (key: string, currentActiveState: TZeroOne) => {
    const reversedState = currentActiveState === 1 ? 0 : 1
    const permissionsDataCopy = [...permissionsData]
    const results = permissionsDataCopy.map((permission) => {
      if (permission.key === key) {
        return { ...permission, is_active: reversedState } as PermissionCreate
      } else return { ...permission }
    })
    setPermissionsData(results)
  }

  const showAlertBanner = (record: PermissionCreate, keyName: PermissionBadgeProps['keyName']) => {
    const { is_active, key } = record

    const changePermissionRecord = () => {
      const reverseState = record[keyName] === 1 ? 0 : 1
      const permissionsDataCopy = [...permissionsData]
      const results = permissionsDataCopy.map((permission) => {
        if (permission.key === key) {
          return { ...permission, [keyName]: reverseState }
        } else return { ...permission }
      })
      setPermissionsData(results)
    }
    const selectedAlert =
      record[keyName] === 1 ? (
        <Alert
          message="YES"
          type="success"
          onClick={() => {
            changePermissionRecord()
          }}
        />
      ) : (
        <Alert message="NO" type="error" onClick={() => changePermissionRecord()} />
      )

    const keyState = keyName.split('_')[1].toUpperCase()
    const permissionIsActive = isPermissionActivated(is_active)
    const newActivateStateText = record[keyName] === 1 ? 'NO' : 'YES'
    if (!permissionIsActive) return <Alert type="info" message="DEACTIVATED" />
    return (
      <Tooltip title={`Change ${keyState} to ${newActivateStateText}`}>{selectedAlert}</Tooltip>
    )
  }

  const columns: any = [
    {
      title: 'Department',
      align: 'center' as const,
      render: (info: PermissionCreate) => (
        <>
          [ # {info?.department_id} ] {info?.departmentName}
        </>
      ),
    },
    {
      title: 'Position',
      align: 'center' as const,
      render: (info: PermissionCreate) => (
        <>
          [ # {info?.position_id} ] {info?.positionName}
        </>
      ),
    },
    {
      title: 'Menu',
      align: 'center' as const,
      render: (info: PermissionCreate) => (
        <>
          [ # {info?.menu_id} ] {info?.menuName}
        </>
      ),
    },
    {
      title: 'READ',
      align: 'center' as const,
      render: (record: PermissionCreate) => showAlertBanner(record, 'can_read'),
    },
    {
      title: 'CREATE',
      align: 'center' as const,
      render: (record: PermissionCreate) => showAlertBanner(record, 'can_create'),
    },
    {
      title: 'UPDATE',
      align: 'center' as const,
      render: (record: PermissionCreate) => showAlertBanner(record, 'can_update'),
    },
    {
      title: 'DELETE',
      align: 'center' as const,
      render: (record: PermissionCreate) => showAlertBanner(record, 'can_delete'),
    },
    {
      title: 'Actions',
      align: 'center' as const,
      render: ({ is_active, key }: PermissionCreate) => (
        <Space>
          <CMSButton buttonColor="purple" onClick={() => handleActivation(key, is_active)}>
            ACTIVATE / DEACTIVATE
          </CMSButton>
          <CMSButton buttonColor="red" onClick={() => deletePermission(key)}>
            REMOVE
          </CMSButton>
        </Space>
      ),
    },
  ]

  const loading = globalLoading || searchPositionLoading

  const [selectedDepartment, setSelectedDepartment] = useState<number | undefined>(undefined)
  const [selectedPosition, setSelectedPosition] = useState<number | undefined>(undefined)
  const [selectedMenu, setSelectedMenu] = useState<number | undefined>(undefined)

  const getMenus = async (positionId: number) => {
    setGlobalLoading(true)
    const res = await getAvailableMenus({ departmentId: selectedDepartment as number, positionId })
    const options = res.map(({ menu_id, menu_name }) => {
      return { label: menu_name, value: menu_id as number } as BaseOption
    })
    setMenuOptions(options)
    setGlobalLoading(false)
  }

  useEffect(() => {
    if (!selectedDepartment) return
    if (!isPageReady) return
    const loadPositionOptions = async () => {
      await getPositionsByDepartmentId(selectedDepartment as number).then((res) => {
        const { positions } = res
        const positionOptionsBasedOnDepartmentId = [...positions].map(
          ({ position_name, position_id }) => {
            return { label: position_name, value: position_id as number } as BaseOption
          },
        )
        setPositionOptions(positionOptionsBasedOnDepartmentId)
      })
    }
    setSearchPositionLoading(true)
    loadPositionOptions().finally(() => setSearchPositionLoading(false))
  }, [selectedDepartment])

  useEffect(() => {
    if (!isPageReady) return
    const loadData = async () => {
      // setGlobalLoading(true)
      const { data } = await getPermissionList({
        departmentId: selectedDepartment,
        menuId: selectedMenu,
        positionId: selectedPosition,
        page: 1,
        results: 200,
      })

      const checkPermissionTaken = data.find(
        (item) =>
          item.department_id === selectedDepartment &&
          item.menu_id === selectedMenu &&
          item.position_id === selectedPosition,
      )
        ? true
        : false
      setPermissionAlreadySaved(checkPermissionTaken)
      // setGlobalLoading(false)
    }
    loadData()
  }, [selectedMenu, selectedPosition])

  const showPosition = selectedDepartment !== undefined
  const showMenu = showPosition && selectedPosition !== undefined
  const searchedDone = showMenu && selectedMenu !== undefined

  const selectedDepartmentName = departmentOptions?.find(
    (dept) => dept.value === selectedDepartment,
  )?.label
  const selectedPositionName = positionOptions?.find((pos) => pos.value === selectedPosition)?.label
  const selectedMenuName = menuOptions?.find((menu) => menu.value === selectedMenu)?.label

  useEffect(() => {
    const loadInitialData = async () => {
      const departments = await getAllDepartments()
      const options = departments.map(({ department_id, department_name }) => {
        return { label: department_name, value: department_id as number } as BaseOption
      })
      setDepartmentOptions(options)
    }
    loadInitialData().finally(() => setIsPageReady(true))
  }, [])

  const deletePermission = (key: string) => {
    const updatedPermissionsData = [...permissionsData].filter((item) => item.key !== key)
    setPermissionsData(updatedPermissionsData)
  }

  const addNewPermission = () => {
    const key = generateId(10)
    const permission: PermissionCreate = {
      department_id: selectedDepartment as number,
      departmentName: selectedDepartmentName as string,
      position_id: selectedPosition as number,
      positionName: selectedPositionName as string,
      menu_id: selectedMenu as number,
      menuName: selectedMenuName as string,
      key,
      is_active: 1,
      can_create: 0,
      can_delete: 0,
      can_read: 0,
      can_update: 0,
    }
    setPermissionsData([...permissionsData, permission])
    setSelectedMenu(undefined)
  }

  if (!isPageReady) return <Skeleton />
  return (
    <>
      <Title level={2}>Create Permission</Title>

      <If condition={loading}>
        <Then>
          <div style={{ width: '100%', margin: '2rem 0', display: 'grid' }}>
            <Spin size="large" style={{ placeSelf: 'center' }} />
          </div>
        </Then>
        <Else>
          <RowCenterMY1>
            <Col span={12}>
              <Title level={4}>Department</Title>
            </Col>
            <Col span={12}>
              <Select
                value={selectedDepartment}
                placeholder="Select a department"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setSearchPositionLoading(true)
                  setSelectedDepartment(e)
                  setSelectedPosition(undefined)
                }}
                options={departmentOptions}
              />
            </Col>
          </RowCenterMY1>
          {showPosition && (
            <RowCenterMY1>
              <Col span={12}>
                <Title level={4}>Job Position</Title>
              </Col>
              <Col span={12}>
                <Select
                  value={selectedPosition}
                  placeholder={`Select a position for ${selectedDepartmentName}`}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setSelectedPosition(e)
                    getMenus(e)
                    setSelectedMenu(undefined)
                  }}
                  options={positionOptions}
                />
              </Col>
            </RowCenterMY1>
          )}
          {showMenu && (
            <RowCenterMY1>
              <Col span={12}>
                <Title level={4}>Menu</Title>
              </Col>
              <Col span={12}>
                <Select
                  value={selectedMenu}
                  allowClear
                  showSearch
                  placeholder={`Select a menu`}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setSelectedMenu(e)
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option: any) => {
                    if (option === undefined) return false
                    const reg = new RegExp(input, 'gi')
                    return reg.test(option?.label)
                  }}
                  options={menuOptions}
                />
              </Col>
            </RowCenterMY1>
          )}
          {searchedDone && (
            <If condition={permissionAlreadySaved}>
              <Then>
                <Alert
                  type="info"
                  message="Permissions was already created for this configuration. Please try something else"
                />
              </Then>
              <Else>
                <div
                  style={{
                    border: '1px solid black',
                    margin: '2rem 0',
                    borderRadius: '2rem',
                    padding: '2rem',
                  }}
                >
                  <RowCenterMY1 style={{ border: 'black solid 1px', height: '100%' }}>
                    <Col span={12}>
                      <Title level={4}>Add this new permission</Title>
                    </Col>
                    <Col span={12}>
                      <CMSButton
                        icon={<PlusCircleOutlined />}
                        buttonColor="black"
                        onClick={() => addNewPermission()}
                      >
                        Add
                      </CMSButton>
                    </Col>
                  </RowCenterMY1>
                </div>
              </Else>
            </If>
          )}
        </Else>
      </If>
      {havePermissions && (
        <Table dataSource={permissionsData} columns={columns} pagination={false} />
      )}
      <Divider />
      <RowBetween>
        <Popconfirm
          title="Do you really want to cancel?"
          onConfirm={() => returnToPermissionList()}
        >
          <CMSButton buttonColor="red">CANCEL</CMSButton>
        </Popconfirm>
        <Popconfirm title="Do you want to save?" onConfirm={() => saveCreatedPermissions()}>
          <CMSButton buttonColor="green" disabled={!havePermissions}>
            CONFIRM
          </CMSButton>
        </Popconfirm>
      </RowBetween>
    </>
  )
}
