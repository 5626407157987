/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyObject, IMetaPage } from 'src/interfaces'
import { HttpResponse } from 'src/interfaces/request'
import { crudActions } from 'src/redux/url/actions'
import {
  CrudAction,
  CrudActionType,
  CrudModuleDelete,
  CrudModuleUpdate,
  CrudModuleView,
  OmitOpenAttrs,
} from './types'

export const contextActions = (dispatch: React.Dispatch<CrudAction>) => {
  return {
    modal: {
      /** Same feature as reset system */
      close() {
        dispatch({ type: CrudActionType.RESET_MODAL })
      },
      view(data: OmitOpenAttrs<CrudModuleView>) {
        dispatch({ type: CrudActionType.VIEW_DATA, payload: data })
      },
      create(body?: { name: string }) {
        dispatch({ type: CrudActionType.CREATE_DATA, payload: body })
      },
      delete(data: OmitOpenAttrs<CrudModuleDelete>) {
        dispatch({ type: CrudActionType.DELETE_DATA, payload: data })
      },
      edit(data: OmitOpenAttrs<CrudModuleUpdate>) {
        dispatch({ type: CrudActionType.UPDATE_DATA, payload: data })
      },
    },
    request: {
      reset() {
        dispatch({ type: CrudActionType.RESET_REQUEST })
      },
      loading() {
        dispatch({ type: CrudActionType.REQUEST_LOADING })
      },
      failed() {
        dispatch({ type: CrudActionType.REQUEST_FAILURE })
      },
      success() {
        dispatch({ type: CrudActionType.REQUEST_SUCCESS })
      },
      all(data: { meta?: IMetaPage; list: AnyObject[] }) {
        dispatch({ type: CrudActionType.LOAD_DATA, payload: data })
        dispatch({ type: CrudActionType.REQUEST_SUCCESS })
      },
      /** Refresh results from api list (avoid get API call) and valid the request */
      refresh(list: AnyObject[]) {
        dispatch({ type: CrudActionType.UPDATE_LIST, payload: { list } })
        dispatch({ type: CrudActionType.REQUEST_SUCCESS })
      },
      process(fn: Promise<HttpResponse<unknown>>, list: Promise<HttpResponse<any>>) {
        dispatch({ type: CrudActionType.REQUEST_LOADING })
        fn.then(async (response) => {
          if (!response.isSuccess) dispatch({ type: CrudActionType.REQUEST_FAILURE })
          else {
            dispatch({ type: CrudActionType.REQUEST_SUCCESS })
            dispatch(crudActions.fetch(list as any) as any)
          }
        })
      },
      fetch(data: Promise<HttpResponse<unknown>>) {
        dispatch({ type: CrudActionType.REQUEST_LOADING })
        data.then((response) => {
          if (!response.isSuccess) dispatch({ type: CrudActionType.REQUEST_FAILURE })
          else {
            dispatch({
              type: CrudActionType.LOAD_DATA,
              payload: { meta: response?.meta, list: response.data as AnyObject[] },
            })
            dispatch({ type: CrudActionType.REQUEST_SUCCESS })
          }
        })
      },
    },
  }
}
