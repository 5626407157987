import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import routeconfig, { RouteItem } from '../../config/route'
import Login from '../../containers/Login'
import Logout from '../../containers/Logout'
import AuthenticatedPage from '../AuthenticatedPage'
import Page from '../Page/Page'

const Router = (): JSX.Element => {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        {routeconfig.map((item: RouteItem) => {
          if (item.requireAuth) {
            return (
              <Route exact path={item.path} key={item.title}>
                <AuthenticatedPage>
                  <Page>
                    <Suspense fallback={<div>Loading...</div>}>
                      <item.container />
                    </Suspense>
                  </Page>
                </AuthenticatedPage>
              </Route>
            )
          } else {
            return (
              <Route exact path={item.path} key={item.title}>
                <Page>
                  <Suspense fallback={<div>Loading...</div>}>
                    <item.container />
                  </Suspense>
                </Page>
              </Route>
            )
          }
        })}
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </QueryParamProvider>
  )
}

export default Router
