import { AxiosFunction } from '../../axios.function'
import Notification from '../../components/Notification'
import { GroupedMenu, Menu } from '../../interfaces/permissions.interface'

export const getMenus = async (): Promise<GroupedMenu[]> => {
  return new Promise((resolve, reject) =>
    AxiosFunction({
      method: 'get',
      url: '/cms/apa/menu',
      queryParams: {},
    })
      .then((res) => {
        const { data }: { data: { allMenus: Menu[]; groupedMenus: GroupedMenu[] } } = res
        resolve(data.groupedMenus as GroupedMenu[])
      })
      .catch((message) => {
        Notification(message || 'An error occurred when fetching positions', 'error')
        reject([])
      }),
  )
}

export const getMenuById = async (menuId: number): Promise<{ menu: Menu; isGroup: boolean }> => {
  return new Promise((resolve, reject) =>
    AxiosFunction({
      method: 'get',
      url: `/cms/apa/menu/${menuId}`,
      queryParams: {},
    })
      .then((res) => {
        const { data } = res
        resolve(data)
      })
      .catch((message) => {
        Notification(message || 'An error occurred when fetching positions', 'error')
        reject([])
      }),
  )
}

export const createMenu = async (body: Menu): Promise<{ created: boolean }> => {
  return await AxiosFunction({
    method: 'post',
    url: '/cms/apa/menu',
    body,
    queryParams: {},
  })
    .then((res) => {
      const { data: newMenuId } = res
      Notification(`Menu [${newMenuId}] ${body.menu_name} was successfully created`, 'success')
      return { created: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating menu', 'error')
      return { created: false }
    })
}

export const editMenu = async (menuId: number, body: Menu): Promise<{ updated: boolean }> => {
  return await AxiosFunction({
    method: 'put',
    url: `/cms/apa/menu/${menuId}`,
    body,
    queryParams: {},
  })
    .then(() => {
      Notification(`Menu was successfully updated`, 'success')
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while updating menu', 'error')
      return { updated: false }
    })
}

export const switchFromMainMenuToGroup = async (
  menuId: number,
  body: Menu,
  selectedMenuId: number,
): Promise<{ updated: boolean }> => {
  return await AxiosFunction({
    method: 'put',
    url: `/cms/apa/menu/${menuId}/switch-to-group`,
    body: { postBody: body, menuGroupId: selectedMenuId },
    queryParams: {},
  })
    .then(() => {
      Notification(
        `Menu was successfully switched and linked to menu #${selectedMenuId} `,
        'success',
      )
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while switching menu', 'error')
      return { updated: false }
    })
}

export const switchFromMenuGroupToMain = async (
  menuId: number,
  body: Menu,
): Promise<{ updated: boolean }> => {
  return await AxiosFunction({
    method: 'put',
    url: `/cms/apa/menu/${menuId}/switch-to-main`,
    body,
    queryParams: {},
  })
    .then((res) => {
      const { data: newMenuId } = res
      Notification(
        `Menu [${newMenuId}]${body.menu_name} was successfully switched to main menu`,
        'success',
      )
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while switching menu', 'error')
      return { updated: false }
    })
}
