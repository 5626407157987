import { Button, Space, Table, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { errorMessage } from '../../libs/axios-error'
import {
  MeterLog,
  MeterLogsResponseType,
  getMeterLogs,
} from '../../services/ev-meter-log/ev-meter-log.service'
import formatNumber from '../PriceModel/util/formatNumber'

export const EvMeterLogPage = () => {
  const [meterLogs, setMeterLogs] = useState<MeterLogsResponseType>()

  const columns: ColumnsType<MeterLog> = [
    {
      title: 'Reservation Id',
      dataIndex: 'reservationId',
    },
    {
      title: 'Meter At (Wh)',
      dataIndex: 'meterAtWh',
      render(value) {
        return `${formatNumber(value)}`
      },
    },
    {
      title: 'Action',
      dataIndex: '_id',
      render(value) {
        return (
          <Space>
            <Button>View RAW</Button>
          </Space>
        )
      },
    },
  ]

  useEffect(() => {
    getMeterLogs()
      .then(setMeterLogs)
      .catch((error) => message.error(errorMessage(error)))
  }, [])

  return (
    <Table
      columns={columns}
      dataSource={meterLogs?.docs}
      pagination={{ pageSize: meterLogs?.limit, total: meterLogs?.totalDocs }}
    />
  )
}
