import { AxiosFunction } from '../../axios.function'
import Notification from '../../components/Notification'
import { Department } from '../../interfaces/permissions.interface'

export const getDepartments = async (): Promise<Department[]> => {
  const response = await AxiosFunction({
    method: 'get',
    url: '/cms/apa/department',
    queryParams: {},
  })
    .then((res) => {
      const { data } = res
      return data as Department[]
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching departments', 'error')
      return []
    })
  return response
}

export const createDepartment = async (body: Department): Promise<{ created: boolean }> => {
  const response = await AxiosFunction({
    method: 'post',
    url: '/cms/apa/department',
    body,
    queryParams: {},
  })
    .then((res) => {
      Notification(
        `Department #${res.data} ${body.department_name} was successfully created`,
        'success',
      )
      return { created: true, message: 'Department created successfully' }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating department', 'error')
      return { created: false }
    })
  return response
}

export const editDepartment = async (
  departmentId: number,
  body: Department,
): Promise<{ updated: boolean }> => {
  const response = await AxiosFunction({
    method: 'put',
    url: `/cms/apa/department/${departmentId}`,
    body,
    queryParams: {},
  })
    .then(() => {
      Notification(`Department ${body.department_name} was successfully updated`, 'success')
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating department', 'error')
      return { updated: false }
    })
  return response
}
