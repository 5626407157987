import { Checkbox, DatePicker, Input, InputNumber, Radio, Select, Space, Typography } from 'antd'
import moment from 'moment'
const { Title } = Typography

import { Link } from 'react-router-dom'
import { WithFormValue } from '../../components/AntdForm'
import { LargeAlert } from '../../components/Styles/alert.style'
import CMSButton from '../../components/Styles/button.style'
import {
  ECategory,
  EReservationState,
  IChargeApi,
  IFieldsForEdit,
  IGetReservation,
  ISelectOptions,
} from './reservation-manage.interface'

//////////////////////////////////////////////////////////////
////////////////// FUNCTIONS FOR FORM ////////////////////////
//////////////////////////////////////////////////////////////

// function that takes a array and return a object with key is value of array and value is label of array
export const getOptions = (arr: string[]) => {
  const options = arr.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
  return options
}

//////////////////////////////////////////////////////////////
////////////////// DATA /////////////////////////////////////
//////////////////////////////////////////////////////////////
const dateFormat = 'YYYY-MM-DD HH:mm'

export const datePicker = (
  <WithFormValue>
    {({ value, onChange }: any) => {
      const toMomentValue = value ? moment(value) : undefined
      return (
        <DatePicker
          minuteStep={15}
          value={toMomentValue}
          format={dateFormat}
          onChange={onChange}
          showTime
          style={{ width: '100%' }}
        />
      )
    }}
  </WithFormValue>
)

const radioButtonYesNo = (
  <Radio.Group buttonStyle="solid">
    <Radio.Button value={0}>NO</Radio.Button>
    <Radio.Button value={1}>YES</Radio.Button>
  </Radio.Group>
)

export const initialParamsReservation = (userParam: any): IGetReservation => {
  return {
    page: 1,
    results: 20,
    reservationNo: undefined,
    userId: userParam,
    vehicleId: undefined,
    vehicleCode: undefined,
    email: undefined,
    stationId: undefined,
    state: 'ALL',
    addons: [],
    tripType: 'ALL',
    checkIn: [],
    checkOut: [],
    gasPolicies: [],
    vehicleSystem: [],
    partnerType: [],
    subscriptionPeriod: [],
    fromStartReserveTime: undefined,
    toStartReserveTime: undefined,
    isNewReservation: false,
    isFinishReservation: false,
  }
}

export const emptySQLvaluesReservation = {
  receiptDate: undefined,
  receiptNo: '',
  logTime: '0001-01-01 00:00:00', // NOW()
  userId: 0,
  userType: '',
  groupId: 0,
  vehicleGroupId: 0,
  ownerUserId: 0,
  driverUserId: 0,
  approveUserId: 0,
  approveTime: '0001-01-01 00:00:00',
  payment: 'CASH',
  paymentNo: 0,
  vehicleId: 0,
  stationId: 0,
  reservationType: 'ROUNDTRIP',
  dstStationId: 0,
  couponCode: '',
  promotionCode: '',
  promotionId: 0,
  bundleId: 0,
  bundleHours: 0,
  bundleDistanceKm: -1,
  bundlePrice: 0,
  lateHourPrice: 0,
  limitCreditBaht: -1,
  limitCreditHour: -1,
  limitCreditKm: -1,
  requestCreditBaht: 0,
  requestCreditHour: 0,
  requestCreditKm: 0,
  applyCreditBaht: 0,
  applyCreditHour: 0,
  applyCreditKm: 0,
  couponNo: 0,
  discount: 0,
  hourRate: 0,
  dayRate: 0,
  lateHourRate: 0,
  freeHours: 0,
  freeDistance: 0,
  distanceRate: 0,
  advanceCheckinM: 15,
  advanceReserveM: 0,
  reservationGapM: 30,
  depositPriceB: 0,
  initDescription: '',
  initStartTime: '0001-01-01 00:00:00',
  initStopTime: '0001-01-01 00:00:00',
  initHours: 0,
  initStaticPrice: 0,
  initHourRate: 0,
  initDayRate: 0,
  initPrice: 0,
  reserveDescription: '',
  reserveStartTime: '0001-01-01 00:00:00',
  reserveStopTime: '0001-01-01 00:00:00',
  reserveHours: 0,
  reserveStaticPrice: 0,
  hourPrice: 0,
  estimatedPrice: 0,
  distancePrice: 0,
  driveFreeM: 0,
  drivePrice: 0,
  standByFreeM: 0,
  standByPrice: 0,
  actualStartTime: '0001-01-01 00:00:00',
  actualStopTime: '0001-01-01 00:00:00',
  actualHours: 0,
  actualHourRate: 0,
  actualDayRate: 0,
  reservationState: 'RESERVE',
  actualPrice: 0,
  activateTime: '0001-01-01 00:00:00',
  deactivateTime: '0001-01-01 00:00:00',
  checkInTime: '0001-01-01 00:00:00',
  checkOutTime: '0001-01-01 00:00:00',
  departTime: '0001-01-01 00:00:00',
  arriveTime: '0001-01-01 00:00:00',
  startKm: 0,
  stopKm: 0,
  engineHours: 0,
  validPositionHours: 0,
  minHDop: 0,
  maxHDop: 0,
  chargeId: '',
  chargeState: 'NONE',
  chargeCreditCard: '',
  chargeTotal: 0,
  totalPrice: 0,
  vatPrice: 0,
  remark: '',
  extraChargeId: 'NULL',
  extraChargeState: 'NONE',
  extraChargeCreditCard: '',
  extraChargeTotal: 0,
  extraChargeCount: 0,
  completeTime: '0001-01-01 00:00:00',
  ratingPre: 0,
  ratingPost: 0,
  ratingMarks: '',
  chargeActualHours: 0,
  category: '',
  taxRequest: 0,
  accessMode: 'AUTO',
  enableNfcDrive: 0,
  adminUserId: 0,
  walletId: 0,
  addonCleaning: '',
  addonDelivery: '',
  addonDriver: '',
  addonHelmet: '',
  addonHelmetAddition: '',
  addonInsurance: '',
  priceCleaning: 0,
  priceDelivery: 0,
  priceDriver: 0,
  priceHelmet: 0,
  priceHelmetAddition: 0,
  priceInsurance: 0,
  statusTags: '',
  requireCheckin: 1,
  checkInState: 'NO',
  checkOutState: 'NO',
  creditGroupId: 0,
  internalRemark: '',
  hourPriceAdjust: 0,
  distancePriceAdjust: 0,
  licenseNumber: '',
  licenseProvince: '',
  vehicleApproveUserId: 0,
  vehicleApproveTime: '0001-01-01 00:00:00',
  vehicleApproveRequire: 0,
  approveRequire: 1,
  planId: 0,
  rejectUserId: 0,
  rejectTime: '0001-01-01 00:00:00',
  rejectTags: '',
  fullPrice: 0,
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  overideDeposit: -1,
  overideHourPrice: -1,
  overideDistancePrice: -1,
  overideLateHourPrice: -1,
  overideBundlePrice: -1,
  overideDiscount: -1,
  overideOtherPrice: -1,
  overideOtherPriceRemark: '',
  debundle: 0,
  debundleFee: 0,
  gasingPolicy: 'SAME_LEVEL',
  apiVersion: '',
  deviceType: '',
  vehicleSystem: null,
  partnerType: 'UNDEFINED',
  subscriptionPackage: '',
  subscriptionStartDate: null,
  subscriptionMasterReservationNo: 0,
  billingInfo: '',
  minReserveM: 0,
  maxReserveM: 0,
  validStartHour: 0,
  validStopHour: 0,
  addonReturnDelivery: '',
  priceReturnDelivery: 0,
  cancellationUserId: 0,
  cancellationReasonCode: '',
  cancellationReasonText: '',
}

////////////////////////////////////////////////////////////////
///////////////// OPTIONS CHOICE ///////////////////////////////
////////////////////////////////////////////////////////////////
export const reservationStateChoice = [
  'ALL',
  'RESERVE',
  'DRIVE',
  'FINISH',
  'CANCEL',
  'COMPLETE',
  'REQUEST',
  'REJECT',
]
export const checkInStateChoice = [
  'NO',
  'PENDING_4SIDE',
  'PENDING_ADDITION',
  'PENDING_DASHBOARD',
  'PENDING',
  'SUCCESS',
  'SKIP',
]
export const checkOutStateChoice = ['NO', 'PENDING', 'SUCCESS', 'SKIP']
export const addOnsOptions = [
  { label: 'CLEANING', value: 'addon_cleaning' },
  { label: 'DELIVERY', value: 'addon_delivery' },
  { label: 'DRIVER', value: 'addon_driver' },
  { label: 'HELMET', value: 'addon_helmet' },
  { label: ' HELMET ADDITION', value: 'addon_helmet_addition' },
  { label: 'INSURANCE', value: 'addon_insurance' },
]
export const reservationTripChoice = ['ALL', 'ONEWAY', 'ROUNDTRIP']
export const gasPoliciesChoice = ['NONE', 'BUNDLE', 'FULL_TO_FULL', 'SAME_LEVEL', 'EV']
export const vehicleSystemChoice = ['HAUP', 'MANUAL', 'NOTRACKING']
export const partnerTypeChoice = ['OEM', 'OTA', 'P2P', 'UNDEFINED']

export const subscriptionOptions = [
  {
    label: '1 Week',
    value: '1W',
  },
  {
    label: '1 Month',
    value: '1M',
  },
  {
    label: '3 Month',
    value: '3M',
  },
  {
    label: '6 Month',
    value: '6M',
  },
  {
    label: '12 Months',
    value: '12M',
  },
]

export const categoryOptionsCheckbox = [
  { value: ECategory.HAUP, label: ECategory.HAUP },
  { value: ECategory.EXTERNAL, label: ECategory.EXTERNAL },
  { value: ECategory.MA, label: ECategory.MA },
  { value: ECategory.LONGTERM, label: ECategory.LONGTERM },
  // { value: 'INSTANT', label: 'INSTANT' },
]

export const statusTagsChoice = ['SPEEDTICKET', 'SCRATCH']

////////////////////////////////////////////////////////////////
///////////////// FIELDS FOR EDIT //////////////////////////////
////////////////////////////////////////////////////////////////

export const disabledFieldsFormCreateReservation: IFieldsForEdit[] = [
  { name: 'bundleDetail', label: 'Bundle Detail', component: <Input disabled /> },
  { name: 'hourRate', label: 'Hour Rate', component: <InputNumber disabled /> },
  { name: 'dayRate', label: 'Day Rate', component: <InputNumber disabled /> },
  { name: 'reserveHours', label: 'Reserve Hours', component: <InputNumber disabled /> },
  { name: 'freeHours', label: 'Free Hours', component: <InputNumber disabled /> },
  { name: 'freeReserveHours', label: 'Free Reserve Hours', component: <InputNumber disabled /> },
  { name: 'hourPrice', label: 'Hour Price', component: <InputNumber disabled /> },
  { name: 'promotionId', label: 'Promotion Id', component: <InputNumber disabled /> },
  { name: 'discount', label: 'Discount', component: <InputNumber disabled /> },
  { name: 'estimatedPrice', label: 'Estimated Price', component: <InputNumber disabled /> },
  { name: 'lateHourRate', label: 'Late Hour Rate', component: <InputNumber disabled /> },
  { name: 'freeDistance', label: 'Free Distance', component: <InputNumber disabled /> },
  { name: 'distanceRate', label: 'Distance Rate', component: <InputNumber disabled /> },
]

export const fieldsFormForEditReservation: IFieldsForEdit[] = [
  // search station
  // search vehicle
  { name: 'reservationNo', label: 'Reservation No', component: <InputNumber disabled={true} /> },
  { name: 'userId', label: 'User', component: <InputNumber disabled={true} /> },
  { name: 'LABEL', label: 'RESERVE SECTION', component: <InputNumber disabled={true} /> },
  {
    name: 'reservationState',
    label: 'State',
    component: <Select allowClear options={getOptions(reservationStateChoice)} />,
  },
  {
    name: 'reserveStartTime',
    label: 'Reserve Start Time',
    component: datePicker,
    itemRules: [
      { required: true, message: 'Please input your reserve stop time!' },
      () => ({
        validator(_: any, value: string) {
          const today = new Date().toDateString()
          if (Date.parse(value) < Date.parse(today)) {
            return Promise.reject(new Error('The starting date must not be before today'))
          } else {
            return Promise.resolve()
          }
        },
      }),
    ],
  },
  {
    name: 'reserveStopTime',
    label: 'Reserve Stop Time',
    component: datePicker,
  },
  { name: 'dstStationId', label: 'Dst Station', component: <InputNumber /> },
  { name: 'promotionCode', label: 'Promotion Code', component: <Input /> },
  { name: 'promotionId', label: 'Promotion Id', component: <InputNumber /> },
  { name: 'discount', label: 'Discount', component: <InputNumber /> },
  { name: 'bundleId', label: 'Bundle Id', component: <InputNumber /> },
  { name: 'bundleHours', label: 'Bundle Hours', component: <InputNumber /> },
  { name: 'bundleDistanceKm', label: 'Bundle Distance Km', component: <InputNumber /> },
  { name: 'bundleDetail', label: 'Bundle Detail', component: <Input /> },
  { name: 'payment', label: 'Payment', component: <Input /> },
  {
    name: 'taxRequest',
    label: 'Tax Request',
    component: radioButtonYesNo,
  },
  { name: 'accessMode', label: 'Access Mode', component: <Input /> },
  {
    name: 'enableNfcDrive',
    label: 'Enable Nfc Drive',
    component: radioButtonYesNo,
  },
  {
    name: 'requireCheckin',
    label: 'Require Checkin',
    component: radioButtonYesNo,
  },
  {
    name: 'checkInState',
    label: 'Check In State',
    component: <Select allowClear options={getOptions(checkInStateChoice)} />,
  },
  {
    name: 'checkOutState',
    label: 'Check Out State',
    component: <Select allowClear options={getOptions(checkOutStateChoice)} />,
  },
  {
    name: 'category',
    label: 'Category',
    component: <Checkbox.Group options={categoryOptionsCheckbox} />,
  },
  {
    name: 'statusTags',
    label: 'Status Tags',
    component: <Checkbox.Group options={getOptions(statusTagsChoice)} />,
  },

  { name: 'LABEL', label: 'PRICING', component: <InputNumber /> },
  { name: 'hourRate', label: 'Hour Rate', component: <InputNumber /> },
  { name: 'dayRate', label: 'Day Rate', component: <InputNumber /> },
  { name: 'reserveHours', label: 'Reserve Hours', component: <InputNumber /> },
  { name: 'freeHours', label: 'Free Hours', component: <InputNumber disabled /> },
  { name: 'reserveStaticPrice', label: 'Reserve Static Price', component: <InputNumber /> },
  { name: 'hourPrice', label: 'Hour Price', component: <InputNumber disabled /> },
  { name: 'estimatedPrice', label: 'Estimated Price', component: <InputNumber /> },
  { name: 'lateHourRate', label: 'Late Hour Rate', component: <InputNumber /> },
  { name: 'freeDistance', label: 'Free Distance', component: <InputNumber /> },

  { name: 'freeReserveHours', label: 'Free Reserve Hours', component: <InputNumber disabled /> },
  { name: 'promotionId', label: 'Promotion Id', component: <InputNumber disabled /> },
  { name: 'discount', label: 'Discount', component: <InputNumber /> },
  { name: 'estimatedPrice', label: 'Estimated Price', component: <InputNumber /> },
  { name: 'distanceRate', label: 'Distance Rate', component: <InputNumber /> },
  ////////////////////////////////// extra data /////////////////////////////////
  { name: 'LABEL', label: 'EXTRA SECTION', component: datePicker },
  { name: 'actualStartTime', label: 'Actual Start Time', component: datePicker },
  { name: 'actualStopTime', label: 'Actual Stop Time', component: datePicker },
  { name: 'actualHours', label: 'Actual Hours', component: <InputNumber /> },
  { name: 'actualPrice', label: 'Actual Price', component: <InputNumber /> },
  { name: 'remark', label: 'Remark', component: <Input /> },
  { name: 'internalRemark', label: 'Internal Remark', component: <Input /> },
  { name: 'hourPriceAdjust', label: 'Hour Price Adjust', component: <InputNumber /> },
  { name: 'distancePriceAdjust', label: 'Distance Price Adjust', component: <InputNumber /> },
  { name: 'licenseNumber', label: 'License Number', component: <Input /> },
  { name: 'licenseProvince', label: 'License Province', component: <Input /> },
  { name: 'referenceNo1', label: 'Reference No1', component: <Input /> },
  { name: 'referenceNo2', label: 'Reference No2', component: <Input /> },
  { name: 'referenceNo3', label: 'Reference No3', component: <Input /> },
  // {name: 'insuranceNumber', label: 'Insurance Number', component: <InputNumber />},
  // {name: 'transferMasterReservationNo', label: 'Transfer Master Reservation No', component: <InputNumber />},
  { name: 'deptAmount', label: 'Dept Amount', component: <InputNumber /> },

  // EXTRA DATA
  // actualStartTime
  // actualStopTime
  // actualHours
  //  actualPrice
  // remark
  // internalRemark
  //
]

export const reservationSteps = [
  EReservationState.REQUEST,
  EReservationState.RESERVE,
  EReservationState.DRIVE,
  EReservationState.FINISH,
  EReservationState.COMPLETE,
]

export const allReservationState = [
  ...reservationSteps,
  EReservationState.CANCEL,
  EReservationState.REJECT,
]

export const selectOptions: ISelectOptions[] = [
  { label: 'Cleanliness', value: 'CLEANLINESS' },
  { label: 'Battery issue', value: 'BATTERY' },
  { label: 'Engine issue', value: 'ENGINE' },
  { label: 'Damage (interior / exterior)', value: 'DAMAGE' },
  { label: 'Accident claim', value: 'ACCIDENT' },
  { label: 'Haupbox issue', value: 'HAUPBOX' },
  { label: 'External rental', value: 'EXTERNAL_RENTAL' },
  { label: 'Installation', value: 'INSTALLATION' },
  { label: 'Check In', value: 'CHECK_IN' },
  { label: 'Check Out', value: 'CHECK_OUT' },
  { label: 'Others', value: 'OTHERS' },
]

export const chargeColumns = [
  {
    title: 'No',
    dataIndex: 'chargeNo',
    key: 'chargeNo',
    align: 'center' as const,
  },
  {
    title: 'Charge Method',
    dataIndex: 'chargeMethod',
    key: 'chargeMethod',
    align: 'center' as const,
  },
  {
    title: 'Charge Time',
    dataIndex: 'chargeTime',
    key: 'chargeTime',
    align: 'center' as const,
  },
  {
    title: 'Status',
    dataIndex: 'chargeState',
    key: 'chargeState',
    align: 'center' as const,
  },
  {
    title: 'Total(฿)',
    dataIndex: 'chargeTotal',
    key: 'chargeTotal',
    align: 'center' as const,
  },
  {
    title: 'Receipt',
    dataIndex: 'receiptNo',
    key: 'receiptNo',
    align: 'center' as const,
    render: (item: IChargeApi['receiptNo']) => <>{item === '' ? '#NA' : item}</>,
  },
  {
    title: 'Payment proof',
    align: 'center' as const,
    render: (item: IChargeApi) => (
      <>
        {item.chargeState === 'CHARGED' ? (
          <Space direction="vertical">
            <Link to={item.transferslipUrl} target="_blank">
              <CMSButton buttonColor="black">See transfer slip</CMSButton>
            </Link>
          </Space>
        ) : (
          <LargeAlert type="info" message="No payment" showIcon />
        )}
      </>
    ),
  },
]
