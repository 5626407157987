import { Divider, Form, Input, InputNumber, Radio, Select, Skeleton, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { CMSActionsButtons } from '../../components/MasterFormComponent/Form/CMSForm'
import { RowBetween } from '../../components/Styles/row.style'
import { GroupedMenu, Menu } from '../../interfaces/permissions.interface'
import {
  createMenu,
  editMenu,
  getMenuById,
  getMenus,
} from '../../services/admin-permissions/menu.api'
const { Title } = Typography

interface MenuOptions {
  value: Menu['menu_id']
  label: Menu['menu_name']
}

export default function MenuForm() {
  const history = useHistory()
  const [radioMenu, setRadioMenu] = useState<'main' | 'group'>('main')
  const [isPageReady, setIsPageReady] = useState(false)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [menuData, setMenuData] = useState<Menu>({} as Menu)

  const { id } = useParams<{ id: string }>()
  const isCreateMode = id === undefined || id === ''
  const titlePage = isCreateMode ? 'Create Menu' : `Edit Menu ${id}`

  const isGroup = radioMenu === 'group'

  const [menuOptions, setMenuOptions] = useState<MenuOptions[]>([])
  const [menuNames, setMenuNames] = useState<string[]>([])

  const haveFetchData = !isCreateMode ? [getMenuById(Number(id))] : []

  const promises: Promise<any>[] = [getMenus(), ...haveFetchData]

  const getNamesFromMenus = (menus: GroupedMenu[]) => {
    const names = []
    for (const menu of menus) {
      names.push(menu.menu_name)
      if (menu.grouped.length > 0)
        for (let i = 0; i < menu.grouped.length; i++) names.push(menu.grouped[i].menu_name)
    }
    return names
  }

  const getMenuOptions = (menus: GroupedMenu[]) =>
    [...menus].map(({ menu_name, menu_id }) => {
      return { value: menu_id, label: menu_name } as MenuOptions
    })

  const loadData = async () => {
    await Promise.all(promises)
      .then((res) => {
        setMenuNames(getNamesFromMenus(res[0]))
        setMenuOptions(getMenuOptions(res[0]))
        if (!isCreateMode) {
          setMenuData(res[1].menu)
          form.setFieldsValue(res[1].menu)
        }
      })
      .finally(() => setIsPageReady(true))
  }

  useEffect(() => {
    loadData()
  }, [])

  const getGroupName = (menuId: number | null) => {
    if (menuId === null) return null
    return menuOptions.find((item) => item.value === menuId)?.label
  }

  const redirectUserToMainPage = () => history.replace('/menu')

  const onFinish = async (body: Menu) => {
    setLoading(true)
    const haveMenuGroup = body.menu_group ? true : false
    if (isCreateMode) {
      const updatedBody = (haveMenuGroup
        ? { ...body, group_name: getGroupName(body.menu_group), system_id: 2 }
        : { ...body, menu_group: null, group_name: null, system_id: 2 }) as Menu
      const { created } = await createMenu(updatedBody)
      if (created) redirectUserToMainPage()
    } else {
      console.log('upt')
      const { updated } = await editMenu(Number(id), body)
      if (updated) redirectUserToMainPage()
    }
    setLoading(false)
  }

  if (!isPageReady) return <Skeleton />
  return (
    <>
      <RowBetween>
        <Title level={3}>{titlePage}</Title>
        {isCreateMode && (
          <Radio.Group value={radioMenu} onChange={(e) => setRadioMenu(e.target.value)}>
            <Radio.Button value="main">Main menu</Radio.Button>
            <Radio.Button value="group">Group menu</Radio.Button>
          </Radio.Group>
        )}
      </RowBetween>
      <Divider />
      <Form form={form} onFinish={onFinish}>
        {isGroup && (
          <Form.Item
            name="menu_group"
            label="Group"
            rules={[{ required: true, message: 'A group is needed if Group menu is selected' }]}
          >
            <Select options={menuOptions} allowClear />
          </Form.Item>
        )}
        {!isCreateMode ? (
          <>
            <Form.Item name="menu_group" label="Group Menu Id">
              <InputNumber disabled />
            </Form.Item>
            <Form.Item name="group_name" label="Group Name">
              <Input disabled />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item name="menu_id" hidden>
          <input />
        </Form.Item>
        <Form.Item name="menu_label" label="Menu label">
          <Input />
        </Form.Item>
        <Form.Item
          name="menu_name"
          label="Name"
          rules={[
            { required: true, message: 'Please input a menu name!' },
            () => ({
              validator(_, value) {
                const foundName = menuNames.find((item) => item === value)
                const checkFoundName = isCreateMode
                  ? foundName
                  : foundName === menuData.menu_name
                  ? undefined
                  : foundName
                const isDuplicate = checkFoundName === undefined ? false : true
                if (isDuplicate)
                  return Promise.reject(new Error('This department name is already exist!'))
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="menu_description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="menu_path" label="Route path">
          <Input />
        </Form.Item>
        <Form.Item
          name="is_active"
          label="Is Active"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Radio.Group>
            <Radio.Button value={1}>YES</Radio.Button>
            <Radio.Button value={0}>NO</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <CMSActionsButtons form={form} cancelRedirectLink="/menu" loading={loading} />
    </>
  )
}
