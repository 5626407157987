import moment from 'moment'
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AxiosFunction } from '../../axios.function'
import Notification from '../../components/Notification'
import { IGetReservation, IMetaPage } from '../../interfaces'
import { IInvoiceApiList, IResponse } from '../../services/invoice/invoice.interface'
import { initialParamsReservation } from '../../services/reservation-manage/reservation-manage.constants'
import {
  EReservationState,
  IReservationData,
} from '../../services/reservation-manage/reservation-manage.interface'

interface IInitReservation {
  source: string | undefined
  ref: number | undefined
  userId: number | undefined
}

export type ReservationInformation = {
  vehicleId: number | null
  reserveStartTime: Date | null | moment.Moment | string
  reserveEndTime: Date | null | moment.Moment | string
  userId?: number
}

interface IReservationContext {
  state: {
    vehicleId: number | null
    reserveStartTime: Date | null | moment.Moment | string
    reserveEndTime: Date | null | moment.Moment | string
  }
  setReservationInfo: (
    vehicleId: IReservationContext['state']['vehicleId'],
    reserveStartTime: IReservationContext['state']['reserveStartTime'],
    reserveEndTime: IReservationContext['state']['reserveEndTime'],
  ) => void
  modal: {
    setViewReservationStateModal: Dispatch<SetStateAction<boolean>>
    setChargeModal: Dispatch<SetStateAction<boolean>>
    setRefreshUserModal: Dispatch<SetStateAction<boolean>>
    viewReservationStateModal: boolean
    refreshUserModal: boolean
    chargeModal: boolean
  }
  invoice: {
    initReservation: IInitReservation
    setInitReservation: Dispatch<SetStateAction<IInitReservation>>
    cleanInitReservation: () => void
  }
  reservation: {
    params: IGetReservation
    setParams: Dispatch<SetStateAction<IGetReservation>>
    selectedReservation: IReservationData | null
    setSelectedReservation: Dispatch<SetStateAction<IReservationData | null>>
    setInvoiceData: Dispatch<SetStateAction<IInvoiceApiList[]>>
    invoiceData: IInvoiceApiList[]
    loading: boolean
    setIdFromURL: Dispatch<SetStateAction<number | 'all'>>
    pagination: IMetaPage
    setPagination: Dispatch<SetStateAction<IMetaPage>>
    reservationList: IReservationData[]
    setReservationList: Dispatch<SetStateAction<IReservationData[]>>
    newState: EReservationState | null
    setNewState: Dispatch<SetStateAction<EReservationState | null>>
  }
  functions: {
    showCarLocation: (reservationNo: number) => void
    getInvoicesNotCharged: (reservationNumber: number) => void
    handleAutoCharge: (body: IInvoiceApiList[], reservationNumber: number) => void
    handleUpdateReservationState: (data: IReservationData, newState: EReservationState) => void
    showUpdateReservationState: (data: IReservationData, newState: EReservationState) => void
  }
}

const ReservationContext = createContext({} as IReservationContext)

export function useReservation() {
  const reservationElement = useContext(ReservationContext)
  if (!reservationElement) {
    throw new Error('useReservation must be used within a ReservationProvider')
  }
  return reservationElement
}

interface IReservationProvider {
  children: React.ReactNode
}

export function ReservationProvider({ children }: IReservationProvider) {
  const history = useHistory()
  const now = new Date()
  const [state, setState] = useState<IReservationContext['state']>({
    vehicleId: null,
    reserveStartTime: `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:00`,
    reserveEndTime: null,
  })

  const initReservationParams = {
    source: undefined,
    ref: undefined,
    userId: undefined,
  }
  const [initReservation, setInitReservation] = useState<IInitReservation>(initReservationParams)

  const cleanInitReservation = () => setInitReservation(initReservationParams)

  const [idFromURL, setIdFromURL] = useState<number | 'all'>('all')

  const [selectedReservation, setSelectedReservation] = useState<IReservationData | null>(null)
  const [viewReservationStateModal, setViewReservationStateModal] = useState(false)
  const [chargeModal, setChargeModal] = useState(false)
  const [invoiceData, setInvoiceData] = useState<IInvoiceApiList[]>([])
  const [refreshUserModal, setRefreshUserModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newState, setNewState] = useState<EReservationState | null>(null)

  const userParam = idFromURL !== 'all' ? idFromURL : undefined
  const [params, setParams] = useState<IGetReservation>(initialParamsReservation(userParam))

  const [reservationList, setReservationList] = useState<IReservationData[]>([])

  const [pagination, setPagination] = useState<IMetaPage>({
    page: 1,
    pages: 0,
    results: 20,
    total: 0,
  })

  const setReservationInfo = (
    vehicleId: IReservationContext['state']['vehicleId'],
    reserveStartTime: IReservationContext['state']['reserveStartTime'],
    reserveEndTime: IReservationContext['state']['reserveEndTime'],
  ) => {
    setState({ vehicleId, reserveStartTime, reserveEndTime })
  }

  const showCarLocation = async (reservationNo: number) => {
    await AxiosFunction({
      method: 'get',
      url: `/cms/reservation-manage/${reservationNo}/showtrack`,
      queryParams: {},
    }).then((res: any) => {
      const { url } = res
      window.open(url, '_blank')
    })
  }

  const showUpdateReservationState = (data: IReservationData, newState: EReservationState) => {
    setSelectedReservation(() => data)
    setNewState(() => newState)
    setViewReservationStateModal(true)
  }

  const getInvoicesNotCharged = async (reservationNumber: number) => {
    setLoading(true)
    AxiosFunction({
      method: 'get',
      url: `/cms/reservation-manage/invoice/${reservationNumber}`,
      queryParams: {},
    })
      .then((res: IResponse<IInvoiceApiList[]>) => {
        const { data } = res
        data && setInvoiceData(data)
        setLoading(false)
      })
      .catch(() => {
        Notification(
          'Impossible to get the invoices to charge the reservation. Please try again or contact the IT department',
          'error',
        )
        setLoading(false)
      })
  }

  const handleUpdateReservationState = async (
    data: IReservationData,
    updatedState: EReservationState,
  ) => {
    setLoading(true)
    AxiosFunction({
      method: 'put',
      url: `/cms/reservation-manage/${data.reservationNo}/state`,
      body: data,
      queryParams: { newState: updatedState },
    })
      .then(async () => {
        setLoading(false)
        setViewReservationStateModal(false)
        Notification('Successfully updated', 'success')
        setParams({ ...params })
        history.push(`/reservation/all`)
      })
      .catch(() => {
        setLoading(false)
        Notification('Impossible to change state', 'error')
      })
  }

  const handleAutoCharge = async (body: IInvoiceApiList[], reservationNo: number) => {
    setLoading(true)
    console.log('body', body)
    await AxiosFunction({
      method: 'post',
      url: `/cms/charge/reservation/${reservationNo}/auto`,
      body: body,
      queryParams: {},
    }).then(async (res: IResponse<{ charged: boolean }>) => {
      const { data, message } = res
      if (data && data.charged) {
        Notification('Successfully charged', 'success')
        setLoading(false)
        setChargeModal(false)
        setParams({ ...params })
      } else {
        if (typeof message === 'string') Notification(message, 'error')
        setLoading(false)
      }
    })
  }

  const reservation: IReservationContext['reservation'] = {
    selectedReservation,
    setSelectedReservation,
    invoiceData,
    setInvoiceData,
    loading,
    setIdFromURL,
    params,
    setParams,
    pagination,
    setPagination,
    setReservationList,
    reservationList,
    newState,
    setNewState,
  }

  const modal = {
    setViewReservationStateModal,
    setChargeModal,
    refreshUserModal,
    setRefreshUserModal,
    viewReservationStateModal,
    chargeModal,
  }
  const functions = {
    showCarLocation,
    getInvoicesNotCharged,
    handleAutoCharge,
    handleUpdateReservationState,
    showUpdateReservationState,
  }

  const invoice = {
    setInitReservation,
    initReservation,
    cleanInitReservation,
  }

  return (
    <ReservationContext.Provider
      value={{ state, setReservationInfo, reservation, functions, modal, invoice }}
    >
      {children}
    </ReservationContext.Provider>
  )
}
