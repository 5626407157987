import moment, { Moment } from 'moment'
import React from 'react'
import { Redirect } from 'react-router'
import { checkValidToken } from '../../services/auth'
import { getPermissionByRestful } from '../../services/auth/permission/permission'
import loadingIcon from '../../static/img/haup-loading.gif'
import { Auth } from './interface'

const defaultValues = {
  user: {
    userid: null,
    userName: null,
    userToken: null,
  },
  permission: null,
  userLoggedIn: false,
}

const AuthContext = React.createContext(defaultValues)
class AuthProvider extends React.Component {
  _isMounted = false
  readyLogin = false
  state = {
    user: {
      userid: null,
      userName: null,
      userToken: null,
    },
    permission: null,
    userLoggedIn: true,
  }
  componentDidMount(): any {
    this._isMounted = true
    checkAuthStatus().then(async (res) => {
      const permissionData = await getPermissionData()
      if (!res) {
        await clearSession()
        this.setState({ userLoggedIn: false })
      }
      if (permissionData === false) {
        await clearSession()
        this.setState({ userLoggedIn: false })
      }
      if (this._isMounted) {
        this.setState({ userLoggedIn: res })
        const userData = await getUserData()
        const userToken = await getUserToken()
        const permission = await getPermission()
        this.readyLogin = true
        this.setState({ user: userData, accessToken: userToken, permission })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render(): JSX.Element {
    const isUserLoggedIn = this.state.userLoggedIn
    return (
      <>
        {!this.readyLogin ? (
          <div
            style={{
              backgroundColor: 'rgb(21, 73, 156)',
              height: '100%',
              width: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              src={loadingIcon}
              style={{
                margin: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxHeight: '20vh',
              }}
            ></img>
          </div>
        ) : (
          [
            isUserLoggedIn ? (
              <AuthContext.Provider value={this.state} key="AuthProvider">
                {this.props.children}
              </AuthContext.Provider>
            ) : (
              <Redirect to="/login" key="RedirectLogin" />
            ),
          ]
        )}
      </>
    )
  }
}

function useAuth() {
  const context = React.useContext(AuthContext) as any
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context as Auth
}

export async function setUserToken(userToken: string): Promise<boolean> {
  try {
    await localStorage.setItem('userToken', JSON.stringify(userToken))
    return true
  } catch (error) {
    return false
  }
}

export async function setUserData(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('userData', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function setPermission(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('permission', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function setLogTime(userData: any): Promise<boolean> {
  try {
    await localStorage.setItem('logTime', JSON.stringify(userData))
    return true
  } catch (error) {
    return false
  }
}

export async function getUserData(): Promise<any> {
  try {
    const userData = await localStorage.getItem('userData')
    if (userData) {
      return JSON.parse(userData)
    }
    return {}
  } catch (error) {
    return false
  }
}

export function getUserToken() {
  const tokenString = localStorage.getItem('userToken')
  if (!tokenString) {
    return null
  }
  return JSON.parse(tokenString)
}

export async function getPermission(): Promise<any> {
  try {
    const permission = await localStorage.getItem('permission')
    if (permission) {
      return JSON.parse(permission)
    }
    return {}
  } catch (error) {
    return false
  }
}

export async function getLogTime(): Promise<Moment | null> {
  const logTime = (await localStorage.getItem('logTime')) || null
  if (logTime) {
    return moment(logTime)
  }
  return null
}

export async function checkAuthStatus(): Promise<boolean> {
  const userToken = await getUserToken()
  if (!userToken) {
    return false
  } else {
    // const lastCheck = await getLogTime()
    // const now = moment()
    // if (now.diff(lastCheck, 'minutes') > 60) {

    // }
    const isValidToken = await checkValidToken(userToken)
    return isValidToken
  }
}

export async function getPermissionData(): Promise<boolean> {
  const userToken = await getUserToken()

  if (!userToken) {
    return false
  } else {
    try {
      const permission = await getPermissionByRestful(userToken)
      await setPermission(permission?.data)
      return true
    } catch {
      return false
    }
  }
}

export async function clearSession(): Promise<boolean> {
  await localStorage.clear()
  return true
}

export { AuthContext, AuthProvider, useAuth }
