import { Alert, Divider, Form, Input, Modal, Radio, Space } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { Else, If, Then } from 'react-if'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import HeaderSection from '../../components/HeaderSection'
import { LargeAlert } from '../../components/Styles/alert.style'
import CMSButton from '../../components/Styles/button.style'
import { RowBetween } from '../../components/Styles/row.style'
import { Department } from '../../interfaces/permissions.interface'
import {
  createDepartment,
  editDepartment,
  getDepartments,
} from '../../services/admin-permissions/department.api'
import { TZeroOne } from '../../services/invoice/invoice.interface'
const { Search } = Input

export const DepartmentList = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [departments, setDepartments] = useState<Department[]>([])

  const departmentIdForm = form.getFieldValue('department_id')
  const isCreateMode = (!departmentIdForm || departmentIdForm === 0) && openModal
  const [filteredDepartments, setFilteredDepartments] = useState<Department[]>([])

  const haveDepartments = filteredDepartments.length > 0

  const triggerRefresh = async () => {
    form.resetFields()
    setOpenModal(false)
    await loadData()
  }

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    setLoading(true)
    if (debouncedQuery || debouncedQuery === '') {
      const regex = new RegExp(debouncedQuery, 'gi')
      const filteredData = departments.filter(
        (item) => regex.test(item.department_name) || regex.test(item.department_des),
      )
      setFilteredDepartments(filteredData)
    } else {
      setFilteredDepartments(departments)
    }
    setLoading(false)
  }, [debouncedQuery])

  const onFinish = async (values: Department) => {
    setLoading(true)
    if (isCreateMode)
      return await createDepartment(values)
        .then(async (res) => {
          if (res.created) await triggerRefresh()
        })
        .finally(() => setLoading(false))
    else
      return await editDepartment(departmentIdForm, values)
        .then(async (res) => {
          if (res.updated) await triggerRefresh()
        })
        .finally(() => setLoading(false))
  }

  const modalTitle = isCreateMode ? 'Create Department' : `Edit Department #${departmentIdForm}`

  const showActiveBanner = (item: TZeroOne) =>
    item === 1 ? <Alert message="YES" type="success" /> : <Alert message="NO" type="error" />

  const columns: ColumnType<Department>[] = [
    {
      title: 'ID',
      align: 'center' as const,
      dataIndex: 'department_id',
      key: 'department_id',
    },
    {
      title: 'Department Name',
      align: 'center' as const,
      dataIndex: 'department_name',
      key: 'department_name',
    },
    {
      title: 'Department Description',
      align: 'center' as const,
      dataIndex: 'department_des',
      key: 'department_des',
    },
    {
      title: 'Active',
      align: 'center' as const,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (item: Department['is_active']) => showActiveBanner(item),
    },
    {
      title: 'Action',
      align: 'center' as const,
      render: (item: Department) => (
        <Space>
          <CMSButton
            buttonColor="blue"
            onClick={() => {
              form.setFieldsValue(item)
              setOpenModal(true)
            }}
          >
            EDIT
          </CMSButton>
          <CMSButton
            buttonColor="purple"
            onClick={() => {
              history.push(`/department/${item.department_id}`)
            }}
          >
            DETAIL
          </CMSButton>
        </Space>
      ),
    },
  ]

  const loadData = async () => {
    setLoading(true)
    const res = await getDepartments()
    setDepartments(res)
    setFilteredDepartments(res)
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <HeaderSection
        doAction={() => setOpenModal(true)}
        tmpBreadcrumb={[
          {
            title: 'Department Management',
            link: '/department',
          },
        ]}
        haveCreateButton={true}
        title="Department List"
        buttonTitle="Create Department"
      />
      <Divider />
      <Search
        value={query}
        placeholder="Search Department by description or name"
        onChange={(e) => setQuery(e.target.value)}
        allowClear
      />

      <Divider />
      <If condition={!haveDepartments && !loading}>
        <Then>
          <LargeAlert type="info" message="No departments found" />
        </Then>
        <Else>
          <Table
            rowKey="department_id"
            dataSource={filteredDepartments}
            columns={columns}
            loading={loading}
          />
        </Else>
      </If>

      <Modal open={openModal} title={modalTitle} footer={false} centered closable={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="department_id" hidden>
            <input />
          </Form.Item>
          <Form.Item
            label="Department Name"
            name="department_name"
            rules={[
              { required: true, message: 'Please input department name!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('department_name') === value) return Promise.resolve()
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const foundDepartment = departments.find((item) => item.department_name === value)
                  const isDuplicate =
                    foundDepartment &&
                    foundDepartment.department_id !== getFieldValue('department_id')
                  if (!isDuplicate) return Promise.resolve()
                  return Promise.reject(new Error('This department name is already exist!'))
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Department Description"
            name="department_des"
            rules={[{ required: true, message: 'Please input department description!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Active"
            name="is_active"
            rules={[{ required: true, message: 'Please select active status!' }]}
          >
            <Radio.Group>
              <Radio.Button value={1}>YES</Radio.Button>
              <Radio.Button value={0}>NO</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <RowBetween>
            <CMSButton buttonColor="red" onClick={() => triggerRefresh()} loading={loading}>
              CANCEL
            </CMSButton>
            <CMSButton buttonColor="green" onClick={() => form.submit()} loading={loading}>
              SUBMIT
            </CMSButton>
          </RowBetween>
        </Form>
      </Modal>
    </>
  )
}
