import { GroupedMenu, Menu } from 'src/interfaces/permissions.interface'

export const haveDescriptionName = (menu: GroupedMenu, query: string): boolean => {
  const regexQuery = new RegExp(query, 'i')
  const findQuery = (item: Menu): boolean => {
    return regexQuery.test(item.menu_name) || regexQuery.test(item.menu_description)
  }
  const isFound = menu.grouped.some(findQuery)
  return isFound ? true : findQuery((menu as unknown) as Menu)
}
