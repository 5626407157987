const formatNumber = (num: number): string => {
  const numStr = num.toString()
  const numArr = numStr.split('').reverse()
  const formattedArr = []
  for (let i = 0; i < numArr.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedArr.push(',')
    }
    formattedArr.push(numArr[i])
  }
  return formattedArr.reverse().join('')
}

export default formatNumber
