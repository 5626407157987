import { Alert, Form, Input, Select, Space, Spin, Tooltip } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CMSHeader } from 'src/components/CMSHeader'
import Notification from '../../components/Notification'
import CMSButton from '../../components/Styles/button.style'
import CMSTable, { TableParams } from '../../components/UserCredit/CMSTable'
import { IMetaPage } from '../../interfaces'
import { ExtendedPermission, PermissionParams } from '../../interfaces/permissions.interface'
import { generateId } from '../../libs/utils'
import {
  IGetPermissionsOptions,
  editPermission,
  editStatePermission,
  getPermissionList,
  getPermissionsInformation,
} from '../../services/admin-permissions/permission.api'
import { TZeroOne } from '../../services/invoice/invoice.interface'
const { Search } = Input

export const isPermissionActivated = (isActive: TZeroOne): boolean =>
  isActive === 1 ? true : false

export type PermissionBadgeProps = {
  keyName: 'can_read' | 'can_delete' | 'can_create' | 'can_update'
  record: ExtendedPermission
}
export const PermissionBadge = ({ record, keyName }: PermissionBadgeProps) => {
  const { is_active, permission_id } = record
  const key = generateId(Math.random() * 1000)
  const [currentKeyValue, setCurrentKeyValue] = useState<TZeroOne>(record[keyName])
  const [loading, setLoading] = useState(false)

  const currentKeyValueMemo = useMemo(() => {
    return currentKeyValue
  }, [currentKeyValue])

  const changeActivationState = async () => {
    setLoading(true)
    const newIsActivateState: TZeroOne = currentKeyValueMemo === 1 ? 0 : 1 // The new state wasn't updated yet so we reverse it
    const permissionId = permission_id as number
    const payload: any = { [keyName]: newIsActivateState }
    await editStatePermission(permissionId, payload)
      .then((res) => {
        if (res.updated) setCurrentKeyValue(newIsActivateState)
      })
      .catch(() => {
        Notification('Something went wrong', 'error')
      })
      .finally(() => setLoading(false))
  }

  const permissionIsActive = isPermissionActivated(is_active)
  const toolTipText = 'Activate this permission in order to modify'
  const selectedAlert =
    currentKeyValueMemo === 1 ? (
      <Alert
        key={key}
        message="YES"
        type="success"
        onClick={() => {
          changeActivationState()
        }}
      />
    ) : (
      <Alert message="NO" type="error" onClick={() => changeActivationState()} key={key} />
    )

  const keyState = keyName.split('_')[1].toUpperCase()
  const newActivateStateText = currentKeyValueMemo === 1 ? 'NO' : 'YES'

  if (!permissionIsActive)
    return (
      <Tooltip title={toolTipText}>
        <Alert type="info" message="DEACTIVATED" />
      </Tooltip>
    )
  if (loading)
    return (
      <div style={{ height: '40px', maxWidth: '120px', minWidth: '80px', display: 'grid' }}>
        <Spin size="default" style={{ placeSelf: 'center' }} />
      </div>
    )
  return (
    <Tooltip title={`Change ${keyState} from #${permission_id} to ${newActivateStateText}`}>
      {selectedAlert}
    </Tooltip>
  )
}

type ActivationButtonProps = {
  record: ExtendedPermission
  loadData: () => void
}

export const ActivationButton = ({ record, loadData }: ActivationButtonProps) => {
  const { is_active, permission_id } = record
  const isActivePermission = isPermissionActivated(is_active)
  const [loadingButton, setLoadingButton] = useState(false)

  const changeIsActivateState = async () => {
    setLoadingButton(true)
    const newActivateState = isActivePermission === true ? 0 : 1
    const notificationTitle =
      newActivateState === 1 ? `Permission activated` : `Permission deactivated`
    const payload: ExtendedPermission = { ...record, is_active: newActivateState }
    const permissionId = permission_id as number
    editPermission(permissionId, payload)
      .then(() => {
        Notification(notificationTitle, 'success')
        loadData()
      })
      .finally(() => setLoadingButton(false))
  }

  const buttonTitle = isActivePermission ? 'DEACTIVATE' : 'ACTIVATE'
  return (
    <CMSButton buttonColor="purple" loading={loadingButton} onClick={() => changeIsActivateState()}>
      {buttonTitle} PERMISSION
    </CMSButton>
  )
}

export default function PermissionList() {
  const history = useHistory()
  const [queryForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [permissionsData, setPermissionsData] = useState<ExtendedPermission[]>([])
  const [params, setParams] = useState<TableParams<PermissionParams>>({
    page: 1,
    results: 20,
    sortBy: 'permission_id',
    orderBy: 'DESC',
    menuId: undefined,
    departmentId: undefined,
    positionId: undefined,
  })

  const onAction = (paramsInfo: typeof params) => setParams({ ...params, ...paramsInfo })

  const [pagination, setPagination] = useState<IMetaPage>({
    page: 1,
    total: 0,
    results: 20,
    pages: 1,
  })

  const [globalOptions, setGlobalOptions] = useState<IGetPermissionsOptions>({
    menu: [],
    department: [],
    position: [],
  })

  const loadData = async () => {
    setLoading(true)
    await getPermissionList(params).then((res) => {
      const { meta, data } = res
      setPagination({ ...pagination, ...meta })
      setPermissionsData(data)
      setLoading(false)
    })
  }

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'permission_id',
      key: 'permission_id',
      align: 'center' as const,
      sorter: (a: any, b: any) => a.permission_id - b.permission_id,
    },
    {
      title: 'Department',
      dataIndex: 'department_info',
      key: 'department_info',
      align: 'center' as const,
      render: (info: ExtendedPermission['department_info']) => (
        <>
          [ # {info?.department_id} ] {info?.department_name}
        </>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position_info',
      key: 'position_info',
      align: 'center' as const,
      render: (info: ExtendedPermission['position_info']) => (
        <>
          [ # {info?.position_id} ] {info?.position_name}
        </>
      ),
    },
    {
      title: 'Menu',
      align: 'center' as const,
      dataIndex: 'menu_info',
      key: 'menu_info',
      render: (info: ExtendedPermission['menu_info']) => (
        <>
          [ # {info?.menu_id} ] {info?.menu_name}
        </>
      ),
    },
    {
      title: 'READ',
      align: 'center' as const,
      render: (record: ExtendedPermission) => (
        <PermissionBadge keyName="can_read" record={record} key={`read${record.permission_id}`} />
      ),
    },
    {
      title: 'CREATE',
      align: 'center' as const,
      render: (record: ExtendedPermission) => (
        <PermissionBadge
          keyName="can_create"
          record={record}
          key={`create${record.permission_id}`}
        />
      ),
    },
    {
      title: 'UPDATE',
      align: 'center' as const,
      render: (record: ExtendedPermission) => (
        <PermissionBadge
          keyName="can_update"
          record={record}
          key={`update${record.permission_id}`}
        />
      ),
    },
    {
      title: 'DELETE',
      align: 'center' as const,
      render: (record: ExtendedPermission) => (
        <PermissionBadge
          keyName="can_delete"
          record={record}
          key={`delete${record.permission_id}`}
        />
      ),
    },
    {
      title: 'Actions',
      align: 'center' as const,
      render: (record: ExtendedPermission) => (
        <ActivationButton record={record} loadData={loadData} />
      ),
    },
  ]

  useEffect(() => {
    loadData()
  }, [params])

  useEffect(() => {
    const getOptionsSelect = async () => {
      const res = await getPermissionsInformation()
      setGlobalOptions(res)
    }
    getOptionsSelect()
  }, [])

  // if (!isPageReady) return <Skeleton />
  return (
    <>
      <CMSHeader
        headerLeft="Permission List"
        headerRight={() => (
          <Space size={40}>
            <Link to="/permissions/create">
              <CMSButton buttonColor="lg-blue">Create Permission</CMSButton>
            </Link>
          </Space>
        )}
        renderHeaderBottom={() => {
          return (
            <Form
              form={queryForm}
              onValuesChange={(e, _) => {
                setParams({ ...params, ...e })
              }}
            >
              <Form.Item label="Department" name="departmentId">
                <Select
                  disabled={loading}
                  placeholder="Select department"
                  options={globalOptions.department}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) => {
                    const reg = new RegExp(input, 'i')
                    return reg.test(option.label)
                  }}
                />
              </Form.Item>
              <Form.Item label="Menu" name="menuId">
                <Select
                  placeholder="Select menu"
                  options={globalOptions.menu}
                  allowClear
                  disabled={loading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) => {
                    const reg = new RegExp(input, 'i')
                    return reg.test(option.label)
                  }}
                />
              </Form.Item>
              <Form.Item label="Position" name="positionId">
                <Select
                  disabled={loading}
                  placeholder="Select job position"
                  options={globalOptions.position}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) => {
                    const reg = new RegExp(input, 'i')
                    return reg.test(option.label)
                  }}
                />
              </Form.Item>
            </Form>
          )
        }}
      />
      <CMSTable
        loading={loading}
        rowKey="permission_id"
        pagination={pagination}
        dataSource={permissionsData}
        onAction={onAction}
        params={params}
        columns={columns}
      />
    </>
  )
}
