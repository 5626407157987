/* eslint-disable @typescript-eslint/no-explicit-any */
import { isUndefined } from 'lodash'
import React, { createContext, PropsWithChildren, useContext, useMemo, useReducer } from 'react'
import { contextActions } from './action'
import { crudReducer, initialCrudState } from './reducer'
import { contextSelectors } from './selectors'
import { CrudAction, CrudState } from './types'

const CrudContext = createContext([])

const CrudContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [state, dispatch] = useReducer(crudReducer, initialCrudState)

  const value = useMemo(() => {
    return [state, dispatch]
  }, [state, dispatch])

  return <CrudContext.Provider value={value as any}>{children}</CrudContext.Provider>
}

type CrudContextReturn = {
  state: CrudState
  crudActions: ReturnType<typeof contextActions>
  crudSelectors: ReturnType<typeof contextSelectors>
}

function useCrudContext(): CrudContextReturn {
  const context = useContext(CrudContext)
  if (isUndefined(context)) {
    throw new Error('useCrudContext must be used within a CrudProvider')
  }
  const [state, dispatch] = context
  const crudActions = contextActions(dispatch as React.Dispatch<CrudAction>)
  const crudSelectors = contextSelectors(state as CrudState)
  return { state, crudActions, crudSelectors }
}

export { CrudContextProvider, useCrudContext }
