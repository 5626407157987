import { Divider, Typography } from 'antd'
import React from 'react'
import { useMobile } from 'src/hooks/useMobile'
import { RowBetween } from './Styles/row.style'

const { Title } = Typography

type CMSHeaderProps = {
  headerLeft: React.ReactNode
  headerRight?: () => React.ReactNode
  renderHeaderBottom?: () => React.ReactNode
  divider?: true | 'none'
}

/**
 * Header component for CMS
 * @description Already include responsive design for mobile
 */
export const CMSHeader = ({
  headerLeft,
  headerRight,
  renderHeaderBottom,
  divider = true,
}: CMSHeaderProps): JSX.Element => {
  const { isMobile } = useMobile()

  return (
    <>
      <RowBetween>
        {typeof headerLeft === 'string' ? (
          <Title style={{ margin: 0 }} level={2}>
            {headerLeft}
          </Title>
        ) : (
          headerLeft
        )}
        {!isMobile && headerRight ? headerRight() : <></>}
      </RowBetween>
      {isMobile && headerRight && <div style={{ marginTop: '0.5rem' }}>{headerRight()}</div>}
      {renderHeaderBottom && <div style={{ marginTop: '1rem' }}>{renderHeaderBottom()}</div>}
      {divider !== 'none' && <Divider />}
    </>
  )
}
