import { Alert, Divider, Form, Input, Modal, Radio, Space, Typography } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { Else, If, Then } from 'react-if'
import { useHistory, useParams } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { LargeAlert } from '../../components/Styles/alert.style'
import CMSButton from '../../components/Styles/button.style'
import { RowBetween } from '../../components/Styles/row.style'
import { Department, JobPosition } from '../../interfaces/permissions.interface'
import {
  createJobPosition,
  editPosition,
  getPositionsByDepartmentId,
} from '../../services/admin-permissions/position.api'
import { TZeroOne } from '../../services/invoice/invoice.interface'
const { Search } = Input
const { Title } = Typography

export const showActiveBanner = (item: TZeroOne) =>
  item === 1 ? <Alert message="YES" type="success" /> : <Alert message="NO" type="error" />

export const PositionList = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams<{ id: string }>()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [positions, setPositions] = useState<JobPosition[]>([])

  const positionIdForm = form.getFieldValue('position_id')
  const isCreateMode = (!positionIdForm || positionIdForm === 0) && openModal
  const [filteredPositions, setFilteredPositions] = useState<JobPosition[]>([])

  const [department, setDepartment] = useState<Department>({} as Department)

  const havePositions = filteredPositions.length > 0

  const triggerRefresh = async () => {
    form.resetFields()
    setOpenModal(false)
    await loadData()
  }

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    setLoading(true)
    if (debouncedQuery || debouncedQuery === '') {
      const regex = new RegExp(debouncedQuery, 'gi')
      const filteredData = positions.filter(
        (item) => regex.test(item.position_des) || regex.test(item.position_name),
      )
      setFilteredPositions(filteredData)
    } else {
      setFilteredPositions(positions)
    }
    setLoading(false)
  }, [debouncedQuery])

  const onFinish = async (values: JobPosition) => {
    setLoading(true)
    if (isCreateMode)
      return await createJobPosition(values)
        .then(async (res) => {
          if (res.created) await triggerRefresh()
        })
        .finally(() => setLoading(false))
    else
      return await editPosition(positionIdForm, values)
        .then(async (res) => {
          if (res.updated) await triggerRefresh()
        })
        .finally(() => setLoading(false))
  }

  const modalTitle = isCreateMode ? 'Create Position' : `Edit Position #${positionIdForm}`

  const columns: ColumnType<JobPosition>[] = [
    {
      title: 'Position ID',
      align: 'center' as const,
      dataIndex: 'position_id',
      key: 'position_id',
    },
    {
      title: 'Position Name',
      align: 'center' as const,
      dataIndex: 'position_name',
      key: 'position_name',
    },
    {
      title: 'Description',
      align: 'center' as const,
      dataIndex: 'position_des',
      key: 'position_des',
    },
    {
      title: 'Active',
      align: 'center' as const,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (item: JobPosition['is_active']) => showActiveBanner(item),
    },
    {
      title: 'Action',
      align: 'center' as const,
      render: (item: Department) => (
        <Space>
          <CMSButton
            buttonColor="blue"
            onClick={() => {
              form.setFieldsValue(item)
              setOpenModal(true)
            }}
          >
            EDIT
          </CMSButton>
        </Space>
      ),
    },
  ]

  const loadData = async () => {
    setLoading(true)
    const res = await getPositionsByDepartmentId(Number(id))
    const { department, positions } = res
    setPositions(positions)
    setFilteredPositions(positions)
    setDepartment(department)
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <RowBetween>
        <Title level={3} style={{ margin: 0 }}>
          Position for (#{id}) {department.department_name}
        </Title>
        <CMSButton
          buttonColor="green"
          onClick={() => {
            history.push(`/department/`)
          }}
        >
          Return to department page
        </CMSButton>
        <CMSButton
          buttonColor="blue"
          onClick={() => {
            form.setFieldValue('department_id', Number(id))
            setOpenModal(true)
          }}
        >
          Create Position
        </CMSButton>
      </RowBetween>
      <Divider />
      <Search
        value={query}
        placeholder="Search Position by description or name"
        onChange={(e) => setQuery(e.target.value)}
        allowClear
      />

      <Divider />
      <If condition={!havePositions && !loading}>
        <Then>
          <LargeAlert type="info" message="No positions found" />
        </Then>
        <Else>
          <Table
            rowKey="position_id"
            dataSource={filteredPositions}
            columns={columns}
            loading={loading}
          />
        </Else>
      </If>

      <Modal open={openModal} title={modalTitle} footer={false} centered closable={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="position_id" hidden>
            <input />
          </Form.Item>
          <Form.Item name="department_id" label="Department ID">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Position Name"
            name="position_name"
            rules={[
              { required: true, message: 'Please input position name!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('position_name') === value) return Promise.resolve()
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const foundPosition = positions.find((item) => item.position_name === value)
                  const isDuplicate =
                    foundPosition && foundPosition.position_id !== getFieldValue('position_id')
                  if (!isDuplicate) return Promise.resolve()
                  return Promise.reject(new Error('This position name is already exist!'))
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Position Description"
            name="position_des"
            rules={[{ required: true, message: 'Please input position description!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Active"
            name="is_active"
            rules={[{ required: true, message: 'Please select active status!' }]}
          >
            <Radio.Group>
              <Radio.Button value={1}>YES</Radio.Button>
              <Radio.Button value={0}>NO</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <RowBetween>
            <CMSButton buttonColor="red" onClick={() => triggerRefresh()} loading={loading}>
              CANCEL
            </CMSButton>
            <CMSButton buttonColor="green" onClick={() => form.submit()} loading={loading}>
              SUBMIT
            </CMSButton>
          </RowBetween>
        </Form>
      </Modal>
    </>
  )
}
