/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Layout } from 'antd'
import 'antd/dist/antd.less'
import React from 'react'
import { MenuItems, menus } from '../../config/menu'
import { AuthContext } from '../../contexts/Auth'
import '../../static/css/index.less'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import SideMenu from '../SideMenu/SideMenu'

const { Content, Footer } = Layout

interface AppProps {
  children: React.ReactNode
}

interface AppState {
  collapsed: boolean
  user: any
}

type CRUD = 'c' | 'r' | 'u' | 'd'

interface listMenu {
  menu_id: number
  group: number
  permission: Record<CRUD, 0 | 1>
  menu_name: string
  menu_path: string
  menu_label: string
}

interface MenuLayout extends listMenu {
  key: string
  subMenu?: listMenu[]
}

class Page extends React.Component<AppProps, AppState> {
  static contextType = AuthContext
  state = {
    collapsed: false,
    user: {
      userId: null,
      userName: '',
      userToken: null,
    },
  }
  user = {
    userId: null,
    userName: '',
    userToken: null,
  }

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed })
  }

  genMenus(menus: MenuItems[]): MenuItems[] {
    const listMenu: listMenu[] = this.context?.permission?.listMenu

    // 1 - Change API permission to add menu route
    const allMenus = JSON.parse(localStorage.getItem('permission') as string).listMenu
    // const listMenuWithAllowedPermissions = listMenu?.filter((listMenu) => {
    //   const crudPermissions = Object.values(listMenu.permission)
    //   return crudPermissions.some((permission) => permission === 1)
    // })
    const listMenuWithAllowedPermissions = [...allMenus]

    const listMenuNoGroup = listMenuWithAllowedPermissions?.filter(
      (listMenu) => listMenu.group === null,
    )
    const listMenuHaveGroup = listMenuWithAllowedPermissions?.filter(
      (listMenu) => listMenu.group !== null,
    )

    // modify the API later
    const groupedMenus = listMenuHaveGroup.reduce((acc: Record<string, listMenu[]>, listItem) => {
      if (!acc[listItem.group]) acc[listItem.group] = [listItem]
      else acc[listItem.group].push(listItem)
      return acc
    }, {})

    const menuLayout: MenuLayout[] = listMenuNoGroup?.map((listMenu) => {
      const findSubMenu = Object.entries(groupedMenus).find(
        ([group, _]) => group === String(listMenu.menu_id),
      )
      const haveSubMenu = findSubMenu !== undefined

      if (haveSubMenu) {
        return {
          ...listMenu,
          key: listMenu.menu_name,
          subMenu: findSubMenu![1],
        }
      } else {
        return { ...listMenu, key: listMenu.menu_name }
      }
    })

    // Previous version
    const menuAllow: MenuItems[] = []
    // for (const group_menu of menus) {
    //   const { key, subMenu } = group_menu
    //   if (!subMenu) {
    //     const check = listMenuNoGroup?.findIndex((v) => v.menu_id === key)
    //     if (check >= 0) menuAllow.push(group_menu)
    //   } else {
    //     const check = listMenuHaveGroup?.findIndex((v) => v.group === key)
    //     if (check >= 0) {
    //       const list = listMenuHaveGroup?.filter((v) => v.group === key)
    //       const subMenuAllow: MenuItems[] = []
    //       for (const subSubmenu of subMenu) {
    //         const check = list?.findIndex((v) => v.menu_id === subSubmenu.key)
    //         if (check >= 0) subMenuAllow.push(subSubmenu)
    //       }
    //       if (subMenuAllow.length > 0) {
    //         menuAllow.push({
    //           key: group_menu.key,
    //           title: group_menu.title,
    //           link: group_menu.link,
    //           subMenu: subMenuAllow,
    //         })
    //       }
    //     }
    //   }
    // }

    for (const menu of menuLayout) {
      const subMenuObject = !menu.subMenu
        ? {}
        : {
            subMenu: (menu?.subMenu!.map((subMenu) => ({
              key: subMenu.menu_name,
              title: subMenu.menu_label,
              link: subMenu.menu_path,
            })) as unknown) as MenuItems[],
          }
      menuAllow.push({
        key: menu.menu_name,
        title: menu.menu_label,
        link: menu.menu_path,
        ...subMenuObject,
      })
    }

    return menuAllow
  }

  render(): JSX.Element {
    const { collapsed } = this.state
    return (
      <>
        <Layout style={{ height: '100vh' }}>
          <SideMenu collapsed={false} menus={this.genMenus(menus)} />
          <Layout className="site-layout" style={{ height: '100vh' }}>
            <HeaderMenu
              headerText={this.genMenus(menus)}
              username={this.context.user.username}
              location={this.props.children}
            />
            <Content style={{ margin: '16px', overflow: 'auto' }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360, height: '100%', overflow: 'auto' }}
              >
                {this.props.children}
              </div>
            </Content>
            {/* <Footer style={{ textAlign: 'center' }}>Develop by HAUP</Footer> */}
          </Layout>
        </Layout>
      </>
    )
  }
}

export default Page
