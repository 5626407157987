import { Link, useLocation } from 'react-router-dom'
import style from './Verifiedvehicle.module.css'
export const Breadcrumb = (props: any) => {
  const location = useLocation<any>()
  return (
    <>
      <div className="box-breadcrumb">
        <ul className={`${style.breadcrumb}`}>
          {props.breadcrumb?.map((val: any, key: number) => {
            return (
              <li
                className={`${style.item_breadcrumb}`}
                aria-current="page"
                key={`breadcrumb-key-${key}`}
              >
                <Link
                  to={{
                    pathname: val.link,
                    state: { vehicleId: val.vehicleId },
                  }}
                  className={`${style.link_breadcrumb}`}
                >
                  {val.title}
                </Link>
                {/* <a className={`${style.link_breadcrumb}`} href={val.link}>
                  {val.title}
                </a> */}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
