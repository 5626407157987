// eslint-disable-next-line prettier/prettier
import { AxiosFunction } from '../../axios.function';
// eslint-disable-next-line prettier/prettier
import Notification from '../../components/Notification';
// eslint-disable-next-line prettier/prettier
import { Department, JobPosition } from '../../interfaces/permissions.interface';

export const getPositionsByDepartmentId = async (
  departmentId: number,
): Promise<{ positions: JobPosition[]; department: Department }> => {
  const response = await AxiosFunction({
    url: `/cms/apa/position/${departmentId}/department`,
    method: 'get',
    queryParams: {},
  })
    .then((res) => {
      const { data } = res
      return data
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching positions', 'error')
      return []
    })
  return response
}

export const createJobPosition = async (body: JobPosition): Promise<{ created: boolean }> => {
  const response = await AxiosFunction({
    method: 'post',
    url: '/cms/apa/position',
    body,
    queryParams: {},
  })
    .then((res) => {
      Notification(
        `Department #${res.data} ${body.position_name} was successfully created`,
        'success',
      )
      return { created: true, message: 'Position created successfully' }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating department', 'error')
      return { created: false }
    })
  return response
}

export const editPosition = async (
  positionId: number,
  body: JobPosition,
): Promise<{ updated: boolean }> => {
  const response = await AxiosFunction({
    method: 'put',
    url: `/cms/apa/position/${positionId}`,
    body,
    queryParams: {},
  })
    .then(() => {
      Notification(`Position ${body.position_name} was successfully updated`, 'success')
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating position', 'error')
      return { updated: false }
    })
  return response
}
