import { CrudAction, CrudActionType, CrudState } from './types'

export const initialCrudState: CrudState = {
  list: [],
  meta: undefined,
  loading: false,
  open: null,
  id: undefined,
  name: undefined,
  data: undefined,
}

export const crudReducer = (state: CrudState = initialCrudState, action: CrudAction): CrudState => {
  switch (action.type) {
    case CrudActionType.REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case CrudActionType.UPDATE_LIST: {
      return {
        ...state,
        list: action.payload.list,
      }
    }
    case CrudActionType.REQUEST_SUCCESS: {
      return {
        ...state,
        open: null,
        data: undefined,
        id: undefined,
        loading: false,
      }
    }
    case CrudActionType.REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case CrudActionType.LOAD_DATA: {
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta,
      }
    }
    case CrudActionType.RESET_MODAL: {
      return {
        ...state,
        open: null,
        data: undefined,
        id: undefined,
        loading: false,
      }
    }
    case CrudActionType.RESET_REQUEST: {
      return {
        ...state,
        loading: false,
        list: [],
        meta: undefined,
      }
    }
    case CrudActionType.CREATE_DATA: {
      return {
        ...state,
        open: 'create',
        data: undefined,
        id: undefined,
        name: action.payload?.name,
      }
    }
    case CrudActionType.UPDATE_DATA: {
      return {
        ...state,
        open: 'update',
        data: action.payload.data,
        id: action.payload.id,
        name: action.payload.name,
      }
    }
    case CrudActionType.DELETE_DATA: {
      return {
        ...state,
        open: 'delete',
        data: action.payload,
        id: action.payload.id,
        name: action.payload.name,
      }
    }
    default: {
      return state
    }
  }
}
