import { UserOutlined } from '@ant-design/icons'
import { Col, Layout, Popover, Row } from 'antd'
import React from 'react'
import Clock from 'react-live-clock'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// ----------- TYPES -------------
interface IHeader {
  headerText: any
  username: string
  location: any
}

interface IHeaderTitle {
  link: string
  title: string
  subMenu: any
}

interface IHeaderTitle {
  link: string
  title: string
}

const { Header } = Layout

// Screen size
const screen_md_size = '768px'

// -------------- STYLES COMPONENTS ------------
const HeaderText = styled.div`
  color: white;
  font-size: 1.25rem;
  text-shadow: 6px 6px 7px black;
  @media (max-width: ${screen_md_size}) {
    text-align: center;
  }
`
const IconUserInfo = styled(UserOutlined)`
  font-size: 40px;
  @media (max-width: ${screen_md_size}) {
    display: none;
  }
`
const IconUser = styled(UserOutlined)`
  font-size: 30px;
`
const LoggedIn = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

class App extends React.Component<IHeader, IHeader> {
  headerText: string
  username: string
  subMenu: any
  location: string
  constructor(props: IHeader) {
    super(props)
    this.headerText = props.headerText
    this.username = props.username
    this.location = props.location
    this.state = { headerText: this.headerText, username: this.username, location: this.location }
  }
  componentDidMount() {
    this.username = this.props.username
  }

  componentDidUpdate() {
    if (this.state.username !== this.props.username) {
      this.setState({ username: this.props.username })
    }
  }
  render(): JSX.Element {
    const titleHeader: JSX.Element[] = []
    const locationNow: string = location.pathname
    const { headerText, username } = this.state

    headerText.map((item: IHeaderTitle) => {
      const { link, title, subMenu } = item || {}
      if (subMenu) {
        subMenu.map((item: any) => {
          const { link, title } = item || {}
          if (link === locationNow) {
            titleHeader.push(<HeaderText>{title}</HeaderText>)
          }
        })
      }
      if (link === locationNow) {
        titleHeader.push(<HeaderText>{title}</HeaderText>)
      }
    })

    const content = (
      <>
        <Row style={{ maxWidth: '100%' }}>
          <LoggedIn>
            <Col md={6} sm={8} xs={8} style={{ marginRight: 10 }}>
              <IconUserInfo />
            </Col>
          </LoggedIn>
          <Col md={18} sm={16} xs={16}>
            <Row>{username}</Row>
            <Row>
              <Link to="/logout">Logout</Link>
            </Row>
          </Col>
        </Row>
      </>
    )
    return (
      <Header style={{ padding: '0 1rem', display: 'grid' }}>
        <Row>
          <Col xs={2} md={0}></Col>
          <Col xs={20} md={14} style={{}}>
            {/* {titleHeader} */}
          </Col>

          {/* rightNav is displayed when width>768px */}
          <Col className="rightNav" sm={0} md={8}>
            <LoggedIn>
              Logged in as: {username}
              <p>
                <Clock
                  format={'dddd, MMMM DD, yyyy | HH:mm'}
                  ticking={true}
                  timezone={'Asia/Bangkok'}
                />
              </p>
            </LoggedIn>
          </Col>
          <Col className="rightNav" xs={2}>
            <Popover placement="bottomRight" content={content} trigger="hover">
              <IconUser />
            </Popover>
          </Col>
        </Row>
      </Header>
    )
  }
}

export default App
