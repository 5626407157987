import _axios from 'axios'
import config from '../../config'
import { getUserToken } from '../../contexts/Auth'
import { MongoosePaginate } from '../../interfaces/mongoose-paginate.interface'

const { restfulService } = config

export interface GetMeterLogsParams {
  limit?: number
  page?: number
  sort?: string
  reservationId?: number
  meterAtFrom?: number
  meterAtTo?: number
  dateFrom?: Date
  dateTo?: Date
}

export interface MeterLog {
  _id: string
  __v: number
  reservationId: number
  meterAtWh: number
  data: any
}

export type MeterLogsResponseType = MongoosePaginate<MeterLog>

function createAxios() {
  return _axios.create({
    baseURL: restfulService.URL,
    headers: {
      Authorization: getUserToken(),
    },
  })
}

export async function getMeterLogs(params?: GetMeterLogsParams): Promise<any> {
  const axios = createAxios()
  const endpoint = `/cms/evcharger/meter-log`
  const results = await axios.get<MeterLogsResponseType>(endpoint, {
    params,
  })
  return results.data
}
