/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { AnyObject } from 'chart.js/types/basic'
import { IMetaPage } from 'src/interfaces'
import { HttpResponse } from 'src/interfaces/request'

export type UrlState = {
  params: AnyObject
  fn?: (params: AnyObject) => Promise<HttpResponse<any>>
  loading: boolean
  isSuccess: boolean
  list: {
    data: any[]
    meta: IMetaPage
  }
}

type BaseAction<Type, Payload = undefined> = Payload extends undefined
  ? { type: Type; payload?: undefined }
  : { type: Type; payload: Payload }

export enum UrlActionType {
  FETCH_INIT = 'FETCH_INIT',
  REQUEST_LOADING = 'REQUEST_LOADING',
  REQUEST_SUCCESS = 'REQUEST_SUCCESS',
  REQUEST_FAILED = 'REQUEST_FAILED',
  CHANGE_URL = 'CHANGE_URL',
  RESET = 'RESET',
}

type UrlFetchAction = BaseAction<
  UrlActionType.FETCH_INIT,
  {
    list: UrlState['fn']
    params: UrlState['params']
  }
>

type UrlRequestLoadingAction = BaseAction<UrlActionType.REQUEST_LOADING>
type UrlRequestFailedAction = BaseAction<UrlActionType.REQUEST_FAILED>
type UrlRequestSuccessAction = BaseAction<
  UrlActionType.REQUEST_SUCCESS,
  {
    data: any[]
    meta: IMetaPage
  }
>
type UrlResetAction = BaseAction<UrlActionType.RESET>
type UrlChangeUrlAction = BaseAction<UrlActionType.CHANGE_URL, { params: AnyObject }>

export type UrlAction =
  | UrlFetchAction
  | UrlRequestLoadingAction
  | UrlRequestFailedAction
  | UrlRequestSuccessAction
  | UrlResetAction
  | UrlChangeUrlAction

export type UrlDispatch = (action: UrlAction) => any
