import { Alert, AlertProps } from 'antd'

export const LargeAlert = ({ ...props }: AlertProps) => {
  return (
    <Alert
      {...props}
      style={{ margin: '1rem 0', width: '100%', textAlign: 'center', fontWeight: 700 }}
    />
  )
}
