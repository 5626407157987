import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Router from './components/Router'
import { CrudContextProvider } from './contexts/Crud/useCrudContext'
import { ReservationProvider } from './contexts/Reservation/reservation'
import { store } from './redux/store'
import './static/style.scss'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CrudContextProvider>
        <ReservationProvider>
          <BrowserRouter basename="/">
            <Router />
          </BrowserRouter>
        </ReservationProvider>
      </CrudContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
