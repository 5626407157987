import { AxiosFunction } from '../../axios.function'
import Notification from '../../components/Notification'
import { TableParams } from '../../components/UserCredit/CMSTable'
import { PermissionBadgeProps } from '../../containers/PermissionsList'
import { IMetaPage } from '../../interfaces'
import {
  Department,
  ExtendedPermission,
  Menu,
  Permission,
  PermissionCreate,
  PermissionParams,
} from '../../interfaces/permissions.interface'
import { TZeroOne } from '../invoice/invoice.interface'

export type GetPermissionsResponse = {
  meta: IMetaPage
  data: ExtendedPermission[]
}
export const getPermissionList = async (
  params: TableParams<PermissionParams>,
): Promise<GetPermissionsResponse> => {
  return await AxiosFunction({
    method: 'get',
    url: '/cms/apa/permission',
    queryParams: params,
  })
    .then((res) => {
      const { data } = res
      return { meta: res.meta, data } as GetPermissionsResponse
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching permissions', 'error')
      return {
        meta: { page: 1, pages: 1, results: 0, total: 0 },
        data: [],
      } as GetPermissionsResponse
    })
}

export type PermissionOptions = {
  label: string
  value: number
}

export type IGetPermissionsOptions = {
  menu: PermissionOptions[]
  department: PermissionOptions[]
  position: PermissionOptions[]
}
export const getAllDepartments = async (): Promise<Department[]> => {
  return await AxiosFunction({
    method: 'get',
    url: '/cms/apa/department',
    queryParams: {},
  })
    .then((res) => {
      const { data } = res
      return data as Department[]
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching permissions information', 'error')
      return []
    })
}

export const editStatePermission = async (
  permissionId: number,
  updatedRecord: Record<PermissionBadgeProps['keyName'], TZeroOne>,
): Promise<{ updated: boolean }> => {
  const res = await getOnePermission(permissionId)
  const updatedPermission = { ...res, ...updatedRecord } as ExtendedPermission
  return await editPermission(permissionId, updatedPermission)
}

export const editPermission = async (
  permissionId: number,
  body: ExtendedPermission,
): Promise<{ updated: boolean }> => {
  return AxiosFunction({
    method: 'put',
    url: `/cms/apa/permission/${permissionId}`,
    body,
    queryParams: {},
  })
    .then(() => {
      Notification('Permission has been edited successfully', 'success')
      return { updated: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred when editing permission', 'error')
      return { updated: false }
    })
}

export const deletePermission = async (permissionId: number): Promise<{ deleted: boolean }> => {
  return AxiosFunction({
    method: 'delete',
    url: `/cms/apa/permission/${permissionId}`,
  })
    .then(() => {
      return { deleted: true }
    })
    .catch(() => {
      return { deleted: false }
    })
}

export const getOnePermission = async (permissionId: number): Promise<Permission> => {
  return AxiosFunction({
    method: 'get',
    url: `/cms/apa/permission/${permissionId}`,
    queryParams: {},
  })
    .then((res) => {
      return res.data as Permission
    })
    .catch(() => {
      return ({} as unknown) as Permission
    })
}

export const getKeyNameValuePermission = async (
  keyName: PermissionBadgeProps['keyName'],
  permissionId: number,
): Promise<TZeroOne> => {
  return new Promise((resolve, reject) => {
    getOnePermission(permissionId)
      .then((permission) => {
        resolve(permission[keyName])
      })
      .catch(() => {
        reject(0)
      })
  })
}

export const createPermission = async (body: PermissionCreate[]): Promise<{ created: boolean }> => {
  const keyToDelete = ['key', 'departmentName', 'menuName', 'positionName']
  const updatedPermissionsBody = [...body].map((permission) => {
    const updatedBody = {} as Record<string, any>
    for (const key in permission)
      if (!keyToDelete.includes(key)) updatedBody[key] = permission[key as keyof typeof permission]
    return updatedBody
  }) as Permission[]
  return AxiosFunction({
    method: 'post',
    url: `/cms/apa/permission`,
    body: updatedPermissionsBody,
    queryParams: {},
  })
    .then((res: any) => {
      Notification('Permissions were successfully created', 'success')
      return { created: true }
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating permissions', 'error')
      return { created: false }
    })
}

export const getAvailableMenus = async (params: {
  departmentId: number
  positionId: number
}): Promise<Menu[]> => {
  return AxiosFunction({
    method: 'get',
    url: `/cms/apa/menu/available`,
    queryParams: params,
  })
    .then((res) => {
      return res.data as Menu[]
    })
    .catch((message) => {
      Notification(message || 'An error occurred while creating permissions', 'error')
      return []
    })
}

export const getPermissionsInformation = async (): Promise<IGetPermissionsOptions> => {
  return await AxiosFunction({
    method: 'get',
    url: '/cms/apa/permission/information',
    queryParams: {},
  })
    .then((res) => {
      const { data } = res
      return data as IGetPermissionsOptions
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching permissions information', 'error')
      return ([] as unknown) as IGetPermissionsOptions
    })
}

export const updateMainGroupOrders = async (
  menuItems: Pick<Menu, 'menu_order' | 'menu_id' | 'menu_group'>[],
): Promise<boolean> => {
  return await AxiosFunction({
    method: 'post',
    url: '/cms/apa/menu/order',
    queryParams: {},
    body: menuItems,
  })
    .then(() => {
      return true
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching permissions information', 'error')
      return false
    })
}

export const getAllMainMenus = async ({
  sortBy,
  orderBy,
}: {
  sortBy?: 'menu_order'
  orderBy?: 'ASC' | 'DESC'
}): Promise<Menu[]> => {
  return await AxiosFunction({
    method: 'get',
    url: '/cms/apa/menu/main',
    queryParams: { system_id: 2, sortBy, orderBy },
  })
    .then((res) => {
      const { data } = res
      return data
    })
    .catch((message) => {
      Notification(message || 'An error occurred when fetching permissions information', 'error')
      return []
    })
}
