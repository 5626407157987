import { CheckCircleOutlined, ExclamationOutlined, WarningOutlined } from '@ant-design/icons'
import { notification } from 'antd'

const Notification = (message: string, notiType: string) => {
  let icon = null
  switch (notiType) {
    case 'success':
      icon = <CheckCircleOutlined style={{ color: '#58e464' }} />
      break
    case 'error':
      icon = <ExclamationOutlined style={{ color: '#f05a5a' }} />
      break
    case 'warning':
      icon = <WarningOutlined style={{ color: '#faad15' }} />
      break
  }
  message === 'updateIndex'
    ? ''
    : notification.open({
        message,
        icon,
        duration: 4,
      })
}

export default Notification

export class ShowNotification {
  static error(message: string): void {
    Notification(message, 'error')
  }

  static success(message: string): void {
    Notification(message, 'success')
  }

  static warning(message: string): void {
    Notification(message, 'warning')
  }
}
