/* eslint-disable @typescript-eslint/ban-types */
import { TableProps } from 'antd/es/table'
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { PropsWithChildren, useState } from 'react'
import { FilterValue } from 'react-table'
import { IMetaPage } from '../../interfaces'

interface PaginationParams {
  page: number
  results: number
  sortBy?: string
  orderBy?: 'ASC' | 'DESC'
}
export type Prettify<T> = {
  [K in keyof T]: T[K]
}

type RecordAny = Record<string, unknown>

export type TableParams<ParamState = Record<string, any>> = PaginationParams &
  {
    [key in keyof ParamState]: ParamState[key]
  }

export type TableParameters = Pick<PaginationParams, 'page' | 'results'>
export type SortParameters = Pick<PaginationParams, 'sortBy' | 'orderBy'>

type OverwrittenTableProps<T extends any> = Omit<
  TableProps<T>,
  'columns' | 'dataSource' | 'pagination'
>

// type ColumnsTypes<T extends RecordAny> = ColumnsType<T> | ColumnsType<RecordAny>
// type DataTypes<T extends RecordAny> = T[] | RecordAny[]

interface CMSTableProps<Data, ParamState = {}> extends OverwrittenTableProps<Data> {
  columns: ColumnsType<Data>
  dataSource: Data[]
  pagination: Prettify<IMetaPage>
  params?: TableParams<ParamState>
  onAction: (data: TableParams<ParamState>) => void
  pageSize?: number
  showExtended?: (values: Data) => any
}

export default function CMSTable<Data extends any, ParamState extends any>(
  props: PropsWithChildren<CMSTableProps<Data, ParamState>>,
): Prettify<ReturnType<typeof Table>> {
  const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100']
  const {
    params = {},
    onAction,
    pageSize,
    pagination,
    columns,
    showExtended,
    dataSource,
    ...rest
  } = props || {}

  const [resultsPerPage, setResultsPerPage] = useState(pageSize || 20)

  const sortItems = (
    pageObject: TablePaginationConfig,
    filtersObject: Record<string, FilterValue | null>,
    sorterObject: any,
  ) => {
    const sorting: PaginationParams['orderBy'] = !sorterObject.order
      ? 'DESC'
      : sorterObject.order === 'ascend'
      ? 'ASC'
      : 'DESC'
    const fieldValue: PaginationParams['sortBy'] = sorterObject.order
      ? sorterObject.field
      : props.rowKey
    const tableParams: PaginationParams = {
      page: pageObject.current as number,
      results: pageObject.pageSize as number,
      orderBy: sorting,
      sortBy: fieldValue,
    }
    if (tableParams.results !== resultsPerPage) {
      setResultsPerPage(tableParams.results || 20)
      const updatedParams = Object.assign({ ...params }, tableParams)
      return onAction(updatedParams)
    } else {
      const updatedParams = Object.assign({ ...params }, tableParams)
      return onAction(updatedParams)
    }
  }

  const expandSection = showExtended
    ? { expandable: { ...rest.expandable, expandedRowRender: showExtended } }
    : undefined

  const tableProps: any = {
    ...rest,
    columns,
    dataSource,

    pagination: {
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      current: pagination.page,
      pageSize: resultsPerPage,
      total: pagination.total,
    },
    onChange: sortItems,
    ...expandSection,
  }

  return <Table {...tableProps} />
}
