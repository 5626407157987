import axios, { AxiosResponse } from 'axios'
import config from './config'
import { getUserToken } from './contexts/Auth'
const { restfulService, uploadService } = config

interface BaseAxiosRequest {
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | 'stream'
  content?: 'multipart/form-data' | 'application/json'
}

interface IAxiosRequest {
  body: any
  queryParams: any
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  url: string
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | 'stream'
  content?: 'multipart/form-data' | 'application/json'
}

interface GetAxiosRequest extends BaseAxiosRequest {
  queryParams: any
  url: string
  method: 'get'
}

interface PostAxiosRequest extends BaseAxiosRequest {
  body: any
  queryParams: any
  url: string
  method: 'post'
}

interface PutAxiosRequest extends BaseAxiosRequest {
  body: any
  queryParams: any
  url: string
  method: 'put'
}

interface DeleteAxiosRequest extends BaseAxiosRequest {
  url: string
  method: 'delete'
}

interface PatchAxiosRequest extends BaseAxiosRequest {
  body: any
  url: string
  method: 'patch'
}

type AxiosRequest =
  | GetAxiosRequest
  | PostAxiosRequest
  | PutAxiosRequest
  | DeleteAxiosRequest
  | PatchAxiosRequest
const accessToken = getUserToken()

const axiosfn = axios.create({
  baseURL: `${restfulService.URL}`,
  headers: {
    Authorization: accessToken || undefined,
  },
})
export const axiosUpload = axios.create({
  baseURL: `${uploadService.URL}`,
  headers: {
    Authorization: accessToken || undefined,
  },
})

export async function AxiosFunction<T = any>(
  data: AxiosRequest & { baseUrl?: string },
): Promise<T> {
  const accessToken = getUserToken()
  const axiosfn = axios.create({
    baseURL: data.baseUrl ? data.baseUrl : `${restfulService.URL}`,
    headers: {
      Authorization: accessToken || undefined,
      'Content-Type': data?.content || 'application/json',
    },
    responseType: data?.responseType || 'json',
  })

  const { body, queryParams, method, url } = data as IAxiosRequest
  const params = queryParams ? queryParams : {}

  const axiosConfig: any = []

  if (method === 'get') {
    axiosConfig.push({ params })
  } else if (method === 'post') {
    axiosConfig.push(body, { params })
  } else if (method === 'put') {
    axiosConfig.push(body, { params })
  } else if (method === 'patch') {
    axiosConfig.push(body)
  }

  return new Promise((resolve, reject) => {
    axiosfn[method]<T>(url, ...axiosConfig)
      .then((response) => {
        const { data } = response as AxiosResponse<T>
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch((error: any) => {
        const isString = typeof error?.response?.data?.message === 'string'
        const message = isString ? error.response.data.message : `Can't connect server`
        reject(message)
      })
  })
}
