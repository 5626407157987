import * as dotenv from 'dotenv'
dotenv.config()

const service = {
  NAME: process.env.REACT_APP_SERVICE_NAME || 'cms',
}

const adminService = {
  URL: process.env.REACT_APP_ADMIN_URL || '',
}

const authService = {
  URL: process.env.REACT_APP_TS_AUTHENTICATION_URL,
  AUTH_PROJECT: process.env.REACT_APP_AUTH_PROJECT,
}

const restfulService = {
  URL: process.env.REACT_APP_TS_RESTFUL_API_URL,
}

const uploadService = {
  URL: process.env.REACT_APP_TS_UPLOAD,
}

const evchargerService = {
  URL: process.env.REACT_APP_TS_EV_CHARGER,
}

const bookingService = {
  URL: process.env.REACT_APP_TS_BOOKING || '',
}

const googleMaps = {
  API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
}

const firebaseConfig = {
  API_KEY: process.env.REACT_APP_FIREBASE_API || '',
  AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  APP_ID: process.env.REACT_APP_FIREBASE_APP_ID || '',
  MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
}

const secret = {
  key: process.env.REACT_APP_SECRET || '',
}

const config = {
  service,
  authService,
  restfulService,
  uploadService,
  evchargerService,
  googleMaps,
  secret,
  adminService,
  bookingService,
  firebaseConfig,
}

export default config
