import { Form, FormItemProps } from 'antd'
export interface FormItemBrokerType<T = any> {
  id?: string
  value?: T
  onChange?: (val?: T) => void
  children?: (val: Omit<FormItemBrokerType<T>, 'children'>) => JSX.Element
}
export const WithFormValue = (props: FormItemBrokerType<any>) => {
  const { id, value, onChange, children } = props
  if (!children) {
    return <></>
  }
  return children({ id, value, onChange })
}

interface FormItemWrapper<T = any> extends FormItemProps {
  children: FormItemBrokerType<T>['children']
}

export function FormItemBroker<T extends any>(props: FormItemWrapper<T>) {
  const { children, ...otherProps } = props
  return (
    <Form.Item {...otherProps}>
      <WithFormValue children={children} />
    </Form.Item>
  )
}

export const FormValue = (props: Required<Pick<FormItemProps, 'name'>>) => {
  const value = Form.useWatch(props.name)
  return (
    <Form.Item name={props.name} noStyle>
      {value}
    </Form.Item>
  )
}
