import { PlusCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import { Link } from 'react-router-dom'
import { Breadcrumb } from '../containers/VerifiedVehicle/breadcrumb'
import CMSButton from './Styles/button.style'

type BaseHeaderSectionProps = {
  tmpBreadcrumb: { title: string; link?: string }[]
  title: string
}

type HeaderSectionProps = (
  | {
      haveCreateButton?: false
      buttonTitle?: undefined
      doAction?: (arg?: any) => void
      linkCreate?: undefined
    }
  | {
      haveCreateButton: true
      linkCreate: string
      buttonTitle: string
      doAction?: undefined
    }
  | {
      haveCreateButton: true
      linkCreate?: undefined
      buttonTitle: string
      doAction: (arg?: any) => void
    }
) &
  BaseHeaderSectionProps

interface IHeaderSectionProps {
  tmpBreadcrumb: { title: string; link?: string }[]
  title: string
  buttonTitle?: string
  linkCreate?: string
  haveCreateButton: boolean
  doAction?: (arg?: any) => void
}

export const createBreadCrumb = (tmpBreadcrumb: { title: string; href?: string }[]): string => {
  let str = ''
  for (let i = 0; i < tmpBreadcrumb.length; i++) {
    str += tmpBreadcrumb[i].title
    if (i < tmpBreadcrumb.length - 1) {
      str += ' / '
    }
  }
  return str
}

const HeaderSection = ({
  tmpBreadcrumb,
  title,
  buttonTitle,
  linkCreate,
  haveCreateButton,
  doAction,
}: IHeaderSectionProps): JSX.Element => {
  return (
    <>
      <Breadcrumb breadcrumb={tmpBreadcrumb} />
      <Row justify="space-between" align="middle" gutter={10}>
        <Col xs={24} md={16} lg={16}>
          <Title level={2} style={{ marginBottom: '0px' }}>
            {title}
          </Title>
        </Col>
        {haveCreateButton && (
          <Col xs={24} md={8} lg={4}>
            {doAction ? (
              <CMSButton
                buttonColor="lg-blue"
                onClick={(arg) => {
                  if (arg) {
                    doAction(arg)
                  } else {
                    doAction()
                  }
                }}
                style={{ position: 'relative', bottom: '5px' }}
              >
                <PlusCircleOutlined />
                {buttonTitle}
              </CMSButton>
            ) : (
              <Link to={`${String(linkCreate)}`}>
                <CMSButton
                  buttonColor="lg-blue"
                  style={{ position: 'relative', bottom: '5px' }}
                  icon={<PlusCircleOutlined />}
                >
                  {buttonTitle}
                </CMSButton>
              </Link>
            )}
          </Col>
        )}
      </Row>
    </>
  )
}

export default HeaderSection
