import axios from 'axios'
import config from '../../config'

const { authService, uploadService } = config

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export async function getUserPhoto(authContext: IAuthContext, params: any): Promise<any> {
  const userToken = authContext?.userLoggedIn.userToken
  return new Promise((resolve, reject) => {
    axios
      .get(`${authService.URL}/user/photo`, {
        headers: {
          authorization: userToken,
        },
        params,
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export async function createUserPhoto(authContext: IAuthContext, data: any): Promise<any> {
  const userToken = authContext?.userLoggedIn.userToken
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/user/photo`, data, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
  })
}

export async function uploadPhoto(authContext: IAuthContext, data: any): Promise<any> {
  const userToken = authContext?.userLoggedIn.userToken
  return new Promise((resolve, reject) => {
    axios
      .post(`${uploadService.URL}/upload`, data, {
        headers: {
          authorization: userToken,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
  })
}

export async function deleteUserPhoto(authContext: IAuthContext, id: number): Promise<any> {
  const userToken = authContext?.userLoggedIn.userToken
  return new Promise((resolve, reject) => {
    axios
      .delete(`${authService.URL}/user/photo/${id}`, {
        headers: {
          authorization: userToken,
        },
      })
      .then(function (response) {
        const { data } = response
        if (data) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
