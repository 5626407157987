import axios, { AxiosResponse } from 'axios'
import config from '../../config'
const { restfulService, uploadService } = config

export interface UserEntry {
  userid?: number //
  username?: string //
  activated?: string //
  name?: string // //
  lastname?: string // //
  email?: string // //
  customer_id?: string
  memberid?: string // //
  birthdate?: string // //

  dl_validated?: number //
  dl_number?: string // //
  dl_issuedate?: string // //
  dl_expiredate?: string // //
  dl_lifetime?: number // //
  dl_front?: string // //
  dl_rear?: string // //

  motodl_validated?: number //
  motodl_number?: string // //
  motodl_issuedate?: string // //
  motodl_expiredate?: string // //
  motodl_lifetime?: number // //
  motodl_front?: string // //
  motodl_rear?: string // //

  cl_lifetime?: number // //
  cl_number?: string // //
  cl_expiredate?: string // //
  cl_front?: string // //
  cl_rear?: string // //

  st_front?: string
  st_expiredate?: string
  st_lifetime?: number

  mobilecountrycode?: string // //
  mobile?: string // //

  note?: string // //
  notes?: INoteDetail[] // //

  howtoknown?: string // //
  address?: string // //
  building?: string //
  street?: string // // //
  subdistrict?: string // //
  district?: string // //
  city?: string // //
  zipcode?: string // //
  soi?: string // //

  register_address?: string // //
  register_building?: string // //
  register_street?: string // //
  register_subdistrict?: string // //
  register_district?: string // //
  register_city?: string // //
  register_zipcode?: string // //
  register_soi?: string // //

  signature?: string //

  organization?: string //
  usertype?: string //
  registedtime?: string //
  updatedtime?: string
  uploadedtime?: string
  groupid?: number // //
  adminid?: number //
  last_login?: string //
  foreigner?: number

  sex?: string // //

  promotion?: string //
  refercode?: string //
  use_id_address?: number
  has_shared_coupon?: number

  line_id?: string // //
  activate_userid?: number //
  activate_time?: string //
  reactivate_time?: string //
  reactivate_userid?: number //
  rejection_remark?: string //
  rejection_internalremark?: string //
  rejection_time?: string //
  rejection_userid?: number //
  freshchat_restore_id?: string //

  image_url?: string //
  defaultpaymentno?: number
  refer_userid?: number // //
  is_carowner?: number // //
  auto_name?: string
  auto_surname?: string
  status_tags?: string
  role_tags?: string //
  remark?: string
  internalremark?: string

  billing_name?: string // //
  billing_vatnumber?: string // //
  billing_address?: string // //
  billing_building?: string // //
  billing_soi?: string // //
  billing_street?: string // //
  billing_subdistrict?: string // //
  billing_district?: string // //
  billing_city?: string // //
  billing_zipcode?: string // //
  taxrequest?: number // //

  deactivate_userid?: number //
  deactivate_time?: string //

  deletion_status?: string //
  deletion_checklist?: string //
  deletion_remark?: string //
  deletion_admin_remark?: string //
  deletion_category?: string //
  deletion_requestdate?: string //
  deletion_estimatedate?: string //

  prefix?: string // //
  nationalityid?: number // //

  // workpermit_validated?: number
  // workpermit_number?: string
  // workpermit_issuedate?: string
  // workpermit_expiredate?: string
  // workpermit_lifetime?: string
  // workpermit_front?: string

  // income_validated?: string
  // income_number?: string
  // income_issuedate?: string
  // income_expiredate?: string
  // income_lifetime?: number
  // income_front?: string
}

export type TypeSteps = 'wait' | 'process' | 'finish' | 'error'

interface IAuthContext {
  user: any
  userLoggedIn: any
}

export type dataObject<T = any> = {
  [key: string]: T
}
export interface IProvince {
  _id: string
  name_th: string
  name_en: string
}

export interface IResDataProvince {
  data: IDataProvince[]
  status: number
}

export interface ISearchUserParams {
  page?: number
  results?: number
  // status: string
  search?: string | undefined
  order?: string
}

export interface IDataProvince {
  _id: string
  id: number
  name_th: string
  name_en: string
  amphure: IDataDistrict[]
  geography_id: number
  created_at: string
  update_at: string
  deleted_at: string
}

export interface IDataDistrict {
  id: number
  province_id: string
  name_th: string
  name_en: string
  tambon: IDataSubdistrict[]
  created_at: string
  updated_at: string
  deleted_at: string
}

export interface IDataSubdistrict {
  id: number
  zip_code: number
  amphure_id: string
  name_th: string
  name_en: string
  created_at: string
  updated_at: string
  deleted_at: string
}

export interface IDataNationality {
  nationalityid: number
  nationalityname: string
}

export interface IResDataNationality {
  data: IDataNationality[]
  result: boolean
}

export interface IDataMobileContryCode {
  mobilecountrycode: string
  countryname: string
}

export interface IResDataMobileContryCode {
  data: IDataMobileContryCode[]
  result: boolean
}

export interface IGroupList {
  groupid: number
  name: string
}

export interface IResGroupList {
  data: IGroupList[]
  result: boolean
}

export interface ICheckEmail {
  email: string
}

export interface IHaveEmail {
  have_email: number
}

export interface IResCheckEmail {
  data: IHaveEmail[]
  result: boolean
}

export interface ICheckUsername {
  username: string
}

export interface IGetUserImage {
  userid?: number
  pathfile?: string
}

export interface IResultImageUrl {
  imageUrl: string
}

export interface IUploadUserImage {
  userid: number
  // fileImage:
}

export interface IBodyUploadImage {
  fileName: FormData
  issueDate: string
  expirationDate: string
  flag?: number
}

export interface INoteDetail {
  notetime: string
  detail: string
}

// export interface IGetUserImage {
//   user?: number
//   page?: number
//   limit?: number
// }

// export interface IResultDataDocumentUserImage {
//   docs: IDataDocumentUserImage[]
//   hasNextPage: boolean
//   hasPrevPage: boolean
//   limit: number
//   nextPage: number
//   page: number
//   prevPage: number
//   pagingCounter: number
//   totalDocs: number
//   totalPages: number
// }
// export interface IDataDocumentUserImage {
//   _id: string
//   car_license: IDocumentUser[]
//   car_license_selfie: IDocumentUser[]
//   documentLogs: []
//   income: []
//   moto_license: IDocumentUser[]
//   moto_license_selfie: IDocumentUser[]
//   national_id: IDocumentUser[]
//   national_id_selfie: IDocumentUser[]
//   organization: IDocumentUser[]
//   work_permit: IDocumentUser[]
//   signature: IDocumentUser[]
//   user: { userId: number }
//   userId: number
//   createdAt: string
//   updatedAt: string
//   lastUserActivity: string
// }

// export interface IDocumentUser {
//   approveStatus: string
//   docType: string
//   expirationDate: string
//   file: { fileType: string; path: string }
//   validStatus: string
//   validateStatus: {
//     status: string
//     remark: string
//     updatedAt: string
//     validateBy: { agent: string }
//   }
//   issueDate: string
//   isNonExpired: boolean
//   requireSelfie: boolean
//   notes: []
//   createdAt: string
//   updatedAt: string
// user: {
//   userId: number
//   userName: string
//   lastName: string
//   activated: string
//   address: string
//   birthDate: string
//   building: string
//   city: string
//   district: string
//   line_id: string
// }
// }

export const getAllUser = (auth: IAuthContext, params: ISearchUserParams) => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/usermanage`,
    method: 'GET',
    params: params,
    headers: {
      authorization: userToken,
    },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getUserByUserId = (auth: IAuthContext, userid: string) => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/usermanage/${userid}`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getAllNationality = (auth: IAuthContext): Promise<{ data: IResDataNationality }> => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/nationality`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getAllMobileContryCode = (
  auth: IAuthContext,
): Promise<{ data: IResDataMobileContryCode }> => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/mobilecountrycode`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getAllGroup = (auth: IAuthContext): Promise<{ data: IResGroupList }> => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/grouplist`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getCheckEmail = (auth: IAuthContext, email: string): Promise<any> => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/checkemail`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
    params: { email: email },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export const getCheckUsername = (auth: IAuthContext, username: string): Promise<any> => {
  const userToken = auth.userLoggedIn?.userToken

  return axios({
    url: `${restfulService.URL}/cms/checkusername`,
    method: 'GET',
    headers: {
      authorization: userToken,
    },
    params: { username: username },
  })
    .then((response: AxiosResponse) => {
      const { data, status } = response
      return { data, status }
    })
    .catch((error) => {
      return error.response
    })
}

export async function createUser(auth: IAuthContext, data: Record<string, any>): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = auth?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/cms/usermanage/createuser`,
        { ...data },
        {
          headers: {
            authorization: `${userToken}`,
          },
        },
      )
      .then(function (response) {
        console.log('success')
        resolve(response?.data)
      })
      .catch(function (error) {
        // console.log('error = ', error.response.data.message)
        reject(
          error?.response?.data?.message ===
            'Error: SequelizeUniqueConstraintError: Validation error'
            ? 'Username or email not available'
            : error?.response?.data?.message,
        )
      })
  })
}

export async function updateUser(
  auth: IAuthContext,
  data: Record<string, any>,
  userid: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = auth?.userLoggedIn?.userToken
    axios
      .put(
        `${restfulService.URL}/cms/usermanage/edit/${userid}`,
        { ...data },
        {
          headers: {
            authorization: `${userToken}`,
          },
        },
      )
      .then(function (response) {
        console.log('success')
        resolve(response?.data)
      })
      .catch(function (error) {
        // console.log('error = ', error.response.data.message)
        reject(error)
      })
  })
}

export async function genUserImageUrl(auth: IAuthContext, body: IGetUserImage): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = auth?.userLoggedIn?.userToken
    axios
      .post(
        `${restfulService.URL}/cms/usermanage/genimageurl`,
        { ...body },
        {
          headers: {
            authorization: userToken,
          },
        },
      )
      .then(function (response) {
        console.log('success')
        resolve(response.data)
      })
      .catch(function (error) {
        // console.log('error = ', error.response.data.message)
        reject(error)
      })
  })
}

export async function postUploadUserImage(
  auth: IAuthContext,
  params: IGetUserImage,
  body: FormData,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const userToken = auth?.userLoggedIn?.userToken
    axios
      .post(
        `${uploadService.URL}/admin/document/user/${params.userid}/file/${params.pathfile}`,
        body,
        {
          headers: {
            authorization: userToken,
            project: 'user-regist',
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        // console.log('error = ', error.response.data.message)
        reject(error)
      })
  })
}
