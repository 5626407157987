import { useEffect, useState } from 'react'

type Width = number
/** Hook check if this is a pixels
 * @param pixels - number of pixels (default 768)
 */
export const useMobile = (pixels: Width = 768): { isMobile: boolean } => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkWindowWidth = () => {
      const isBelowLimit = window.innerWidth <= pixels
      setIsMobile(isBelowLimit)
    }
    window.addEventListener('resize', checkWindowWidth)
    return () => {
      window.removeEventListener('resize', checkWindowWidth)
    }
  }, [])

  return { isMobile }
}
