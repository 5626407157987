import {
  CheckSquareOutlined,
  ClusterOutlined,
  // FileProtectOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { ReactNode } from 'react'

export interface MenuItems {
  key: number | string
  title: string
  icon?: ReactNode
  link?: string
  // subMenu?: Array<Omit<MenuItems, 'subMenu'>>
  subMenu?: MenuItems[]
}

//mockMunuPermission
export const menus: MenuItems[] = [
  //*Reservation list
  {
    key: 64,
    title: 'Reservation',
    subMenu: [
      {
        key: 2,
        title: 'Reservation',
        icon: CheckSquareOutlined,
        link: '/reservation/all',
      },
      {
        key: 75,
        title: 'Dispatch',
        link: '/dispatch',
      },
      {
        key: 71,
        title: 'Check In',
        link: '/check-in-airport?step=0',
      },
      {
        key: 39,
        // key: 'invoice',
        title: 'Invoice list',
        link: '/invoice',
      },
      {
        key: 40,
        // key: 'charge',
        title: 'Charge list',
        link: '/charge',
      },
      {
        key: 76,
        // key: 'charge',
        title: 'Payment Charge',
        link: '/charge/create',
      },
      {
        key: 85,
        title: 'New Reservation',
        link: '/create-reservation-charge',
      },
      {
        key: 86,
        title: 'Chat Monitor',
        link: '/chat-monitor',
      },
    ],
  },
  //*Airport transfer
  {
    key: 47,
    title: 'Airport Booking',
    icon: FormOutlined,
    subMenu: [
      {
        key: 48,
        title: 'Booking',
        icon: FormOutlined,
        link: '/booking',
      },
      {
        key: 49,
        title: 'Address',
        icon: FormOutlined,
        link: '/address',
      },
      {
        key: 77,
        title: 'Driver Manage',
        icon: FormOutlined,
        link: '/driver/dashboard',
      },
      {
        key: 78,
        title: 'Driver Manage',
        icon: FormOutlined,
        link: '/driver',
      },
      {
        key: 79,
        title: 'Shift Manage',
        icon: FormOutlined,
        link: '/driver/shift',
      },
      {
        key: 82,
        title: 'Driver Booking',
        icon: FormOutlined,
        link: '/driver/bookings',
      },
      {
        key: 105,
        title: 'Fast Track',
        icon: FormOutlined,
        link: '/airport/fast-track',
      },
      {
        key: 107,
        title: 'Vehicle Model',
        icon: FormOutlined,
        link: '/airport/vehicle',
      },
      // {
      //   key: -1,
      //   title: 'Price',
      //   link: '/airport-transfer/price'
      // }
    ],
  },
  {
    title: 'Airport package',
    key: 95,
    subMenu: [
      { title: 'Package', link: '/airport/package', key: 103 },
      {
        key: 96,
        title: 'Package request',
        link: '/airport/package/request',
      },
      {
        key: 97,
        title: 'Create Package Transaction',
        link: '/airport/package/purchase',
      },
      {
        key: 98,
        title: 'Accounting',
        link: '/airport/package/accounting',
      },
    ],
  },
  {
    key: 65,
    title: 'Inventory',
    subMenu: [
      {
        key: 1,
        // key: 'submission',
        title: 'Submission List',
        icon: FormOutlined,
        link: '/submission',
      },
      {
        key: 4,
        // key: 'vehicle-list',
        title: 'Vehicle',
        icon: CheckSquareOutlined,
        link: '/vehicle',
      },
      {
        key: 3,
        // key: 'vehicle-ut-report',
        title: 'Vehicle UT Report',
        icon: CheckSquareOutlined,
        link: '/report',
      },
      {
        key: 43,
        title: 'Station',
        link: '/station',
      },
    ],
  },
  {
    key: 66,
    title: 'Price',
    subMenu: [
      {
        key: 63,
        title: 'Check Price',
        link: '/checkPrice',
      },
      {
        key: 18,
        // key: 'pricing-new',
        title: 'Standard Price',
        icon: FormOutlined,
        link: '/pricemodel',
      },

      {
        key: 61,
        title: 'Project',
        link: '/stdPriceProjectMarkup',
      },
      {
        key: 16,
        // key: 'campaign-manage',
        title: 'Campaign',
        icon: FormOutlined,
        link: '/campaignmanage',
      },
      {
        key: 15,
        // key: 'package-manage',
        title: 'Package',
        icon: FormOutlined,
        link: '/packagemanage',
      },
      {
        key: 62,
        title: 'Channel',
        link: '/stdPriceChannelMarkup',
      },

      {
        key: 17,
        //  key: 'pricing-old',
        title: '[Close] Old price',
        icon: FormOutlined,
        link: '/price-manage',
      },
    ],
  },
  {
    key: 67,
    title: 'User',
    subMenu: [
      {
        key: 5,
        // key: 'userlist',
        title: 'User',
        icon: FormOutlined,
        link: '/usermanage',
      },
      {
        key: 50,
        title: 'User Photo (User document)',
        link: 'user-photo',
      },
      {
        key: 46,
        title: 'HAUP Credit',
        link: '/credit',
      },
      // {
      //   key: -1,
      //   title: 'Admin log',
      //   link: '/',
      // },
      // {
      //   key: -1,
      //   title: 'Note list',
      //   link: '/',
      // },
      // {
      //   key: -1,
      //   title: 'Message list',
      //   link: '/',
      // },
    ],
  },
  //*Blacklist
  {
    key: 42,
    title: 'Blacklist',
    icon: FormOutlined,
    subMenu: [
      {
        key: 44,
        title: 'Blacklist Name',
        link: '/blacklist',
      },
      {
        key: 45,
        title: 'Blacklist Photo',
        link: '/blacklist/photo',
      },
    ],
  },
  //**Banner
  {
    key: 68,
    title: 'Marketing',
    subMenu: [
      {
        key: 6,
        // key: 'banner',
        title: 'Banner',
        icon: CheckSquareOutlined,
        link: '/banner',
      },
      {
        key: 9,
        // key: 'notification',
        title: 'Notification',
        icon: FormOutlined,
        link: '/notification',
      },
      {
        key: 73,
        // key: 'notification',
        title: 'Select Notification',
        icon: FormOutlined,
        link: '/notification/all',
      },
      {
        key: 73,
        // key: 'notification',
        title: 'Edit Notification',
        icon: FormOutlined,
        link: '/notification/:id',
      },
      {
        key: 7,
        // key: 'promotion-code',
        title: 'Promotion',
        icon: FormOutlined,
        link: '/promocode',
      },
      {
        key: 8,
        // key: 'coupon-list',
        title: 'Coupon',
        icon: FormOutlined,
        link: '/coupon',
      },
    ],
  },
  {
    key: 24,
    // key: 'ev-charger',
    title: 'EV Charger',
    icon: CheckSquareOutlined,
    subMenu: [
      {
        key: 25,
        // key: 'ev-charger-charger',
        title: 'Charger',
        link: '/charger',
      },
      {
        key: 26,
        // key: 'ev-charger-pricing',
        title: 'Pricing',
        link: '/charger/pricing',
      },
      {
        key: 27,
        // key: 'ev-charger-station',
        title: 'Station',
        link: '/charger/station',
      },
      {
        key: 28,
        // key: 'ev-charger-vehicle',
        title: '[Close] Vehicle',
        link: '/charger/Vehicle',
      },
      {
        key: 29,
        // key: 'ev-charger-reservation',
        title: 'Report',
        link: '/charger/reservation',
      },
      {
        key: 29.1,
        title: 'Meter Log',
        link: '/charger/meter-log',
      },
      {
        key: 60,
        // key: 'ev-charger-reservation',
        title: 'Submission',
        link: '/charger/submission/station',
      },
    ],
  },
  {
    key: 19,
    // key: 'event',
    title: 'Event',
    icon: FormOutlined,
    subMenu: [
      {
        key: 41,
        // key: 'create-ttt-transaction',
        title: '[Close] Create TTT order',
        link: '/ttt/new',
      },
      {
        key: 20,
        // key: 'ttt-transaction-list',
        title: 'TTT Transaction',
        icon: FormOutlined,
        link: '/transaction/acc',
      },
      {
        key: 21,
        // key: 'ttt-redeem',
        title: 'TTT Redeem',
        icon: FormOutlined,
        link: '/events/ttt/redeem',
      },
      {
        key: 22,
        // key: 'ttt-check-coupon',
        title: 'TTT Check Coupon',
        icon: FormOutlined,
        link: '/transaction/cx',
      },
      {
        key: 23,
        // key: 'safe-t-ticet',
        title: '[Close] Safe-T Ticket',
        icon: FormOutlined,
        link: '/events/safe-t/tickets',
      },
    ],
  },
  {
    key: 30,
    // key: 'vehicle-master',
    title: 'Master',
    icon: ClusterOutlined,
    subMenu: [
      {
        key: 31,
        // key: 'master-brand',
        title: 'Brand',
        link: '/vehiclemaster/brand',
      },
      {
        key: 32,
        // key: 'master-model',
        title: 'Model',
        link: '/vehiclemaster/model',
      },
      {
        key: 33,
        // key: 'master-type',
        title: 'Type',
        link: '/vehiclemaster/type',
      },
      {
        key: 34,
        // key: 'master-size',
        title: 'Size',
        link: '/vehiclemaster/size',
      },
      {
        key: 35,
        // key: 'master-size-intl',
        title: 'Size Intl',
        link: '/vehiclemaster/sizeintl',
      },
      {
        key: 36,
        // key: 'master-color',
        title: 'Color',
        link: '/vehiclemaster/color',
      },
    ],
  },
  {
    key: 38,
    // key: 'system-log',
    title: 'Log System',
    link: '/logs',
  },
  {
    key: 51,
    title: 'System',
    subMenu: [
      {
        key: 52,
        title: 'Department',
        link: '/department',
      },
      {
        key: 54,
        title: 'Menu',
        link: '/menu',
      },
      {
        key: 55,
        title: 'Permissions',
        link: '/permissions',
      },
      {
        key: 59,
        title: 'Admin',
        link: '/adminManage',
      },
      {
        key: 56,
        title: 'Groups',
        link: '/groups',
      },
    ],
  },
  {
    key: 11,
    // key: 'template',
    title: 'Template',
    icon: FormOutlined,
    subMenu: [
      {
        key: 12,
        // key: 'template-message',
        title: 'Message Template',
        icon: FormOutlined,
        link: '/templating/message',
      },
      {
        key: 13,
        // key: 'template-email',
        title: 'New Email Template',
        icon: FormOutlined,
        link: '/email/template',
      },
    ],
  },

  //*Picture upload
  // {
  //   key: 'picture-upload',
  //   title: 'Picture Upload',
  //   icon: CheckSquareOutlined,
  //   link: '/picture-upload',
  // },

  //*Group code
  {
    key: 10,
    // key: 'group-code',
    title: 'Group Code',
    icon: FormOutlined,
    link: '/groupcode',
  },

  //*User referral
  {
    key: 14,
    // key: 'user-referral',
    title: 'User Referral',
    icon: FormOutlined,
    link: '/user-referral',
  },

  {
    key: 74,
    title: 'Dashboard-SVB',
    icon: FormOutlined,
    link: '/reservation-dashboard',
  },
  {
    key: 83,
    title: 'Task Management',
    icon: FormOutlined,
    subMenu: [
      {
        key: 84,
        title: 'Tasks List',
        icon: FormOutlined,
        link: '/tasks',
      },
    ],
  },
  {
    key: 87,
    title: 'Van with Driver',
    icon: FormOutlined,
    subMenu: [
      {
        key: 88,
        title: 'Driver manage',
        icon: FormOutlined,
        link: '/van-with-driver/driver-manage',
      },
      {
        key: 89,
        title: 'Addon manage',
        icon: FormOutlined,
        link: '/van-with-driver/addon-manage',
      },
      {
        key: 90,
        title: 'Vehicle manage',
        icon: FormOutlined,
        link: '/van-with-driver/vehicle-manage',
      },
      {
        key: 91,
        title: 'Destination manage',
        icon: FormOutlined,
        link: '/van-with-driver/destination-manage',
      },
      {
        key: 92,
        title: 'Booking manage',
        icon: FormOutlined,
        link: '/van-with-driver/booking-manage',
      },
    ],
  },
  {
    key: 93,
    title: 'Revenue',
    icon: FormOutlined,
    subMenu: [
      {
        key: 94,
        title: 'Revenue',
        icon: FormOutlined,
        link: '/auto-revenue',
      },
    ],
  },
]
