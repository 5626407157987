import axios from 'axios'
import config from '../../config'

const { authService } = config

interface ICredential {
  username: string
  password: string
}

export async function getUserToken(credential: ICredential): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/login`, {
        username: credential.username,
        password: credential.password,
        project: authService.AUTH_PROJECT,
        deviceType: 'WEBSITE',
      })
      .then(function (response) {
        const { data } = response
        const { accessToken } = data
        if (accessToken) {
          resolve(data)
        } else {
          reject(data)
        }
      })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}

export async function checkValidToken(accessToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${authService.URL}/token/verify`, {
        accessToken,
        project: authService.AUTH_PROJECT,
      })
      .then(function (response) {
        const { data } = response
        const { token } = data
        if (token) {
          resolve(token)
        } else {
          reject(token)
        }
      })
      .catch(function (error) {
        const httpStatus = error?.response?.status
        if (httpStatus === 401) {
          resolve(false)
        }
        reject(error)
      })
  })
}

export async function exchangeProjectToken(accessToken: string, projectName: string) {
  const exchangeTokenResponse = await axios.get<{ result: boolean; exchangeToken: string }>(
    `${authService.URL}/token/exchange/${projectName}`,
    {
      headers: {
        Authorization: accessToken,
        project: projectName,
      },
    },
  )

  const { exchangeToken } = exchangeTokenResponse.data
  const loginResult = await axios.post<{
    accessToken: string
    tokenType?: string
    expireAt: string | null
    userData: unknown
    adminGroup: number[] | null
  }>(`${authService.URL}/token/exchange`, {
    exchangeToken: exchangeToken,
  })
  return loginResult.data
}
