/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ShowNotification } from 'src/components/Notification'
import { IMetaPage } from 'src/interfaces'
import { UrlActionType, UrlDispatch, UrlState } from './types'

// do necessary API calls here as well
export const crudActions = {
  /** remove current state logic */
  resetState: () => async (dispatch: UrlDispatch) => {
    dispatch({
      type: UrlActionType.RESET,
    })
  },
  fetch: (params: UrlState['params']) => async (dispatch: UrlDispatch) => {
    const { list } = dispatch({
      type: UrlActionType.REQUEST_LOADING,
    })
    const response = await list(params)
    if (!response.isSuccess) {
      return dispatch({ type: UrlActionType.REQUEST_FAILED })
    }

    dispatch({
      type: UrlActionType.REQUEST_SUCCESS,
      payload: {
        meta: response?.meta ?? ({} as IMetaPage),
        data: response.data,
      },
    })
  },

  /** initialize the state with the provided params and list function */
  initialize: (params: UrlState['params'], list: UrlState['fn']) => async (
    dispatch: UrlDispatch,
  ) => {
    dispatch({
      type: UrlActionType.FETCH_INIT,
      payload: { params, list },
    })

    // get url params and call the API (id params exists => choose it otherwise params provided in initialize action context)
    if (list === undefined) {
      ShowNotification.error('No API function provided')
      return dispatch({ type: UrlActionType.REQUEST_FAILED })
    }
    crudActions['fetch'](params)(dispatch)
  },
  updateUrl: (params: UrlState['params']) => async (dispatch: UrlDispatch) => {
    dispatch({
      type: UrlActionType.CHANGE_URL,
      payload: { params },
    })
    crudActions['fetch'](params)(dispatch)
  },
}
