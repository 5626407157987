// import { LinkOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
// import { Link } from 'react-router-dom'
// import { menus } from '../../config/menu'
// import { theme } from '../../config/style'
import { useAuth } from '../../contexts/Auth'

export const HomePage = () => {
  const auth = useAuth()

  return (
    <div>
      <Typography.Title>Welcome {auth?.user?.name}</Typography.Title>
      <hr />
      {/* <List
        dataSource={menus}
        grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
        style={{ width: '100%', marginTop: '1rem' }}
        renderItem={({ title, icon, subMenu, link }) => {
          return (
            <List.Item>
              <Card
                title={title}
                headStyle={{ background: theme.haupBlue, color: 'white' }}
                bodyStyle={{ border: '3px solid ' + theme.haupBlue }}
              >
                <List
                  dataSource={subMenu}
                  size="small"
                  renderItem={(sub) => (
                    <List.Item>
                      <Link style={{ color: 'black' }} to={sub.link || '#'}>
                        <LinkOutlined /> {sub.title}
                      </Link>
                    </List.Item>
                  )}
                />
              </Card>
            </List.Item>
          )
        }}
      /> */}
    </div>
  )
}
