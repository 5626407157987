import { Form } from 'antd'
import { ReactElement, ReactNode, cloneElement, useState } from 'react'
import { generateId } from '../../../libs/utils'

export interface FormGroupProps {
  children?: ReactElement[] | ReactElement
  label?: ReactNode
}

export const FormGroup = ({ children, label }: FormGroupProps): JSX.Element => {
  const [elmGroupId] = useState(generateId(7))
  const labelColConfig = { span: 8, lg: 6, xl: 4 }
  const childrenLabelColConfig = { span: 8, lg: 6, xl: 4 }

  function injectLabelColProps(elm?: ReactElement, index = 0) {
    if (!elm) {
      return elm
    }
    return cloneElement(elm, {
      ...elm.props,
      key: `form-group-${elmGroupId}-${index}`,
      labelCol: childrenLabelColConfig,
      labelAlign: 'right',
    })
  }

  const modifiedChildren = Array.isArray(children)
    ? children.map(injectLabelColProps)
    : injectLabelColProps(children)

  return (
    <Form.Item
      label={<h3>{label}</h3>}
      labelCol={labelColConfig}
      style={{ border: '1px solid #00000050', padding: '1rem' }}
    >
      {modifiedChildren}
    </Form.Item>
  )
}
