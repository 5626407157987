import axios from 'axios'
import config from '../../../config'

const { restfulService, authService } = config

export const getPermissionByRestful = (userToken: string) => {
  try {
    // const result = axios.get(`${restfulService.URL}/cms/permission`, {
    //   headers: { Authorization: userToken },
    // })
    const result = axios.get(`${authService.URL}/permission`, {
      headers: { Authorization: userToken },
    })
    return result
  } catch (error: any) {
    throw new Error(error.message)
  }
}
