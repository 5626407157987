import { lazy } from 'react'
import { DepartmentList } from '../containers/DepartmentList'
import { HomePage } from '../containers/Home'
import MenuCreate from '../containers/MenuCreate'
import MenuEdit from '../containers/MenuEdit'
import { MenuList } from '../containers/MenuList'
import PermissionsCreate from '../containers/PermissionsCreate'
import PermissionList from '../containers/PermissionsList'
import { PositionList } from '../containers/PositionList'
// import ReservationManage from '../containers/ReservationManage'
// import DetailModel from '../containers/VehicleMaster/VehicleModel/'
import { EvMeterLogPage } from '../containers/EvMeterLog'

export type RouteItem = {
  title: string
  path: string
  requireAuth: boolean
  container: any
  // | ((...args: any) => JSX.Element)
  // | typeof React.Component
  // | React.LazyExoticComponent<(...args: any) => JSX.Element>
  // | React.LazyExoticComponent<typeof React.Component>
}

const routes: RouteItem[] = [
  { title: 'Home', path: '/', requireAuth: true, container: HomePage },
  {
    title: 'Vehicle features list',
    path: '/vehicle/key-features',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleFeatures/List')),
  },
  {
    title: 'Submission List',
    path: '/submission',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleSubmissionList')),
  },
  {
    title: 'Submission Detail',
    path: '/submission/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleSubmissionDetail')),
  },
  // {
  //   title: 'Feature Pin',
  //   path: '/feature-pin',
  //   requireAuth: true,
  //   container: FeaturePin,
  // },
  // { title: 'BestDeal', path: '/deal', requireAuth: true, container: BeastDeal },
  {
    title: 'PushNotification',
    path: '/notification',
    requireAuth: true,
    container: lazy(() => import('../containers/PushNotification')),
  },
  {
    title: 'PushNotification',
    path: '/notification/all',
    requireAuth: true,
    container: lazy(() => import('../containers/PushNotification/pushNotificationSchedule')),
  },

  {
    title: 'PushNotification',
    path: '/notification/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PushNotification')),
  },
  {
    title: 'EvCharger',
    path: '/charger',
    requireAuth: true,
    container: lazy(() => import('../containers/EvCharger')),
  },
  {
    title: 'EvStation',
    path: '/charger/station',
    requireAuth: true,
    container: lazy(() => import('../containers/EvStation')),
  },
  {
    title: 'EvSubMissionStation',
    path: '/charger/submission/station',
    requireAuth: true,
    container: lazy(() => import('../containers/EvSubmissionStation')),
  },
  {
    title: 'EvSubMissionStation Edit',
    path: '/charger/submission/station/edit/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/EvSubmissionStationEdit')),
  },
  {
    title: 'EvCharger(Pricing)',
    path: '/charger/pricing',
    requireAuth: true,
    container: lazy(() => import('../containers/Pricing')),
  },
  {
    title: 'EvCharger(Vehicle)',
    path: '/charger/Vehicle',
    requireAuth: true,
    container: lazy(() => import('../containers/EvCharger/Vehicle')),
  },
  {
    title: 'Ev Reservation',
    path: '/charger/reservation',
    requireAuth: true,
    container: lazy(() => import('../containers/EvReservation')),
  },
  {
    title: 'Ev Reservation Create',
    path: '/charger/reservation/create',
    requireAuth: true,
    container: lazy(() => import('../containers/EvReservationCreate')),
  },
  {
    title: 'Ev Meter Log',
    path: '/charger/meter-log',
    requireAuth: true,
    container: EvMeterLogPage,
  },
  {
    //*ต้องเช็คอีกทีว่าใส่ในไหน
    title: 'SearchHost',
    path: '/searchhost',
    requireAuth: true,
    container: lazy(() => import('../containers/HostInfo')),
  },
  {
    //*ต้องเช็คอีกทีว่าใส่ในไหน
    title: 'Vehicle Import',
    path: '/vehicle/import',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleImport')),
  },
  {
    title: 'Vehicle Add',
    path: '/vehicle/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleAdd')),
  },
  {
    title: 'Vehicle Edit',
    path: '/vehicle/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleEdit')),
  },
  {
    title: 'Vehicle List',
    path: '/vehicle',
    requireAuth: true,
    container: lazy(() => import('../containers/Verified/VehicleManage')),
  },
  // {
  //   title: 'Verified Vehicle',
  //   path: '/verifiedvehicle',
  //   requireAuth: true,
  //   container: VerifiedVehicle,
  // },
  {
    title: 'Verified vehicle info page',
    path: '/verifiedvehicle/insuranceinfo/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/VerifiedVehicle/InfoPage')),
  },
  {
    title: 'Insurance info page',
    path: '/verifiedvehicle/insuranceinfo/createorder/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/VerifiedVehicle/insuranceinfo')),
  },
  // {
  //   title: 'Insurance Management',
  //   path: '/insurance',
  //   requireAuth: true,
  //   container: InsuranceManagement,
  // },
  // {
  //   title: 'Insurance Management',
  //   path: '/insurance',
  //   requireAuth: true,
  //   container: InsuranceManagement,
  // },
  // {
  //   title: 'Contract Management',
  //   path: '/contract',
  //   requireAuth: true,
  //   container: ContractManagement,
  // },
  // {
  //   title: 'Contract Management',
  //   path: '/contract',
  //   requireAuth: true,
  //   container: ContractManagement,
  // },

  {
    title: 'User Manage',
    path: '/usermanage',
    requireAuth: true,
    container: lazy(() => import('../containers/UserManage/Dashboard/UserManage')),
  },
  {
    title: 'Create User',
    path: '/usermanage/create',
    requireAuth: true,
    container: lazy(() => import('../containers/UserManage/CreateUser/CreateUser')),
  },
  {
    title: 'User Manage',
    path: '/usermanage/:user_id',
    requireAuth: true,
    container: lazy(() => import('../containers/UserManage/Dashboard/UserManage')),
  },
  {
    title: 'Edit User',
    path: '/usermanage/edit/:userid',
    requireAuth: true,
    container: lazy(() => import('../containers/UserManage/EditUser/EditUser')),
  },

  {
    title: 'Package Manage',
    path: '/packagemanage',
    requireAuth: true,
    container: lazy(
      () => import('../containers/PackageCampaign/Dashboard/Package/DashboardPackage'),
    ),
  },
  {
    title: 'Create Package',
    path: '/packagemanage/create',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Create/Package/DetailPackage')),
  },
  {
    title: 'Create Package',
    path: '/packagemanage/clone/:package_name',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Clone/ClonePackage')),
  },
  {
    title: 'Edit Package',
    path: '/packagemanage/edit/:package_name',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Edit/EditPackage')),
  },
  {
    title: 'Campaign Manage',
    path: '/campaignmanage',
    requireAuth: true,
    container: lazy(
      () => import('../containers/PackageCampaign/Dashboard/Campaign/DashboardCampaign'),
    ),
  },
  {
    title: 'Create Campaign',
    path: '/campaignmanage/create',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Create/Campaign/DetailCampaign')),
  },
  {
    title: 'Create Campaign',
    path: '/campaignmanage/clone/:campaign_name',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Clone/CloneCampaign')),
  },
  {
    title: 'Create Campaign',
    path: '/campaignmanage/edit/:campaign_name',
    requireAuth: true,
    container: lazy(() => import('../containers/PackageCampaign/Edit/EditCampaign')),
  },

  {
    //*ต้องเช็คอีกทีว่าใส่ในไหน
    title: 'Host Contract',
    path: '/host/contract/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/HostContract')),
  },
  {
    title: 'Banner Management',
    path: '/banner',
    requireAuth: true,
    container: lazy(() => import('../containers/BannerManage')),
  },
  {
    title: 'GroupCode Management',
    path: '/groupcode',
    requireAuth: true,
    container: lazy(() => import('../containers/CampaignCodeManager')),
  },

  {
    //*ต้องเช็คอีกทีว่าใส่ในไหน
    title: 'User Request Management',
    path: '/userrequest',
    requireAuth: true,
    container: lazy(() => import('../containers/Userdeletion')),
  },
  // {
  //   title: 'Price Model',
  //   path: '/pricemodel/:brand',
  //   requireAuth: true,
  //   container: DetailPriceModel,
  // },
  {
    title: 'Price Model',
    path: '/pricemodel',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/Dashboard/DashboardPriceModel')),
  },
  {
    title: 'Price Model',
    path: '/pricemodel/create',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/CreatePriceModel/DetailPriceModel')),
  },
  {
    title: 'Create Price Model',
    path: '/pricemodel/clone/:brand_key/:model_key',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/Clone/ClonePriceModel')),
  },
  {
    title: 'Create Price Model',
    path: '/pricemodel/clone/:brand_key/:model_key/:sub_model',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/Clone/ClonePriceModel')),
  },
  {
    title: 'Edit Price Model',
    path: '/pricemodel/edit/:brand_key/:model_key',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/EditPriceModel/EditPriceModel')),
  },
  {
    title: 'Edit Price Model',
    path: '/pricemodel/edit/:brand_key/:model_key/:sub_model',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceModel/EditPriceModel/EditPriceModel')),
  },

  // {
  //   title: 'Vehicle Master',
  //   path: '/vehiclemaster',
  //   requireAuth: true,
  //   container: Vehicle Master,
  // },
  {
    title: 'Brand Manage',
    path: '/vehiclemaster/brand',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleBrand/index')),
  },
  {
    title: 'Create Brand',
    path: '/vehiclemaster/brand/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleBrand/CreateBrand')),
  },
  // {
  //   title: 'Edit Brand',
  //   path: '/vehiclemaster/brand/edit:key',
  //   requireAuth: true,
  //   container: DetailBrand,
  // },
  {
    title: 'Model Manage',
    path: '/vehiclemaster/model',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleModel/index')),
  },
  // },
  {
    title: 'Create Model',
    path: '/vehiclemaster/model/clone',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleModel/CreateModel')),
  },
  {
    title: 'Create Model',
    path: '/vehiclemaster/model/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleModel/CreateModel')),
  },
  // {
  //   title: 'Manage Model',
  //   path: '/vehiclemaster/:brand/:model',
  //   requireAuth: true,
  //   container: DetailModel,
  // },
  {
    title: 'Color Manage',
    path: '/vehiclemaster/color',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleColor/index')),
  },
  {
    title: 'Create Color',
    path: '/vehiclemaster/color/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleColor/CreateColor')),
  },
  {
    title: 'Type Manage',
    path: '/vehiclemaster/type',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleType/index')),
  },
  {
    title: 'Create Type',
    path: '/vehiclemaster/type/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleType/CreateType')),
  },
  {
    title: 'Size International Manage',
    path: '/vehiclemaster/sizeintl',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleSizeIntl/index')),
  },
  {
    title: 'Create Size International',
    path: '/vehiclemaster/sizeintl/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleSizeIntl/CreateSizeIntl')),
  },
  {
    title: 'Vehicle Size Manage',
    path: '/vehiclemaster/size',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleSize/index')),
  },
  {
    title: 'Create Vehicle Size',
    path: '/vehiclemaster/size/create',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleMaster/VehicleSize/CreateSize')),
  },
  {
    title: 'User Referral Management',
    path: '/user-referral',
    requireAuth: true,
    container: lazy(() => import('../containers/UserReferral')),
  },
  {
    //*ต้องเช็คอีกทีว่าใส่ในไหน
    title: 'TTT Redeem',
    path: '/events/ttt/redeem',
    requireAuth: true,
    container: lazy(() => import('../containers/Events/TTTCouponRedemptionPage')),
    // Paths related to promo code
  },
  {
    title: 'Promotion Code',
    path: '/promocode',
    requireAuth: true,
    container: lazy(() => import('../containers/PromoCode')),
  },
  {
    title: 'Add Promotional Code',
    path: '/promocode/create',
    requireAuth: true,
    container: lazy(() => import('../containers/PromoCodeAdd')),
  },
  {
    title: 'Edit Promotional Code',
    path: '/promocode/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PromoCodeEdit')),
  },
  {
    title: 'Price management',
    path: '/price-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceManagement')),
  },
  {
    title: 'Create a price',
    path: '/price-manage/create',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceManagement/PriceManagementAdd')),
  },
  {
    title: 'Create a price',
    path: '/price-manage/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceManagement/PriceManagementEdit')),
  },
  {
    title: 'Create a price rate',
    path: '/price-rate/create/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceRate/PriceRateAdd')),
  },
  {
    title: 'Check price rate',
    path: '/price-rate/all/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceRate')),
  },
  {
    title: 'Edit a price rate',
    path: '/price-rate/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/PriceRate/PriceRateEdit')),
  },
  {
    title: 'Coupon Management',
    path: '/coupon',
    requireAuth: true,
    container: lazy(() => import('../containers/Coupon')),
  },
  {
    title: 'Reservation Management',
    path: '/reservation/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage')),
  },
  {
    title: 'Create a reservation',
    path: '/create-reservation',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage/ReservationCreate')),
  },
  {
    title: 'Create a reservation',
    path: '/create-reservation-charge',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage/ReservationCreateCharge')),
  },
  {
    title: 'Chat Monitor',
    path: '/chat-monitor',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage/ChatMonitor')),
  },
  {
    title: 'Chat Monitor detail',
    path: '/chat-monitor/room/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage/ChatMonitor/ChatMonitorDetail')),
  },
  {
    title: 'Edit a reservation',
    path: '/reservation/:id/edit',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationManage/ReservationEdit')),
  },
  {
    title: 'Check Coupon TTT2023',
    path: '/transaction/cx',
    requireAuth: true,
    container: lazy(() => import('../containers/CheckCoupon')),
  },
  {
    title: 'Transaction List',
    path: '/transaction/acc',
    requireAuth: true,
    container: lazy(() => import('../containers/TransactionListACC')),
  },
  {
    title: 'Message Template',
    path: '/templating/message',
    requireAuth: true,
    container: lazy(() => import('../containers/Templating/Message')),
  },
  {
    title: 'Logs',
    path: '/logs',
    requireAuth: true,
    container: lazy(() => import('../containers/LogSystem')),
  },
  {
    title: 'Admin Manage',
    path: '/adminManage',
    requireAuth: true,
    container: lazy(() => import('../containers/AdminManageList')),
  },
  {
    title: 'Admin Create',
    path: '/adminManage/create',
    requireAuth: true,
    container: lazy(() => import('../containers/AdminManageCreate')),
  },
  {
    title: 'Check Price',
    path: '/checkPrice',
    requireAuth: true,
    container: lazy(() => import('../containers/checkPriceList')),
  },
  {
    title: 'Groups',
    path: '/groups',
    requireAuth: true,
    container: lazy(() => import('../containers/GroupsManageList')),
  },
  {
    title: 'Group Create',
    path: '/groups/create',
    requireAuth: true,
    container: lazy(() => import('../containers/GroupsManageCreate')),
  },
  {
    title: 'Car check',
    path: '/reservation/:id/checking',
    requireAuth: true,
    container: lazy(() => import('../containers/CarCheckInOut')),
  },
  {
    title: 'Email template',
    path: '/templating/email',
    requireAuth: true,
    container: lazy(() => import('../containers/Templating/Email')),
  },
  {
    title: 'Safe-T',
    path: '/events/safe-t/tickets',
    requireAuth: true,
    container: lazy(() => import('../containers/Safe-T')),
  },
  {
    title: 'Report',
    path: '/report',
    requireAuth: true,
    container: lazy(() => import('../containers/VehicleCalendar')),
  },
  {
    title: 'Invoice',
    path: '/invoice',
    requireAuth: true,
    container: lazy(() => import('../containers/Invoice')),
  },
  {
    title: 'Charge',
    path: '/charge',
    requireAuth: true,
    container: lazy(() => import('../containers/Charge')),
  },
  {
    title: 'Charge',
    path: '/charge/create',
    requireAuth: true,
    container: lazy(() => import('../containers/Charge/newCreateCharge/index')),
  },
  // {
  //   title: 'Charge',
  //   path: '/charge/:id/new',
  //   requireAuth: true,
  //   container: lazy(() => import('../containers/Charge/ChargeCreate')),
  // },
  {
    title: 'New Invoice',
    path: '/invoice/new',
    requireAuth: true,
    container: lazy(() => import('../containers/Invoice/InvoiceCreate')),
  },
  {
    title: 'New Transaction TTT',
    path: '/ttt/new',
    requireAuth: true,
    container: lazy(() => import('../containers/TTTTransactionCreate')),
  },
  {
    title: 'Push Notification for reservation',
    path: '/push-notification-reservation',
    requireAuth: true,
    container: lazy(() => import('../containers/PushNotification/Reservation')),
  },
  {
    title: 'Blacklist Name',
    path: '/blacklist',
    requireAuth: true,
    container: lazy(() => import('../containers/BlacklistManage')),
  },
  {
    title: 'Booking',
    path: '/booking',
    requireAuth: true,
    container: lazy(() => import('../containers/BookingList')),
  },
  {
    title: 'Booking Create',
    path: '/booking/create',
    requireAuth: true,
    container: lazy(() => import('../containers/BookingCreate')),
  },
  {
    title: 'Booking View',
    path: '/booking/:bookingId',
    requireAuth: true,
    container: lazy(() => import('../containers/BookingEdit')),
  },
  {
    title: 'Booking Send Email',
    path: '/booking/:bookingId/sendmail',
    requireAuth: true,
    container: lazy(() => import('../containers/BookingSendEmail')),
  },
  {
    title: 'Address',
    path: '/address',
    requireAuth: true,
    container: lazy(() => import('../containers/AddressList')),
  },
  {
    title: 'Address Create',
    path: '/address/create',
    requireAuth: true,
    container: lazy(() => import('../containers/AddressCreate')),
  },
  {
    title: 'Address View',
    path: '/address/:addressId',
    requireAuth: true,
    container: lazy(() => import('../containers/AddressEdit')),
  },
  {
    title: 'Email Template List',
    path: '/email/template',
    requireAuth: true,
    container: lazy(() => import('../containers/EmailTemplateList')),
  },
  {
    title: 'Email Template Create',
    path: '/email/template/create',
    requireAuth: true,
    container: lazy(() => import('../containers/EmailTemplateCreate')),
  },
  {
    title: 'Email Template Edit',
    path: '/email/template/:templateId',
    requireAuth: true,
    container: lazy(() => import('../containers/EmailTemplateEdit')),
  },
  {
    title: 'Email Sender',
    path: '/email',
    requireAuth: true,
    container: lazy(() => import('../containers/EmailSentLog')),
  },
  {
    title: 'Email Compose',
    path: '/email/compose',
    requireAuth: true,
    container: lazy(() => import('../containers/EmailCompose')),
  },
  {
    title: 'Blacklist Photo',
    path: '/blacklist/photo',
    requireAuth: true,
    container: lazy(() => import('../containers/BlacklistManage/BlacklistPhoto')),
  },
  {
    title: 'Station List',
    path: '/station',
    requireAuth: true,
    container: lazy(() => import('../containers/StationList')),
  },
  {
    title: 'Station Create',
    path: '/station/create',
    requireAuth: true,
    container: lazy(() => import('../containers/StationCreate/StationCreatePage')),
  },
  {
    title: 'Station Edit',
    path: '/station/:stationId',
    requireAuth: true,
    container: lazy(() => import('../containers/StationEdit')),
  },
  {
    title: 'Credit List',
    path: '/credit',
    requireAuth: true,
    container: lazy(() => import('../containers/UserCreditList')),
  },
  {
    title: 'Create Credit',
    path: '/credit/create',
    requireAuth: true,
    container: lazy(() => import('../containers/UserCreditCreate')),
  },
  {
    title: 'User Photo',
    path: '/user-photo',
    requireAuth: true,
    container: lazy(() => import('../containers/UserPhoto')),
  },
  {
    title: 'Department List',
    path: '/department',
    requireAuth: true,
    container: DepartmentList,
  },
  {
    title: 'Position List',
    path: '/department/:id',
    requireAuth: true,
    container: PositionList,
  },
  {
    title: 'Menu Management',
    path: '/menu',
    requireAuth: true,
    container: MenuList,
  },
  {
    title: 'Create Menu',
    path: '/menu/create',
    requireAuth: true,
    container: MenuCreate,
  },
  {
    title: 'Edit Menu',
    path: '/menu/:id/edit',
    requireAuth: true,
    container: MenuEdit,
  },
  {
    title: 'Permission List',
    path: '/permissions',
    requireAuth: true,
    container: PermissionList,
  },
  {
    title: 'Create Permission',
    path: '/permissions/create',
    requireAuth: true,
    container: PermissionsCreate,
  },
  {
    title: 'Department List',
    path: '/department',
    requireAuth: true,
    container: DepartmentList,
  },
  {
    title: 'Position List',
    path: '/department/:id',
    requireAuth: true,
    container: PositionList,
  },
  {
    title: 'Menu Management',
    path: '/menu',
    requireAuth: true,
    container: MenuList,
  },
  {
    title: 'Create Menu',
    path: '/menu/create',
    requireAuth: true,
    container: MenuCreate,
  },
  {
    title: 'Edit Menu',
    path: '/menu/:id/edit',
    requireAuth: true,
    container: MenuEdit,
  },
  {
    title: 'Permission List',
    path: '/permissions',
    requireAuth: true,
    container: PermissionList,
  },
  {
    title: 'Create Permission',
    path: '/permissions/create',
    requireAuth: true,
    container: PermissionsCreate,
  },
  {
    title: 'Standard Price Project Markup',
    path: '/stdPriceProjectMarkup',
    requireAuth: true,
    container: lazy(() => import('../containers/StdPriceProjectMarkup')),
  },
  {
    title: 'Create Standard Price Project Markup',
    path: '/stdPriceProjecMarkup/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/StdPriceProjectMarkup/CreateStdPriceProjectMarkup'),
    ),
  },
  {
    title: 'Edit Standard Price Project Markup',
    path: '/stdPriceProjectMarkup/edit/:project_id',
    requireAuth: true,
    container: lazy(() => import('../containers/StdPriceProjectMarkup/EditStdPriceProjectMarkup')),
  },
  {
    title: 'Standard Price Channel Markup',
    path: '/stdPriceChannelMarkup',
    requireAuth: true,
    container: lazy(() => import('../containers/StdPriceChannelMarkup')),
  },
  {
    title: 'Create Standard Price Channel Markup',
    path: '/stdPriceChannelMarkup/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/StdPriceChannelMarkup/CreateStdPriceChannelMarkup'),
    ),
  },
  {
    title: 'Edit Standard Price Channel Markup',
    path: '/stdPriceChannelMarkup/edit/:channel_id',
    requireAuth: true,
    container: lazy(() => import('../containers/StdPriceChannelMarkup/EditStdPriceChannelMarkup')),
  },
  {
    title: 'stepper',
    path: '/check-in-airport',
    requireAuth: true,
    container: lazy(() => import('../containers/StepperAirPort')),
  },
  {
    title: 'Dispatch List',
    path: '/dispatch',
    requireAuth: true,
    container: lazy(() => import('../containers/DispatchList')),
  },
  {
    title: 'Reservation Dashboard',
    path: '/reservation-dashboard',
    requireAuth: true,
    container: lazy(() => import('../containers/ReservationDashboard')),
  },
  {
    title: 'Driver Management',
    path: '/driver',
    requireAuth: true,
    container: lazy(() => import('../containers/Driver')),
  },
  {
    title: 'Shift Management',
    path: '/driver/shift',
    requireAuth: true,
    container: lazy(() => import('../containers/DriverShift')),
  },
  {
    title: 'View Bookings',
    path: '/driver/bookings',
    requireAuth: true,
    container: lazy(() => import('../containers/ViewBookings')),
  },
  {
    title: 'Driver Manage',
    path: '/driver/dashboard',
    requireAuth: true,
    container: lazy(() => import('../containers/DriverDashboard')),
  },
  {
    title: 'Booking Invoice',
    path: '/booking-invoice/:bookingId',
    requireAuth: true,
    container: lazy(() => import('../containers/BookingList/BookingInvoice')),
  },
  {
    title: 'Van with driver / driver',
    path: '/van-with-driver/driver-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/DriverDashboard')),
  },
  {
    title: 'Van with driver / driver create',
    path: '/van-with-driver/driver-manage/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/DriverDashboard/DriverDashBoardCreate'),
    ),
  },
  {
    title: 'Van with driver / driver edit',
    path: '/van-with-driver/driver-manage-edit/:id',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/DriverDashboard/DriverDashBoardEdit'),
    ),
  },
  {
    title: 'Van with driver / addon',
    path: '/van-with-driver/addon-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/AddonDashboard')),
  },
  {
    title: 'Van with driver / addon create',
    path: '/van-with-driver/addon-manage/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/AddonDashboard/AddonDashBoardCreate'),
    ),
  },
  {
    title: 'Van with driver / addon edit',
    path: '/van-with-driver/addon-manage-edit/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/AddonDashboard/AddonDashBoardEdit')),
  },
  {
    title: 'Van with driver / vehicle',
    path: '/van-with-driver/vehicle-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/VehicleDashBoard')),
  },
  {
    title: 'Van with driver / vehicle create',
    path: '/van-with-driver/vehicle-manage/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/VehicleDashBoard/VehicleDashBoardCreate'),
    ),
  },
  {
    title: 'Van with driver / vehicle edit',
    path: '/van-with-driver/vehicle-manage-edit/:id',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/VehicleDashBoard/VehicleDashBoardEdit'),
    ),
  },
  {
    title: 'Van with driver / destination',
    path: '/van-with-driver/destination-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/DestinationDashboard')),
  },
  {
    title: 'Van with driver / destination create',
    path: '/van-with-driver/destination-manage/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/DestinationDashboard/DestinationDashBoardCreate'),
    ),
  },
  {
    title: 'Van with driver / destination edit',
    path: '/van-with-driver/destination-manage-edit/:id',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/DestinationDashboard/DestinationDashBoardEdit'),
    ),
  },
  {
    title: 'Van with driver / booking',
    path: '/van-with-driver/booking-manage',
    requireAuth: true,
    container: lazy(() => import('../containers/VanWithDriver/BookingDashboard')),
  },
  {
    title: 'Van with driver / booking',
    path: '/van-with-driver/booking-manage/create',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/BookingDashboard/BookingDashBoardCreate'),
    ),
  },
  {
    title: 'Van with driver / booking edit',
    path: '/van-with-driver/booking-manage-edit/:id',
    requireAuth: true,
    container: lazy(
      () => import('../containers/VanWithDriver/BookingDashboard/BookingDashBoardEdit'),
    ),
  },
  {
    title: 'Revenue Management',
    path: '/auto-revenue',
    requireAuth: true,
    container: lazy(() => import('../containers/AutoRevenue/partner-income-lists')),
  },
  {
    title: 'Partner Revenue',
    path: '/auto-revenue/partner',
    requireAuth: true,
    container: lazy(() => import('../containers/AutoRevenue/partner-revenue')),
  },
  {
    title: 'Airport Request List',
    path: '/airport/package/request',
    requireAuth: true,
    container: lazy(() => import('../containers/AirportPackage/TransactionList')),
  },
  {
    title: 'Airport Package Purchase',
    path: '/airport/package/purchase',
    requireAuth: true,
    container: lazy(() => import('../containers/AirportPackage/CreateTransaction')),
  },
  {
    title: 'Airport Package Accounting',
    path: '/airport/package/accounting',
    requireAuth: true,
    container: lazy(() => import('../containers/AirportPackage/TransactionAccounting')),
  },
  {
    title: 'User - Building Management',
    path: '/building-management/user',
    requireAuth: true,
    container: lazy(() => import('../containers/BuildingManagement/User')),
  },
  {
    title: 'Company - Building Management',
    path: '/building-management/company',
    requireAuth: true,
    container: lazy(() => import('../containers/BuildingManagement/Company')),
  },
  {
    title: 'Building - Building Management',
    path: '/building-management/building',
    requireAuth: true,
    container: lazy(() => import('../containers/BuildingManagement/Building')),
  },
  {
    title: 'Airport Vehicle Model',
    path: '/airport/vehicle',
    requireAuth: true,
    container: lazy(() => import('../containers/AirportVehicle')),
  },
  {
    title: 'Airport Transfer Package',
    path: '/airport/package',
    requireAuth: true,
    container: lazy(() => import('../containers/AirportPackage/PackageList')),
  },
  {
    title: 'Fast Track List',
    path: '/airport/fast-track',
    requireAuth: true,
    container: lazy(() => import('../containers/FastTrack/List')),
  },
  {
    title: 'Fast Track Item List',
    path: '/airport/fast-track/:code/items',
    requireAuth: true,
    container: lazy(() => import('../containers/FastTrack/Items')),
  },
  {
    title: 'Ordering menu group',
    path: '/menu/order',
    requireAuth: true,
    container: lazy(() => import('../containers/MenuList/Order')),
  },
  {
    title: 'Addon Master',
    path: '/addon/master',
    requireAuth: true,
    container: lazy(() => import('../containers/AddonModule/index')),
  },
  {
    title: 'Addon Inventory',
    path: '/addon/inventory',
    requireAuth: true,
    container: lazy(() => import('../containers/AddonModule/Inventory/index')),
  },
  {
    title: 'Addon Order',
    path: '/addon/orders',
    requireAuth: true,
    container: lazy(() => import('../containers/AddonModule/OrderItems/index')),
  },
  {
    title: 'Task Type',
    path: '/task/types',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskType/index')),
  },
  {
    title: 'Task Form List',
    path: '/task/form',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskFormFields/Form/List/index')),
  },
  {
    title: 'Create Form Field',
    path: '/task/form/:slug/field/create',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskFormFields/Fields/Create')),
  },
  {
    title: 'Edit Form Field',
    path: '/task/form/:slug/field/:field',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskFormFields/Fields/Edit')),
  },
  {
    title: 'Create Form',
    path: '/task/form/create',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskFormFields/Form/Create/index')),
  },
  {
    title: 'Update Form and fields',
    path: '/task/form/:slug',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskFormFields/Form/Edit/index')),
  },
  {
    title: 'Task Management',
    path: '/task/list',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/Dashboard')),
  },
  {
    title: 'Task Management',
    path: '/task/create',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskModule')),
  },
  {
    title: 'Task Management',
    path: '/task/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/TaskManagement/TaskModule')),
  },
  {
    title: 'Package Master Management',
    path: '/ctrip/package/master/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/PackageMaster/List/index')),
  },
  {
    title: 'Ctrip Booking Report Download',
    path: '/ctrip/booking/report',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BookingList/Report/index')),
  },
  {
    title: 'C Trip Create Package Master',
    path: '/ctrip/package/master/create',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/PackageMaster/Create/index')),
  },
  {
    title: 'C Trip Create Edit Master',
    path: '/ctrip/package/master/edit/:id',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/PackageMaster/Edit/index')),
  },
  {
    title: 'C Trip Booking Management',
    path: '/ctrip/booking/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BookingList/index')),
  },
  {
    title: 'C Trip Package Management',
    path: '/ctrip/package/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/Package/index')),
  },
  {
    title: 'C Trip Branch Management',
    path: '/ctrip/branch/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/Branch/index')),
  },
  {
    title: 'C Trip Branch Addon Management',
    path: '/ctrip/branch/addon/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BranchAddon/index')),
  },
  {
    title: 'C Trip Branch Model Management',
    path: '/ctrip/branch/model/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BranchModel/index')),
  },
  {
    title: 'C Trip Branch Model Management',
    path: '/ctrip/branch/model/:id/pricing',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BranchModel/Pricing/index')),
  },
  {
    title: 'C trip Booking Spec Equipment',
    path: '/ctrip/booking/special-equipment/list',
    requireAuth: true,
    container: lazy(() => import('../containers/OpenApiModule/BookingSpecEquip/index')),
  },
  {
    title: 'Home Page Banner',
    path: '/homepage-banner',
    requireAuth: true,
    container: lazy(() => import('../containers/HomePageBanner')),
  },
]

// Function to check for duplicated route
// const count = routes.reduce((acc, route) => {
//   if (route.path in acc) {
//     const prevCount = acc[route.path]
//     const currentCount = prevCount + 1
//     return {
//       ...acc,
//       [route.path]: currentCount,
//     }
//   }
//   return {
//     ...acc,
//     [route.path]: 1,
//   }
// }, {} as Record<string, number>)

// Object.entries(count).map(([key, value]) => {
//   if (value > 1) {
//     throw new Error(`Route ${key} is duplicated.`)
//   }
// })

export default routes
