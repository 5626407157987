/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Form, Input, Modal, Popconfirm, Select, Space, Table, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CMSHeader } from 'src/components/CMSHeader'
import Notification from 'src/components/Notification'
import { RowBetween } from 'src/components/Styles/row.style'
import { useDebounce } from 'usehooks-ts'
import CMSButton from '../../components/Styles/button.style'
import { GroupedMenu, Menu } from '../../interfaces/permissions.interface'
import {
  getMenus,
  switchFromMainMenuToGroup,
  switchFromMenuGroupToMain,
} from '../../services/admin-permissions/menu.api'
import { showActiveBanner } from '../PositionList'
import { haveDescriptionName } from './menu.fn'
const { Search } = Input
const { Title } = Typography

export const MenuList = (): JSX.Element => {
  const [loadingMenu, setLoadingMenu] = useState(false)
  const [switchMenuFrom] = Form.useForm()
  const history = useHistory()
  const [queryText, setQuery] = useState('')
  const [openMoveMenuToGroupModal, setOpenMoveMenuToGroupModal] = useState(false)
  const debouncedQuery = useDebounce(queryText, 500)
  const [selectedGroupMenu, setSelectedGroupMenu] = useState<null | number>(null)

  const cancelMenuGroupModal = () => {
    setOpenMoveMenuToGroupModal(false)
    setSelectedGroupMenu(null)
    switchMenuFrom.resetFields()
    setLoadingMenu(false)
  }

  const [groupedMenus, setGroupedMenus] = useState<GroupedMenu[]>([])

  const getMain = useMemo(() => {
    if (groupedMenus.length === 0) return []
    return [...groupedMenus]
      .filter((group) => group.grouped.length !== 0)
      .map((groupedMenu) => ({
        name: groupedMenu.menu_name,
        id: groupedMenu.menu_id,
      }))
  }, [groupedMenus])

  const loadData = async (): Promise<void> => {
    setLoadingMenu(true)
    // loadingMenu.value = true
    await getMenus()
      .then((res) => {
        setGroupedMenus(res)
      })
      .finally(() => setLoadingMenu(false))
  }

  const filteredMenus = useMemo(() => {
    let menuList = [...groupedMenus]

    if (menuList.length === 0) return []
    if (debouncedQuery !== '' && debouncedQuery !== undefined) {
      menuList = [...menuList].filter((groupedMenu) =>
        haveDescriptionName(groupedMenu, debouncedQuery),
      )
    }
    return menuList
  }, [debouncedQuery, groupedMenus])

  const addMenuToGroup = async () => {
    const currentMenuBody = groupedMenus.find(
      (groupedMenu) => groupedMenu.menu_id === selectedGroupMenu,
    )
    if (!currentMenuBody) return Notification('An error occurred', 'error')
    const newMenuIdAssigned = switchMenuFrom.getFieldValue('menuId')
    setLoadingMenu(true)
    await switchFromMainMenuToGroup(
      selectedGroupMenu as number,
      (currentMenuBody as any) as Menu,
      newMenuIdAssigned as number,
    ).finally(async () => {
      cancelMenuGroupModal()
      await loadData()
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const baseMenusColumns = [
    {
      title: 'ID',
      dataIndex: 'menu_id',
      key: 'menu_id',
    },
    {
      title: 'Name',
      dataIndex: 'menu_name',
      key: 'menu_name',
    },
    {
      title: 'Description',
      dataIndex: 'menu_description',
      key: 'menu_description',
    },
    {
      title: 'Active',
      align: 'center' as const,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (item: Menu['is_active']) => showActiveBanner(item),
    },
    {
      title: 'System',
      align: 'center' as const,
      dataIndex: 'system_id',
      key: 'system_id',
      render: (item: Menu['system_id']) => (item === 2 ? 'CMS2' : 'OTHERS'),
    },
  ]

  const extendedMenusColumns = [
    ...baseMenusColumns,
    {
      title: 'Action',
      align: 'center' as const,
      render: (item: GroupedMenu) => {
        const isGroup = item.grouped.length !== 0
        return (
          <Space>
            {!isGroup && item.menu_path === null && (
              <Alert message="This menu has no path" type="warning" />
            )}
            <CMSButton
              buttonColor="lg-blue"
              onClick={() => {
                history.push(`/menu/${item.menu_id}/edit`)
              }}
            >
              EDIT
            </CMSButton>
            {!isGroup && (
              <CMSButton
                buttonColor="lg-blue"
                onClick={() => {
                  setSelectedGroupMenu(() => (item.menu_id ? item.menu_id : null))
                  setOpenMoveMenuToGroupModal(true)
                }}
              >
                SWITCH TO GROUP MENU
              </CMSButton>
            )}
          </Space>
        )
      },
    },
  ]

  const switchMenuInsideGroupToMain = async (body: Menu) => {
    setLoadingMenu(true)
    await switchFromMenuGroupToMain(body?.menu_id as number, body).then(
      async () => await loadData(),
    )
    // loadData() will set the loading to false after fetching the data
  }

  const GroupedMenuTable = ({ menus }: { menus: Menu[] }) => {
    const groupedColumns = [
      ...baseMenusColumns,
      {
        title: 'Action',
        align: 'center' as const,
        render: (item: Menu) => (
          <Space>
            {item.menu_path === null && <Alert message="This menu has no path" type="warning" />}
            <CMSButton
              buttonColor="lg-blue"
              onClick={() => history.push(`/menu/${item.menu_id}/edit`)}
            >
              EDIT
            </CMSButton>
            <Popconfirm
              title={`Do you want to switch ${item.menu_name} as main?`}
              onConfirm={() => {
                switchMenuInsideGroupToMain(item)
              }}
            >
              <CMSButton buttonColor="lg-blue">SWITCH TO MAIN MENU</CMSButton>
            </Popconfirm>
          </Space>
        ),
      },
    ]
    return <Table pagination={false} columns={groupedColumns} dataSource={menus} />
  }

  return (
    <>
      <CMSHeader
        headerLeft="Menu List"
        headerRight={() => (
          <Space size={40}>
            <Link to="/menu/order">
              <CMSButton buttonColor="purple">Rearrange CMS SIdebar</CMSButton>
            </Link>
            <Link to="/menu/create">
              <CMSButton buttonColor="lg-blue">Create Menu</CMSButton>
            </Link>
          </Space>
        )}
        renderHeaderBottom={() => (
          <div style={{ position: 'relative', height: 'max-content' }}>
            <button
              onClick={() => setQuery('')}
              style={{
                position: 'absolute',
                backgroundColor: 'black',
                color: 'white',
                right: 0,
                height: 30,
                width: 30,
                borderRadius: 999,
                zIndex: 1,
                fontSize: 16,
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = 'white'
                e.currentTarget.style.color = 'black'
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'black'
                e.currentTarget.style.color = 'white'
              }}
            >
              X
            </button>
            <Input
              value={queryText}
              disabled={loadingMenu}
              style={{ width: 'calc(100% - 40px)' }}
              placeholder="Search menu by description, name or group_name"
              onChange={(e) => setQuery(() => e.target.value)}
            />
          </div>
        )}
      />
      <Table
        loading={loadingMenu}
        pagination={{
          pageSize: 25,
        }}
        rowKey="menu_id"
        dataSource={filteredMenus}
        columns={extendedMenusColumns}
        expandable={{
          rowExpandable: (record: GroupedMenu) => {
            if (record.grouped.length > 0) return true
            else return false
          },
          expandedRowRender: (record: GroupedMenu) => <GroupedMenuTable menus={record.grouped} />,
        }}
      />
      <Modal open={openMoveMenuToGroupModal} centered footer={false} closable>
        <Title level={4} style={{ marginTop: '2rem' }}>
          Assign new menu
        </Title>
        <Form form={switchMenuFrom} onFinish={addMenuToGroup}>
          <Form.Item
            name="menuId"
            label="Select a new menu"
            rules={[
              {
                required: true,
                message: 'Please select a menu or cancel',
              },
            ]}
          >
            <Select style={{ width: '100%' }}>
              {getMain.map((mainMenu) => (
                <Select.Option value={mainMenu.id}>{mainMenu.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <RowBetween>
            <CMSButton loading={loadingMenu} buttonColor="red" onClick={cancelMenuGroupModal}>
              CANCEL
            </CMSButton>
            <Popconfirm
              okText="OK"
              onCancel={cancelMenuGroupModal}
              title="Do you want to assign the value"
              onConfirm={() => switchMenuFrom.submit()}
            >
              <CMSButton loading={loadingMenu} buttonColor="purple">
                ASSIGN NEW MENU
              </CMSButton>
            </Popconfirm>
          </RowBetween>
        </Form>
      </Modal>
    </>
  )
}
