import { IPaymentType } from '../charge/charge.interface'
import { IInvoiceItemsApi, TPaymentChannel } from '../invoice/invoice.interface'
import { UserModel } from '../user/users.interfaces'

export interface IGetReservation {
  page?: number
  results?: number
  reservationNo?: number
  userId?: number
  email?: string
  vehicleId?: number
  vehicleCode?: string
  stationId?: number
  state?: 'ALL' | 'RESERVE' | 'DRIVE' | 'FINISH' | 'CANCEL' | 'COMPLETE' | 'REQUEST' | 'REJECT'
  addons?: Array<
    | 'addon_cleaning'
    | 'addon_delivery'
    | 'addon_driver'
    | 'addon_helmet'
    | 'addon_helmet_addition'
    | 'addon_insurance'
  >
  tripType: 'ALL' | 'ROUNDTRIP' | 'ONEWAY'
  checkIn: Array<
    | 'NO'
    | 'PENDING_4SIDE'
    | 'PENDING_ADDITION'
    | 'PENDING_DASHBOARD'
    | 'PENDING'
    | 'SUCCESS'
    | 'SKIP'
  >
  checkOut: Array<'NO' | 'PENDING' | 'SUCCESS' | 'SKIP'>
  gasPolicies: Array<'NONE' | 'BUNDLE' | 'FULL_TO_FULL' | 'SAME_LEVEL' | 'EV'>
  vehicleSystem: Array<'HAUP' | 'MANUAL' | 'NOTRACKING'>
  partnerType: Array<'OEM' | 'OTA' | 'P2P' | 'UNDEFINED'>
  subscriptionPeriod: Array<'1W' | '1M' | '3M' | '6M' | '12M'>
  fromStartReserveTime?: Date
  toStartReserveTime?: Date
  isNewReservation: boolean
  isFinishReservation: boolean
}

export interface IGetReservationByVehicle {
  //copy from ts-restful file reservation.service.ts
  vehicleId?: number
  hostUserId?: number
  reservationStatus?: ReservationStatus | ''
  reservationNo?: number
  page?: number
  results?: number
  dateFrom?: string
  dateTo?: string
  stationId?: number
}

export enum ReservationStatus { //copy from ts-restful file reservation.service.ts
  ALL = 'ALL',
  REQUEST = 'REQUEST',
  RESERVE = 'RESERVE',
  REJECT = 'REJECT',
  FINISH = 'FINISH',
  COMPLETE = 'COMPLETE',
}

export interface ISelectOptions {
  label: string
  value: string
}

export enum EReservationState {
  RESERVE = 'RESERVE',
  DRIVE = 'DRIVE',
  FINISH = 'FINISH',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
  REQUEST = 'REQUEST',
  REJECT = 'REJECT',
}

export interface IReservationManageApi {
  reservationNo: 11
  receiptDate: Date
  receiptNo: string
  logTime: Date
  userId: number
  userType: string
  groupId: number
  vehicleGroupId: number
  ownerUserId: number
  driverUserId: number
  approveUserId: number
  approveTime?: Date
  payment: 'CASH' | 'CREDIT' | 'GROUPCREDIT'
  paymentNo: number
  vehicleId: number
  stationId: number
  reservationType: 'ROUNDTRIP' | 'ONEWAY'
  dstStationId: number
  couponCode: string
  promotionCode: string
  promotionId: number
  bundleId: number
  bundleHours: number
  bundleDistanceKm: number
  bundlePrice: number
  lateHourPrice: number
  limitCreditBaht: number
  limitCreditHour: number
  limitCreditKm: number
  requestCreditBaht: number
  requestCreditHour: number
  requestCreditKm: number
  applyCreditBaht: number
  applyCreditHour: number
  applyCreditKm: number
  couponNo: number
  discount: number
  hourRate: number
  dayRate: number
  lateHourRate: number
  freeHours: number
  freeDistance: number
  distanceRate: number
  advanceCheckinM: number
  advanceReserveM: number
  reservationGapM: number
  depositPriceB: number
  initDescription: string
  initStartTime: Date
  initStopTime: Date
  initHours: number
  initStaticPrice: number
  initHourRate: number
  initDayRate: number
  initPrice: number
  reserveDescription: string
  reserveStartTime: Date
  reserveStopTime: Date
  reserveHours: number
  reserveStaticPrice: number
  hourPrice: number
  estimatedPrice: number
  distancePrice: number
  driveFreeM: number
  drivePrice: number
  standByFreeM: number
  standByPrice: number
  actualStartTime: Date
  actualStopTime: Date
  actualHours: number
  actualHourRate: number
  actualDayRate: number
  reservationState: 'RESERVE' | 'DRIVE' | 'FINISH' | 'CANCEL' | 'COMPLETE' | 'REQUEST' | 'REJECT'
  actualPrice: number
  activateTime: Date
  deactivateTime?: Date
  checkInTime?: Date
  checkOutTime?: Date
  departTime?: Date
  arriveTime?: Date
  startKm: number
  stopKm: number
  engineHours: number
  validPositionHours: number
  minHDop: number
  maxHDop: number
  chargeId: string
  chargeState:
    | 'NONE'
    | 'CHARGED'
    | 'REVERSED'
    | 'ERROR'
    | 'HAUPCREDIT_CHARGED'
    | 'HAUPCREDIT_REVERSED'
    | 'CASH_CHARGED'
    | 'CASH_REVERSED'
    | 'CHARGES'
  chargeCreditCard: string
  chargeTotal: number
  totalPrice: number
  vatPrice: number
  remark: string
  extraChargeId: string
  extraChargeState:
    | 'NONE'
    | 'CHARGED'
    | 'REVERSED'
    | 'ERROR'
    | 'HAUPCREDIT_CHARGED'
    | 'HAUPCREDIT_REVERSED'
    | 'CHARGES'
  extraChargeCreditCard: string
  extraChargeTotal: number
  extraChargeCount: number
  completeTime: null
  ratingPre: number
  ratingPost: number
  ratingMarks: string
  chargeActualHours: number
  category: string
  taxRequest: number
  accessMode: 'AUTO' | 'BLE' | 'WS'
  enableNfcDrive: 0 | 1
  adminUserId: number
  walletId: number
  addonCleaning: string
  addonDelivery: string
  addonDriver: string
  addonHelmet: string
  addonHelmetAddition: string
  addonInsurance: string
  priceCleaning: number
  priceDelivery: number
  priceDriver: number
  priceHelmet: number
  priceHelmetAddition: number
  priceInsurance: number
  statusTags: string
  requireCheckin: 0 | 1
  checkInState:
    | 'NO'
    | 'PENDING_4SIDE'
    | 'PENDING_ADDITION'
    | 'PENDING_DASHBOARD'
    | 'PENDING'
    | 'SUCCESS'
    | 'SKIP'
  checkOutState: 'NO' | 'PENDING' | 'SUCCESS' | 'SKIP'
  creditGroupId: number
  internalRemark: string
  hourPriceAdjust: number
  distancePriceAdjust: number
  licenseNumber: string
  licenseProvince: string
  vehicleApproveUserId: number
  vehicleApproveTime: Date
  vehicleApproveRequire: 0 | 1
  approveRequire: 0 | 1
  planId: number
  rejectUserId: number
  rejectTime: Date
  rejectTags: string
  fullPrice: number
  referenceNo1: string
  referenceNo2: string
  referenceNo3: string
  overideDeposit: number
  overideHourPrice: number
  overideDistancePrice: number
  overideLateHourPrice: number
  overideBundlePrice: number
  overideDiscount: number
  overideOtherPrice: number
  overideOtherPriceRemark: string
  debundle: 0 | 1
  debundleFee: number
  gasingPolicy: 'NONE' | 'BUNDLE' | 'FULL_TO_FULL' | 'SAME_LEVEL' | 'EV'
  apiVersion: number
  deviceType: string
  vehicleSystem: 'HAUP' | 'MANUAL' | 'NOTRACKING'
  partnerType: 'OEM' | 'OTA' | 'P2P' | 'UNDEFINED'
  subscriptionPackage: string
  subscriptionStartDate: Date
  subscriptionMasterReservationNo: number
  billingInfo: string
  minReserveM: number
  maxReserveM: number
  validStartHour: number
  validStopHour: number
  addonReturnDelivery: string
  priceReturnDelivery: number
  cancellationUserId: number
  cancellationReasonCode: string
  cancellationReasonText: string
  insuranceNumber: string
}

export interface IReservationData extends IReservationManageApi {
  name: string
  email: string
  lastName: string
  vehicleCode: string
  vehicleType: string
  stationName: string
  vehicleModel: string
  vehicleBrand: string
  vehicleYear: number
  isActivated: string
  mobile: string
}

export interface INotesData {
  noteNo?: number
  refNoteNo: number
  pin: 0 | 1
  noteTime: Date
  dueTime?: Date
  completeTime?: Date
  closeTime?: Date
  adminUserId: number
  assignedUserId: number
  noteState: 'LOG' | 'OPEN' | 'INPROGRESS' | 'PENDING' | 'COMPLETE' | 'CLOSE' | 'CANCEL'
  priority: number
  userId: number
  userGroupId: number
  reservationNo: number
  vehicleId: number
  vehicleGroupId: number
  stationId: number
  stationGroupId: number
  detail: string
  tags: string
}

export interface IUserSelectedData {
  userId: number
  email: string
  name: string
}

export interface IVehicleSelectedData {
  vehicleId: number
  vehicleCode: string
}

export interface IStationSelectedData {
  stationId: number
  stationName: string
}

export enum GetTypeEnum {
  USER = 'USER',
  VEHICLE = 'VEHICLE',
  STATION = 'STATION',
}

export interface IReservationLogs {
  reservationNo: number
  logTime: Date
  adminUserId: string
  detail: string
  stationId: number
  reservationLogNo: number
  userId: number
  ownerUserId: number
  vehicleId: number
}

export interface IFieldsForEdit {
  name: string
  label: string
  component: JSX.Element
  itemRules?: any[]
}

export interface IGetEstimate {
  userId: number
  vehicleId: number
  stationId?: number
  reserveStartTime: Date | string
  reserveStopTime: Date | string
  category?: string
  haupCredit?: number
  code?: string[]
  discount?: number
  discountP?: number
}

export interface INoDataFoundSelector {
  user: boolean
  vehicle: boolean
  station: boolean
}

export interface IGetPromotionBody {
  userId: number
  vehicleId: number
  stationId?: number
  startTime: Date
  stopTime: Date
}

export interface IChargeApi {
  invoiceId?: number
  chargeNo: number
  receiptNo: string
  userId: number
  description: string
  reservationNo: 4428
  chargeEvent: 'RESERVE' | 'EXTRA' | 'MANUAL'
  chargeTime: Date
  reverseTime: Date
  chargeMethod: 'OMISE' | 'CASH' | 'BANK' | 'HAUPCREDIT'
  chargeId: string
  chargeAccount: string
  chargeState:
    | 'NONE'
    | 'CHARGED'
    | 'REVERSED'
    | 'ERROR'
    | 'HAUPCREDIT_CHARGED'
    | 'HAUPCREDIT_REVERSED'
    | 'CASH_CHARGED'
    | 'CASH_REVERSED'
    | 'PENDING'
  chargeStatus: string
  chargeTotal: number
  otpUrl: string
  paymentNo: number
  chargeHour: number
  chargeDistance: number
  chargeDeposit: number
  chargeLatefee: number
  chargeFine: number
  chargeDeductable: number
  chargeOther: number
  linkUrl: string
  linkId: string
  transferslipUrl: string
  callbackUrl: string
  holding: number
}

type TZeroOne = 0 | 1

export interface IVehicleModel {
  vehicleid: number
  ordercode: string
  owner_userid: number
  driver_userid: number
  vehiclecode: string
  vehicletype: string
  vehiclesystem: 'HAUP' | 'MANUAL' | 'NOTRACKING'
  vehicleschassisnumber: string
  vehiclebrand: string
  vehiclegrade: string
  vehiclemodel: string
  vehiclecolor: string
  vehicleseat: string
  vehicleyear: string
  vehicletransmission: string
  licensenumber: string
  licenseprovince: string
  staticprice: string
  hourrate: number
  dayrate: number
  latehourrate: number
  distancerate: number
  freedistance: number
  gpsid: number
  host_stationid: number
  pic_small: string
  pic_path: string
  groupid: number
  priceid: number
  vehiclestatus: 'DISABLE' | 'MA' | 'SERVICE' | 'COMINGSOON' | 'INTERNAL'
  vehiclepromote: string
  vehicletypename: string
  finish_at_station: TZeroOne
  ma_tags: string
  check_availability: TZeroOne
  require_deepclean: TZeroOne
  feature_tags: string
  revenuerate: number
  thumbnail_path: string
  document_link: string
  instantbooking_userids: string
  instantbooking_nfc: string
  instantbooking_userid: number
  licenseplate_expiredate: Date
  vehicleact_expiredate: Date
  vehicleact_company: string
  insurance_startdate: Date
  insurance_expiredate: Date
  insurance_company: string
  insurance_contactnumber: string
  vin_number: string
  roadside_contactnumber: string
  fuelcard: string
  checkin_email: string
  noti_url: string
  log_nfctap: TZeroOne
  role_tags: string
  fueltype: string
  compensate_distance_p: number
  verified: TZeroOne
  fuel_link: string
  charging_link: string
  fuelbrand: string
  chargingbrand: string
  chargingtype: string
  tirecode: string
  antithiefmode: 'MOBILIZE' | 'IMMOBILIZE' | 'SCENE1' | 'SCENE2' | 'UNDEFINED'
  max_overlap_reservation: number
  insurance_hourrate: number
  insurance_dayrate: number
  insurance_annualprice: number
  batterycode: string
  certificate_tags: string
  enginestarttype: 'UNDEFINED' | 'KEY' | 'PUSH'
  enginecuttype: string
  battery_replacedate: Date
  tire_replacedate: Date
  discountrate_weekly: number
  discountrate_monthly: number
  discountrate_yearly: number
  seasonalrate: number
  advance_checkin_m: number
  advance_reserve_m: number
  reservation_gap_m: number
  min_reserve_m: number
  max_reserve_m: number
  valid_starthour: number
  valid_stophour: number
  depositprice: number
  allowdebundle: TZeroOne
  debundlefee: number
  partnertype: 'UNDEFINED' | 'OEM' | 'OTA' | 'P2P' | 'DEALER'
  subscription_packages: string
  fuelcard_vendor: string
  fuelcard_expireddate: Date
  vehicleclass: string
  enginetype: string
  vehiclesize: string
  enginesize: string
  vehiclesize_intl: string
  display_priority: number
  car_operator_emails: string
  highrisk: TZeroOne
  airtag_model: string
  airtag_number: string
  airtag_install_batt_p: number
  airtag_install_date: Date
  airtag_owner: string
  use_std_price: TZeroOne
  sub_model: string
  weeklyrate: number
  monthlyrate: number
  yearlyrate: number
  threeyearsrate: number
  use_host_price: TZeroOne
  host_markup_p: number
  insurance_type: string
  insurance_class: string
  insurance_deductiblerate: number
  ma_userid: number
  vehicle_description: string
  vehicle_pickup_description: string
  promotion_tags: string
  vehicle_tags: string
  channel_code: string
  project_code: string
  gps_install_date: string
  gps_uninstall_date: string
}

// ----------------- INTERFACES -------------------
export type EDiscountList = 'NONE' | 'PROMOTION' | 'COUPON' | 'SALES'
export enum ECategory {
  HAUP = 'HAUP',
  EXTERNAL = 'EXTERNAL',
  MA = 'MA',
  LONGTERM = 'LONGTERM',
}

export interface ISelectOptions {
  label: string
  value: string
}

export interface IAfterEstimation {
  numberOfThreeYears: number
  numberOfYears: number
  numberOfMonths: number
  numberOfWeeks: number
  numberOfDays: number
  numberOfHours: number
  totalFees: number
  depositAmount: number
}

export type TResevationHaupTeam = 'CX' | 'SALES' | 'OPERATIONS' | 'VX' | 'VI'

export interface InvoiceItemDB {
  name: string
  product_id: null
  unit_price: number
  vat_p: number
  vat: number
  quantity: number
  total: number
}

export interface CreateReservationDto {
  reservationData: IReservationManageApi
  items: ItemsCreateReservation
}

export interface ItemsCreateReservation {
  invoiceItems: IInvoiceItemsApi[]
  paymentList: IPaymentType
  paymentChannel: TPaymentChannel
  transferSlipUrl: string
}

//! new interface
export type TypeInvoiceStatus = 'DRAFT' | 'OPEN' | 'PAID' | 'VOID' | 'OVERDUE' | 'DEBT'
export interface IUserCreditInfo {
  walletHaup: {
    sum_credit_baht: number
    sum_credit_hour: number
    sum_credit_km: number
    sum_credit_point: number
    sum_credit_point_thismonth: number
    sum_lifetime_credit_baht: number
    sum_reservecount: number
  }
}

export interface IResultVehicleAvailable {
  check_result: 'AVAILABLE' | 'NOT_AVAILABLE'
  data: {
    v_min_reserve_m: number
    v_max_reserve_m: number
    v_blocked_by_min_reserve_m: number
    v_blocked_by_max_reserve_m: number
    v_advance_reserve_m: number
    v_reservation_gap_m: number
    v_min_start_time_by_gap: string
    v_min_start_time_by_advance: string
    v_min_start_time_by_owner_reserved: string
    v_min_start_time: string
    v_blocked_by_pickup_station_offhours: number
    v_blocked_by_dropoff_station_offhours: number
    v_blocked_by_pickup_vehicle_offhours: number
    v_blocked_by_dropoff_vehicle_offhours: number
    v_blocked_by_out_of_station: number
    v_blocked_by_reservation_late_return: number
    v_reserve_blocked: number
    v_temporary_blocked_by_reserved: number
    v_temporary_blocked_by_reservation_gap: number
    v_temporary_blocked_by_reservation_advance: number
    v_temporary_blocked_by_owner_reserved: number
    v_reserve_temporary_blocked: number
  }
}

export interface IPriceInfo {
  oldPriceRateInfo: {
    old_price_id: number
    old_price_rate_id: number
    price_code: string
  }
  priceRate: {
    lateHourRate: number
    hourRate: number
    dayRate: number
    monthlyRate: number
    threeyearsRate: number
    weeklyRate: number
    yearlyRate: number
  }
  priceRateInfo: {
    std_price_id: number
    std_price_rate_id: number
    std_price_rate_version: string
  }
  priceRateType: string
}

export interface IPaymentData {
  account: string
  bank: string
  brand: string
  default_payment: boolean
  deleted: number
  desc: string
  expired: string
  financing: string
  name: string
  paymentmethod: string
  paymentno: number
  phonenumber: string
  requirevalidate: number
  token: string
  userid: number
  validated: number
}

export interface ICodeUseInfo {
  code: string
  promotion_id: number
  master_promotion_id: number
  expired_time: string
  type_code: string
  canUseCode: boolean
  discount: number
  haup_subsidise_p: number
}
export interface IAddOnItem {
  product_name_en: string
  product_name_th: string
  product_type: 'ADDON'
  product_code: 'OTHER'
  price: number
  quantity: number
  haup_subsidise_p?: 0 | 1
}
export interface IInputReservationCalculate {
  userId: number
  vehicleId: number
  fromDate: string
  toDate: string
  channel: string
  dstStationId?: number
  code: string[]
  discount: number
  discountP: number
  deposit: number
  haupCredit: number
  addOn?: IAddOnItem[] //!คิดก่อนเอาอะไรบ้าง
  gasingPolicy: 'NONE' | 'BUNDLE' | 'FULL_TO_FULL' | 'SAME_LEVEL' | 'EV'
  category?: 'HAUP' | 'EXTERNAL' | 'MA' | 'LONGTERM' //!ส่งมาจาก front-end
  paymentMethod: string
  remark?: string
}
export interface IInvoiceData {
  invoice_id?: number //auto
  source: string //source of expense => reservation, ev, shop, fasttrack, parking, etc...
  ref: number
  customer_userid: number
  invoice_status: TypeInvoiceStatus
  due_date: string
  price: number
  vat: number
  total: number
  owner_userid: number
  invoiceItem: IInvoiceItemData[]
}

export interface IInvoiceItemData {
  invoice_item_id?: number // auto
  invoice_id?: number
  product_name_en: string
  product_name_th: string
  product_type: string
  product_code: string
  price: number
  quantity: number
  discount: number
  vat_p: number
  vat: number
  total: number
  can_use_haupcredit: 0 | 1
  share_revenue: 0 | 1
  promotion_code: string
  coupon_code: string
  ref_invoice_item_id: number
  promotion_id: number
}

export interface IResultOfReturnReservationCalculate {
  inputInfo: IInputReservationCalculate
  codeUseInfo: ICodeUseInfo[]
  userInfo?: UserModel
  paymentData?: IPaymentData[]
  vehicleInfo?: IVehicleModel
  availableInfo?: IResultVehicleAvailable
  userCreditInfo?: IUserCreditInfo
  priceInfo?: IPriceInfo
  // priceRateInfo?: IPriceRateInfo
  // oldPriceRateInfo?: IOldPriceRateInfo
  // haupcredit //เหลือเท่าไหร่ //
  invoiceInfo?: IInvoiceData[]
  canCancel: boolean
  reservationPrice: number
  discountPrice: number
  netPrice: number
}

export interface IBundleRatemaster {
  bundleid: number
  bundletype: string
  bundlecode: string
  freedistanceperhour: number
  override_distancerate: number
}

export interface ICurrentBundleData {
  bundlecode: string
  distanceRate: number
  freeDistancePerHour: number
  minDistance: number
  maxDistance: number
  isLastIndex: boolean
}
